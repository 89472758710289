/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-unused-state */
import * as React from "react";
import Paper from "@material-ui/core/Paper";
import { ViewState, EditingState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  Toolbar,
  MonthView,
  DateNavigator,
  ViewSwitcher,
  Appointments,
  AppointmentTooltip,
  AppointmentForm,
  DragDropProvider,
  EditRecurrenceMenu,
  AllDayPanel,
} from "@devexpress/dx-react-scheduler-material-ui";
import { connectProps } from "@devexpress/dx-react-core";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Close from "@material-ui/icons/Close";
import CalendarToday from "@material-ui/icons/CalendarToday";
import Create from "@material-ui/icons/Create";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Switch from "@material-ui/core/Switch";
import { getHolidayExcludedfacilities } from "services/userservices";
import { spinLoaderShow } from "redux/action/spinLoader";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import moment from 'moment'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import { appointments } from '../../../demo-data/appointments';
const appointments = [];
const containerStyles = (theme) => ({
  container: {
    width: theme.spacing(50),
    padding: 0,
    paddingBottom: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  header: {
    overflow: "hidden",
    paddingTop: theme.spacing(0.5),
  },
  closeButton: {
    float: "right",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 2),
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  picker: {
    marginRight: theme.spacing(2),
    "&:last-child": {
      marginRight: 0,
    },
    width: "50%",
  },
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(1, 0),
  },
  icon: {
    margin: theme.spacing(2, 0),
    marginRight: theme.spacing(2),
  },
  textField: {
    width: "100%",
  },
});

class AppointmentFormContainerBasic extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      appointmentChanges: {},
      facilityDropDownValue: [],
      fieldsErrors: {
        title: false,
        Facility:false
      }
    };

    this.getAppointmentData = () => {
      const { appointmentData } = this.props;
      return appointmentData;
    };
    this.getAppointmentChanges = () => {
      const { appointmentChanges } = this.state;
      return appointmentChanges;
    };

    this.changeAppointment = this.changeAppointment.bind(this);
    this.commitAppointment = this.commitAppointment.bind(this);
  }

  componentWillReceiveProps(propsNew) {


    if (propsNew?.appointmentData?.uuid) {
      getHolidayExcludedfacilities({
        holiday_id: propsNew?.appointmentData?.uuid,
        status: true,
      })
        .then((res) => {
          this.setState({

          })
          let data = res?.data?.result;
          this.setState(
            {
              facilityDropDownValue: data.map((x) => x.facilities),

            },
            () => {
              
              this.changeAppointment({
                field: "facility",
                changes: this.state.facilityDropDownValue,
              })
            }
          );

          
          this.changeAppointment({
            field: "title",
            changes: this.props.appointmentData.title,
          })
          

        })
        .catch((err) => {
          dispatch(spinLoaderShow(false))
        });
    } else {
      this.setState({
        facilityDropDownValue: [],
      });
    }
  
  }

  changeAppointment({ field, changes }) {
    const nextChanges = {
      ...this.getAppointmentChanges(),
      [field]: changes,
    };
    this.setState({
      appointmentChanges: nextChanges,
    });
  }

  commitAppointment(type) {
    const { commitChanges } = this.props;
    const appointment = {
      ...this.getAppointmentData(),
      ...this.getAppointmentChanges(),
    };
    if (type === "deleted") {
      commitChanges({ [type]: appointment.id });
    } else if (type === "changed") {
      commitChanges({ [type]: { [appointment.id]: appointment } });
    } else {
      commitChanges({ [type]: appointment });
    }
    this.setState({
      appointmentChanges: {},
      facilityDropDownValue: [],
      status: true,
    });
    // const [venues,setVenues] = useState([])
  }

  // componentDidMount(){
  //   console.clear();
  // }

  render() {
    const {
      classes,
      visible,
      visibleChange,
      appointmentData,
      cancelAppointment,
      target,
      onHide,
      facilities,
    } = this.props;
    const { appointmentChanges } = this.state;

    const displayAppointmentData = {
      ...appointmentData,
      ...appointmentChanges,
    };

    const isNewAppointment = appointmentData.id === undefined;
    const applyChanges = isNewAppointment
      ? () => {
       
        if (!moment(this.props.appointmentData.startDate).isBefore(moment(), "day")) { return this.commitAppointment("added") } else {
          toast.error("Date Expired record not saved");

        }
      }
      : () => {
        if (!moment(this.props.appointmentData.startDate).isBefore(moment(), "day")) { return this.commitAppointment("changed") } else {
          toast.error("Date Expired record not saved");

        }
      }

    const textEditorProps = (field) => {
      return ({
        variant: "outlined",
        onChange: ({ target: change }) => {
          this.changeAppointment({
            field: [field],
            changes: change.value,
          })
        },
        value: displayAppointmentData[field] || "",
        label: field[0].toUpperCase() + field.slice(1),
        className: classes.textField,
      })
    };

    const pickerAutoCompleteProps = (field) => {
      let data = displayAppointmentData[field]
        ? displayAppointmentData[field]
        : this.state.facilityDropDownValue;
      let __data = displayAppointmentData[field]
        ? displayAppointmentData[field]
        : this.state.facilityDropDownValue;
      let dropDownValues = data;
      data = [...(data ? data : []), ...this.state.facilityDropDownValue];
      function removeDuplicates(originalArray, prop) {
        var newArray = [];
        var lookupObject = {};
        for (var i in originalArray) {
          lookupObject[originalArray[i][prop]] = originalArray[i];
        }
        for (i in lookupObject) {
          newArray.push(lookupObject[i]);
        }
        return newArray;
      }
      data = removeDuplicates(data, "uuid");

      if (dropDownValues?.length > 0) {
        data = data
          .map((x) => {
            return dropDownValues.find((y) => y.uuid == x.uuid);
          })
          .filter((z) => z);
      }

      return {
        onChange: (event, newValue) =>
          this.changeAppointment({
            field: [field],
            changes: newValue,
          }),
        value: data,
      };
    };

    const pickerStatusProps = (field) => ({
      onChange: (event) => {
        this.changeAppointment({
          field: [field],
          changes: event?.target?.checked,
        });
      },
      label: field[0].toUpperCase() + field.slice(1),
      checked: displayAppointmentData?.[field],
    });

    const pickerEditorProps = (field) => ({
      className: classes.picker,
      // keyboard: true,
      ampm: false,
      value: displayAppointmentData[field],
      onChange: (date) =>
        this.changeAppointment({
          field: [field],
          changes: date
            ? date.toDate()
            : new Date(displayAppointmentData[field]),
        }),
      inputVariant: "outlined",
      format: "DD/MM/YYYY HH:mm",
      onError: () => null,
    });

    const cancelChanges = () => {
      this.setState({
        appointmentChanges: {},
        facilityDropDownValue: [],
        status: true,
      });
      visibleChange();
      cancelAppointment();
    };


    return (
      <AppointmentForm.Overlay
        visible={visible}
        target={target}
        onHide={onHide}
      >
        <div>
          <div className={classes.header}>
            <IconButton className={classes.closeButton} onClick={cancelChanges}>
              <Close color="action" />
            </IconButton>
          </div>
          <div className={classes.content}>
            <div className={classes.wrapper}>
              {/* <Create className={classes.icon} color="action" /> */}
              <TextField {...textEditorProps("title")} />

            </div>
            {this.state?.fieldsErrors?.title && <div className="imageError">{this.state?.fieldsErrors?.title}</div>}
            <div className={classes.wrapper} hidden>
              {/* <CalendarToday className={classes.icon} color="action" /> */}
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDateTimePicker
                  label="Start Date"
                  disabled
                  {...pickerEditorProps("startDate")}
                />
                <KeyboardDateTimePicker
                  label="End Date"
                  disabled
                  {...pickerEditorProps("endDate")}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className={classes.wrapper}>
              {/* <Create className={classes.icon} color="action" /> */}

              <Autocomplete
                size="small"
                multiple
                fullWidth
                value={this.state.facilityDropDownValue}
                autoComplete={false}
                //{...pickerAutoCompleteProps("facility")}
                onChange={(event, newValue) => {
                  this.setState({
                    facilityDropDownValue: newValue,
                  });
                  this.changeAppointment({
                    field: "facility",
                    changes: newValue,
                  });
                }}
                options={
                  this.props?.facilities?.length > 0
                    ? this.props?.facilities
                    : []
                }
                getOptionLabel={(option) => {
                  return option?.name;
                }}
                getOptionSelected={(option, value) => {
                  return option?.["uuid"] === value?.["uuid"];
                }}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => {
                    return (
                      <Chip
                        size="small"
                        label={option?.["name"]}
                        {...getTagProps({ index })}
                      />
                    );
                  })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    margin="normal"
                    label="Exclude Sports/Classes"
                    size="small"
                    autoComplete={"no"}
                  />
                )}
              />

            </div>
            {this.state?.fieldsErrors?.Facility && <div className="imageError">{this.state?.fieldsErrors?.Facility}</div>}

            <div className={classes.wrapper}>
              {/* <Create className={classes.icon} color="action" /> */}


              <FormControlLabel
                control={<Switch  {...pickerStatusProps("status")} />}
                label="Status"
              />
            </div>
            {/* <div className={classes.wrapper}>
              <LocationOn className={classes.icon} color="action" />
              <TextField {...textEditorProps("location")} />
            </div>
            <div className={classes.wrapper}>
              <Notes className={classes.icon} color="action" />
              <TextField {...textEditorProps("notes")} multiline rows="6" />
            </div> */}
          </div>
          <div className={classes.buttonGroup}>
            {!isNewAppointment && (
              <></>
              // <Button
              //   variant="outlined"
              //   color="secondary"
              //   className={classes.button}
              //   onClick={() => {
              //     visibleChange();
              //     this.commitAppointment("deleted");
              //   }}
              // >
              //   Delete
              // </Button>
            )}
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              onClick={() => {

                let title = this.state.appointmentChanges?.title
  
                let facility = this.state.facilityDropDownValue
             
                if(!facility || facility.length < 1){
                  this.setState({
                    fieldsErrors: { Facility: "Facility field is required." }
                  })
                }
                if (title && (facility && facility.length > 0)) {
                  visibleChange();
                  applyChanges();
                } else {
                  if (!title) {
                    this.setState({
                      fieldsErrors: { title: "Title field is required." }
                    })
                  }
                }


              }}
            >
              {isNewAppointment ? "Create" : "Save"}
            </Button>
          </div>
        </div>
      </AppointmentForm.Overlay>
    );
  }
}

const AppointmentFormContainer = withStyles(containerStyles, {
  name: "AppointmentFormContainer",
})(AppointmentFormContainerBasic);

const styles = (theme) => ({
  addButton: {
    position: "absolute",
    bottom: theme.spacing(1) * 3,
    right: theme.spacing(1) * 4,
  },
});

/* eslint-disable-next-line react/no-multi-comp */
class Demo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.scheduleData,
      currentDate: new Date(),
      confirmationVisible: false,
      editingFormVisible: false,
      deletedAppointmentId: undefined,
      editingAppointment: undefined,
      previousAppointment: undefined,
      addedAppointment: {},
      startDayHour: 9,
      endDayHour: 19,
      isNewAppointment: false,
    };

    this.toggleConfirmationVisible = this.toggleConfirmationVisible.bind(this);
    this.commitDeletedAppointment = this.commitDeletedAppointment.bind(this);
    this.toggleEditingFormVisibility =
      this.toggleEditingFormVisibility.bind(this);

    this.commitChanges = this.commitChanges.bind(this);
    this.onEditingAppointmentChange =
      this.onEditingAppointmentChange.bind(this);
    this.onAddedAppointmentChange = this.onAddedAppointmentChange.bind(this);
    this.appointmentForm = connectProps(AppointmentFormContainer, () => {
      const {
        editingFormVisible,
        editingAppointment,
        data,
        addedAppointment,
        isNewAppointment,
        previousAppointment,
      } = this.state;

      const currentAppointment =
        data.filter(
          (appointment) =>
            editingAppointment && appointment.id === editingAppointment.id
        )[0] || addedAppointment;
      const cancelAppointment = () => {
        if (isNewAppointment) {
          this.setState({
            editingAppointment: previousAppointment,
            isNewAppointment: false,
          });
        }
      };

      return {
        visible: editingFormVisible,
        appointmentData: currentAppointment,
        commitChanges: this.commitChanges,
        visibleChange: this.toggleEditingFormVisibility,
        onEditingAppointmentChange: this.onEditingAppointmentChange,
        cancelAppointment,
        facilities: this.props.facilities,
      };
    });
  }
  /// LIFE CYCLE METHODS
  componentDidUpdate() {
    this.appointmentForm.update();
  }

  // componentDidMount() {
  // }

  /// LIFE CYCLE METHODS

  onEditingAppointmentChange(editingAppointment) {
    this.setState({ editingAppointment });
  }

  onAddedAppointmentChange(addedAppointment) {
    this.setState({ addedAppointment });
    const { editingAppointment } = this.state;
    if (editingAppointment !== undefined) {
      this.setState({
        previousAppointment: editingAppointment,
      });
    }
    this.setState({ editingAppointment: undefined, isNewAppointment: true });
  }

  setDeletedAppointmentId(id) {
    this.setState({ deletedAppointmentId: id });
  }

  toggleEditingFormVisibility() {
    const { editingFormVisible } = this.state;

    this.setState({
      editingFormVisible: !editingFormVisible,
    });
  }

  toggleConfirmationVisible() {
    const { confirmationVisible } = this.state;
    this.setState({ confirmationVisible: !confirmationVisible });
  }

  commitDeletedAppointment() {
    this.setState((state) => {
      const { data, deletedAppointmentId } = state;
      const nextData = data.filter(
        (appointment) => appointment.id !== deletedAppointmentId
      );
      this.props.setSchedulteData(nextData);
      return { data: nextData, deletedAppointmentId: null };
    });

    this.toggleConfirmationVisible();
  }

  commitChanges({ added, changed, deleted }) {
    this.setState((state) => {
      let { data } = state;
      if (added) {
        const startingAddedId =
          data.length > 0 ? data[data.length - 1].id + 1 : 0;
        this.props.setAddScheduled(added);
        data = [...data, { id: startingAddedId, ...added }];
      }
      if (changed) {
        this.props.setAddScheduled(changed);
        data = data.map((appointment) =>
          changed[appointment.id]
            ? { ...appointment, ...changed[appointment.id] }
            : appointment
        );
      }
      if (deleted !== undefined) {
        this.props.setAddScheduled(deleted);
        this.setDeletedAppointmentId(deleted);
        this.toggleConfirmationVisible();
      }
      this.props.setSchedulteData(data);
      return { data, addedAppointment: {} };
    });
  }

  render() {
    const {
      currentDate,
      data,
      confirmationVisible,
      editingFormVisible,
      startDayHour,
      endDayHour,
    } = this.state;
    const { classes } = this.props;

    return (
      <Paper>
        <Scheduler data={data} height={660}>
          <ViewState />
          <EditingState

            onCommitChanges={this.commitChanges}
            onEditingAppointmentChange={this.onEditingAppointmentChange}
            onAddedAppointmentChange={this.onAddedAppointmentChange}
          />
          <MonthView />
          <AllDayPanel />
          <EditRecurrenceMenu />
          <Appointments />
          <AppointmentTooltip
            showOpenButton
            showCloseButton
          //showDeleteButton
          />
          <Toolbar />
          {/* <ViewSwitcher /> */}
          <DateNavigator />
          <AppointmentForm
            overlayComponent={this.appointmentForm}
            visible={editingFormVisible}
            onVisibilityChange={this.toggleEditingFormVisibility}
          />
          <DragDropProvider />
        </Scheduler>
        <Dialog open={confirmationVisible} onClose={this.cancelDelete}>
          <DialogTitle>Delete Appointment</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this appointment?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.toggleConfirmationVisible}
              color="primary"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              onClick={this.commitDeletedAppointment}
              color="secondary"
              variant="outlined"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    );
  }
}

export default withStyles(styles, { name: "EditingDemo" })(Demo);
