import * as yup from 'yup'


export const contactus_mapValidations: any = yup.object().shape({
    lat:  yup.string().required("This field is empty").nullable(),
    lng:  yup.string().required("This field is empty").nullable(),




})