
import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {isvalid} from '../../services/cognitouser';
import { checkPermission } from '../../services/common';
import { sections } from '../../json/sections';
import { Backdrop, CircularProgress } from '@material-ui/core';

// 
export default function PrivateRoute({ component, path, ...rest }:any) {

    const Component:any = component;
    const [loading, setLoading] = useState(true)
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const getPermission = useSelector((state:any) =>{return state.userPermission.permissions})

    useEffect(() => {

        var sectionobj = sections.filter((x:any) => x.pathName === path);
    
        var y;
        if (sectionobj != undefined) {
            y = sectionobj.map((section:any) => checkPermission(getPermission, section.sectionName, section.subsection, section.specific))
            if (y.find((x:any) => x === true)) {
             
                setIsAuthenticated(true);
            } else {
                setLoading(false);
            }
        }
    }, [getPermission])

    return (
        <Route
            {...rest}
            render={props => {
                if (isAuthenticated) {
                 
                    return <Component  {...props} />
                } else {
          
                    if (loading && isvalid()) {
                        return (<Backdrop open={loading}><CircularProgress color="primary" /></Backdrop>)
                    }
                    else if (!loading && isvalid() && !isAuthenticated) {
                        return <Redirect to={{ pathname: '/admin/dashboard'}} />

                    } else if (!isvalid()) {
                        return <Redirect to={{ pathname: '/' }} />
                    }
                }
            }}
        />
    )
}
