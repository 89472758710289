import React, { useEffect, useState } from "react";
import loginStyles from "./LoginStyle";
// import logo from "../assets/images/picpax.svg";
import Button from "components/CustomButtons/Button";

import {
  CssBaseline,
  Grid,
  Typography,
  Container,
  Box,
  Backdrop,
  CircularProgress,
  TextField,
} from "@material-ui/core";

import logo from "assets/img/blackimage.png";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { loginPageValidation } from "./LoginValidator";
import { InputField } from "components/Forms/Formfield.component";
import { Auth } from "aws-amplify";
import { congitoConfig, isvalid, getRoleId, signOut } from "services/cognitouser";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getRoles } from "services/userservices";
import { userPermissions } from "redux/action/permission";
import { useDispatch } from "react-redux";
import { InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";


export default function LoginLayout(props: any) {
  const dispatch = useDispatch();

  const {

    visibleAlert,
    handleClose,
    alert,
    splash,
    heading,
    children,
  } = props;
  const classes = loginStyles();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);



  const {
    control,
    reset,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(loginPageValidation),
  });

  let history = useHistory();


  const onSubmit = (data: any) => {

    setLoading(true);
    Auth.signIn({ username: data.email, password: data.password }).then(
      async (res: any) => {
        console.clear();
        let email = res.attributes["email"]
        let roleId = res.attributes["custom:roleId"];
        let name = res.attributes["name"]
        sessionStorage.setItem("roleId", roleId);
        localStorage.setItem("email", email)
        localStorage.setItem("name", name)




        let roleData = await getRoles({ uuid: roleId });
        if (roleData.data.result[0]["status"]) {
          history.push("/admin/dashboard")
          dispatch(userPermissions(roleData.data.result[0]["permissions"]));
          setLoading(false);
          // GET ROLE THEN DISPATCH SAVE ROLE ID IN SessionStorage
          // toast.success("Successfully Logged In")
       


        } else {
          signOut();
          toast.error("Your role is disabled! Contact Admin");
          setLoading(false);


        }

      }
    ).catch(err => {
      
      toast.error("Invalid Attempt " + String(err.message).toLocaleUpperCase());
      setLoading(false);

    });
  };

  useEffect(() => {
    setLoading(true);
    Auth.configure(congitoConfig);

    if (isvalid()) {
      history.push("/admin/dashboard")
    }
    setLoading(false);

  }, []);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (

    <div className={classes.mainContain}>
      <ToastContainer />
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress color="primary" />
      </Backdrop>
      <CssBaseline />
      <Grid container alignItems="stretch">
        <Grid item md={6}>
          <Box component="div" className={classes.introSide}>
            <Box pt={6} pb={4}>
              <Typography color="primary" component="h2" variant="h4">
                Hello Admin!
              </Typography>
            </Box>
            <Box component="div">
              <img
                className={classes.splashImg}
                src={
                  "https://assets.playa.ae/assets/other_images/a29098a5-4c12-44ba-86d0-ad50c42f77e9.jpg"
                }
                alt="Splash"
              />
            </Box>
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box component="div" className={classes.loginSide}>
            <Container maxWidth="sm">
              <div className={classes.paper}>
                <div></div>
                <Typography
                  className={classes.loginhead}
                  color="secondary"
                  component="h4"
                  variant="h4"
                >
                  {heading}
                </Typography>
                {/* {children} */}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid
                    container
                    item
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={4}
                  >
                    <Grid
                      className={"loginlogo"}
                      container
                      item
                      xs={12}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <img src={"https://assets.playa.ae/assets/facilities_images/41ecdfae-1298-45f2-b663-336eb19d7fb3.png"} width="50%" alt="SBP"></img>
                    </Grid>

                    <Grid container item xs={12} >
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            error={errors?.email}
                            variant="outlined"
                            fullWidth
                            label="Email"
                            type="text"
                            helperText={errors?.email && errors?.email?.message}
                          />
                        )}
                      />
                    

                    </Grid>
                    
                    <Grid container item xs={12}>
                      <Controller
                        name="password"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            error={errors?.password}
                            variant="outlined"
                            fullWidth
                            label="Password"
                            type={showPassword ? "text" : "password"}
                            helperText={
                              errors?.password && errors?.password?.message
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}

                                  >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid container item xs={12}>
                      <Button fullWidth color="primary" type="submit">
                        Login
                      </Button>          
                      <div className="forgot-btn"> <Link to="/forgot">Forgot Password</Link></div>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </Container>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
