import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TimePicker } from "@material-ui/pickers";
import { Chip } from "@material-ui/core";

import {
  CircularProgress,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Switch,
  Checkbox,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import MuiPhoneNumber from "material-ui-phone-number";
import { Skeleton } from "@material-ui/lab";
import { Editor } from '@tinymce/tinymce-react';
import { RadioGroup } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";
import { Radio } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "100%",
  },
  marginTopZero: {
    marginTop: theme.spacing(0),
  },
}));

export const InputField = (props: any) => {
  const [load, setLoad] = useState(true);
  const {
    fieldName,
    label,
    control,
    iProps,
    rules,
    initValue,
    helperText,
    placeholder,
    errors,
    type,
    autoComplete,
    isLoading,
    multiline,
    rows,
    disabled,
    fullWidth,
    endAdornment
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldName, props.valueGot);
  

    if (isLoading || props.valueGot || props.valueGot == '' || props.valueGot == null)
      setLoad(false);
  }, [props.valueGot, isLoading]);

  return (
    <>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={initValue || initValue === 0 ? initValue : ""}
        rules={rules}
        render={({ field }) => (
          <>
            {load ? (
              <Skeleton height={90} />
            ) : type == 'hidden' ? (
              <input {...field} type={type} onChange={(e) => field.onChange(parseInt(e.target.value))} />
            ) : (
              <TextField
                {...field}
                error={errors}
                variant="outlined"
                margin={!props.marginTopZero ? 'normal' : ''}
                fullWidth={fullWidth == false ? false : true}
                label={label}
                size="small"
                type={type}
                id={fieldName}
                multiline={multiline}
                rows={rows}
                disabled={disabled}
                placeholder={placeholder}
                autoComplete={autoComplete}
                helperText={helperText}
                {...iProps}
                onChange={(e: any) => {
                  if (iProps?.onChange) {
                    iProps?.onChange(e)
                  }
                  field.onChange(e)
                }}
              />
            )}
          </>
        )}
      />
    </>
  );
};

export const PhoneNoField = (props: any) => {
  const classes = useStyles();

  const {
    fieldName,
    label,
    control,
    iProps,
    rules,
    initValue,
    helperText,
    errors,
    type,
    autoComplete,
    isLoading
  } = props;
  const [load, setLoad] = useState(true);
  useEffect(() => {
    props.valueGot && props.setValue(fieldName, props.valueGot);
    if (
      props.valueGot ||
      props.valueGot === "" ||
      props.valueGot === null ||
      isLoading
    )
      setLoad(false);
  }, [props.valueGot]);

  return (
    <>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={initValue || initValue === 0 ? initValue : ""}
        rules={rules}
        render={({ field }) => (
          <MuiPhoneNumber
            data-cy="user-phone"
            defaultCountry={"ae"}
            {...field}
            error={errors}
            variant="outlined"
            margin="normal"
            fullWidth
            label={label}
            type={type}
            id={fieldName}
            size="small"
            autoComplete={autoComplete}
            helperText={helperText}
            {...iProps}
          />
        )}
      />
    </>
  );
};

export const DateField = (props: any) => {
  const {
    fieldName,
    label,
    control,
    iProps,
    rules,
    initValue,
    inputVariant,
    helperText,
    errors,
    isLoading
  } = props;
  const [load, setLoad] = useState(true);

  useEffect(() => {
    props.valueGot && props.setValue(fieldName, props.valueGot);
    if (
      props.valueGot ||
      props.valueGot === "" ||
      props.valueGot === null ||
      isLoading
    )
      setLoad(false);
  }, [props.valueGot]);

  return (
    <>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={initValue || initValue === 0 ? initValue : ""}
        rules={rules}
        render={({ field }) => (
          <DatePicker
            {...field}
            margin="normal"
            inputVariant="outlined"
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            label={label}
            {...iProps}
            size="small"
            helperText={helperText}
            fullWidth
            error={errors}
          />
        )}
      />
    </>
  );
};

export const SelectField = (props: any) => {
  const classes = useStyles();
  const [load, setLoad] = useState(true);
  const [menuItems, setMenuItem] = useState([]);
  const {
    fieldName,
    label,
    control,
    iProps,
    rules,
    initValue,
    helperText,
    errors,
    type,
    autoComplete,
    placeHolder,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldName, props.valueGot);
  }, [props.valueGot]);

  useEffect(() => {
    setMenuItem(props?.selectOptions);
    if (props?.selectOptions.length > 0) setLoad(false);
  }, [props?.selectOptions]);

  return (
    <>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={initValue || initValue === 0 ? initValue : ""}
        rules={rules}
        render={({ field }) => (
          <>
            {load ? (
              <Skeleton height={58} />
            ) : (
              <FormControl
                error={errors}
                variant="outlined"
                size="small"
                margin="normal"
                className={classes.formControl}
              >
                <InputLabel id={label}>{label}</InputLabel>
                <Select
                  {...field}
                  variant="outlined"
                  fullWidth
                  label={label}
                  {...iProps}
                  onChange={(e: any, data: any) => {
                    field.onChange(e, data);
                    if (iProps?.onChange) {
                      iProps?.onChange(e, data)
                    }
                 
                    props.setValue(fieldName, data?.props.value)
                  }}
                  size="small"
                  type={type}
                  id={fieldName}
                  autoComplete={autoComplete}

                >
                  <MenuItem value="" disabled>
                    {placeHolder}
                  </MenuItem>
                  {(menuItems || []).map((item: any, index: any) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
              </FormControl>
            )}
          </>
        )}
      />
    </>
  );
};

export const LoadingButton = (props: any) => {
  const { onClick, loading, text } = props;
  return (
    <Button {...props} onClick={onClick} disabled={loading} variant="contained" color="primary">
      {loading && <CircularProgress size={23} />}
      {!loading && text}
    </Button>
  );
};

export const AutoCompleteField = (props: any) => {
  const [load, setLoad] = useState<any>(true);
  const [value, setValue] = React.useState<any>({});
  const [inputValue, setInputValue] = React.useState<any>("");

  const {
    fieldName,
    control,
    iProps,
    rules,
    initValue,
    helperText,
    errors,
    type,
    autoComplete,
    options,
    isLoading,
    optionKey,
    optionValue,
    label,
    returnObject
  } = props;

  useEffect(() => {
    if (isLoading) {
      setLoad(false);
      if (returnObject) {
        props.valueGot && props.setValue(fieldName, props?.valueGot);
      } else {
        props.valueGot && props.setValue(fieldName, props?.valueGot?.[optionKey]);
      }
    } else if (isLoading == false) {
      setLoad(true);
    }
    if (props.valueGot == undefined || props.valueGot == null) {
      setValue({});
      setInputValue({})
    } else {
      setValue(props.valueGot);
      setInputValue(props.valueGot?.[optionValue])
    }


  }, [props.valueGot, isLoading]);

  return (
    <>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={initValue || initValue === 0 ? initValue : ""}
        rules={rules}
        render={(field) => (

          <>

            {load ? (
              <Skeleton height={65} />
            ) : (
              <Autocomplete
                className="form-item"
                options={options}
                {...iProps}
                autoComplete={false}
                //filterSelectedOptions
                getOptionSelected={(option: any, value: any) => {
                  return option?.[optionKey] === value?.[optionKey];
                }}
                getOptionLabel={(option: any) => {

                  return option?.[optionValue] !== undefined ? option?.[optionValue] : '';
                }}
                value={value}
                onChange={(event: any, newValue: any) => {
                  setValue(newValue);
                  iProps?.onChange(newValue);
                  if (returnObject) {
                    props.setValue(fieldName, newValue);
                  } else {
                    props.setValue(fieldName, newValue?.[optionKey]);
                  }
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                renderInput={(params) => {

                  let _data = {
                    ...params,
                    inputProps: {
                      ...params.inputProps,
                      autoComplete: "no",
                    },
                  };
                  return (
                    <TextField
                      {..._data}
                      error={errors}
                      variant="outlined"
                      label={label}
                      margin="normal"
                      size="small"
                      autoComplete={"no"}
                      helperText={helperText}
                    />
                  );
                }}
              />
            )}
          </>
        )}
      />
    </>
  );
};

export const AutoCompleteMultipleField = (props: any) => {
  const [load, setLoad] = useState<any>(true);
  const [value, setValue] = React.useState<any>([]);
  const {
    fieldName,
    control,
    iProps,
    rules,
    initValue,
    helperText,
    errors,
    type,
    autoComplete,
    options,
    isLoading,
    optionKey,
    optionValue,
    label
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldName, props.valueGot);
    if (isLoading) {
      setLoad(false);
      props.setValue(fieldName, props?.valueGot);
    }
    if (props.valueGot == undefined) {
      setValue([]);
    } else {
      setValue(props.valueGot);
    }
    if (isLoading == false) {
      setLoad(true);
    }

  }, [props.valueGot, isLoading]);

  return (
    <>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={initValue || initValue === 0 ? initValue : ""}
        rules={rules}
        render={(field) => (
          <>
            {load ? (
              <Skeleton height={65} />
            ) : (
              <Autocomplete
                size="small"
                multiple
                value={value}
                // {...iProps}
                autoComplete={false}
                onChange={(event: any, newValue: any) => {
                  setValue(newValue);
                  iProps?.onChange(newValue);
                  props.setValue(fieldName, newValue);
                }}
                options={options}
                getOptionLabel={(option: any) => {
                  return option?.[optionValue];
                }}
                getOptionSelected={(option: any, value: any) => {
                  return option?.[optionKey] === value?.[optionKey];
                }}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option: any, index: any) => {
                    return (
                      <Chip
                        size="small"
                        label={option?.[optionValue]}
                        {...getTagProps({ index })}
                      />
                    )
                  })
                }
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    error={errors}
                    variant="outlined"
                    label={label}
                    margin="normal"
                    size="small"
                    autoComplete={"no"}
                    helperText={helperText}
                  />
                )}
              />
            )}
          </>
        )}
      />
    </>
  );
};

// export const DropZone = (props:any)=>{
//   const [load ,setLoad] = useState(true)
//   const {
//     fieldName,
//     label,
//     control,
//     iProps,
//     rules,
//     initValue,
//     helperText,
//     errors,
//     type,
//     autoComplete,
//     options,
//     isLoading,
//   } = props;

//   useEffect(() => {
//     props.valueGot && props.setValue(fieldName, props.valueGot);
//   }, [props.valueGot]);

//   return(
//     <>
//      <Controller
//         name={fieldName}
//         control={control}
//         defaultValue={initValue || initValue === 0 ? initValue : ""}
//         rules={rules}
//         render={(field:any) => (

//         )}
//       />
//     </>
//   )
// }

export const SwitchFieldDefault = (props: any) => {
  const [load, setLoad] = useState(true);
  const {
    fieldName,
    label,
    control,
    iProps,
    rules,
    initValue,
    helperText,
    errors,
    type,
    autoComplete,
    isLoading,
    defaultChecked
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldName, props.valueGot)
    if (
      props.valueGot ||
      props.valueGot === "" ||
      props.valueGot === null ||
      isLoading
    )
      setLoad(false);
  }, [props.valueGot]);

  return (
    <>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={initValue || initValue === 0 ? initValue : ""}
        rules={rules}
        render={({ field }) => (
          <>
            {load ? (
              <Skeleton height={90} />
            ) : (
              <>
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      onChange={(e) => { field.onChange(e.target.checked); iProps?.onChange(e) }}
                      defaultChecked={defaultChecked}
                      checked={field.value}
                      id={fieldName}
                    />
                  }
                  label={label}
                />
                {helperText && <FormHelperText error={errors} id={fieldName}>{helperText}</FormHelperText>}
              </>
            )}
          </>
        )}
      />
    </>
  );
};

export const SwitchField = (props: any) => {
  const [load, setLoad] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const {
    fieldName,
    label,
    control,
    iProps,
    rules,
    initValue,
    helperText,
    errors,
    type,
    autoComplete,
    isLoading,
    checked
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldName, checked);
    if (
      props.valueGot ||
      props.valueGot === "" ||
      props.valueGot === null
    ) {
      setLoad(false);
      setIsChecked(checked)
    }
  }, [props.valueGot]);

  return (
    <>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={initValue || initValue === 0 ? initValue : ""}
        rules={rules}
        render={({ field }) => (
          <>
           
                <Switch
                  {...field}
                  {...iProps}
                  onChange={(e) => {
                    field.onChange(e)
                    iProps?.onChange(e)
                    setIsChecked(!isChecked)
                  }}
                  checked={isChecked}
                  id={fieldName}
                  label={label}
                />
                {helperText && <FormHelperText error={errors} id={fieldName}>{helperText}</FormHelperText>}
             
          </>
        )}
      />
    </>
  );
};

export const TextEditorField = (props: any) => {
  const {
    fieldName,
    initValue,
    control,
    errors,
    rules,
    isLoading,
    className,
    helperText,
    initialValue
  } = props;
  const [load, setLoad] = useState(true);
  const [value, setValue] = useState<any>('')
  useEffect(() => {

    if (props.valueGot) {
      props.valueGot && props.setValue(fieldName, props.valueGot);
      setValue(props.valueGot)
    }
    if (isLoading)
      setLoad(false);
  }, [props.valueGot, isLoading]);

  return (
    <div className={className}>
      <Controller

        name={fieldName}
        control={control}
        defaultValue={initValue || initValue === 0 ? initValue : ""}
        rules={rules}
        render={({ field }) => (
          <>
            {load ? (
              <Skeleton height={90} />
            ) : (
              <Editor
                apiKey="2lsoue1ha24xltn4wqeql7iuwoqc8ygldmun39lg3jengd3w"
                textareaName={fieldName}
                value={value}
                onEditorChange={(newText) => { setValue(newText); props.setValue(fieldName, newText) }}
                initialValue={initialValue}
                init={{
                  plugins: "emoticons",
                  toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | emoticons',
                  toolbar_location: "top",
                  menubar: false,
                }
                }
              />

            )}
            {errors && <FormHelperText error={errors} >{helperText}</FormHelperText>}
          </>

        )}



      />
    </div>
  )
}

export const TimePickerField = (props: any) => {
  const {
    fieldName,
    label,
    control,
    iProps,
    rules,
    initValue,
    helperText,
    errors,
    isLoading
  } = props;
  const [load, setLoad] = useState(true);
  useEffect(() => {
    props.valueGot && props.setValue(fieldName, props.valueGot);
    if (
      props.valueGot ||
      props.valueGot === "" ||
      props.valueGot === null ||
      isLoading
    )
      setLoad(false);
  }, [props.valueGot]);

  return (
    <>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={initValue || initValue === 0 ? initValue : ""}
        rules={rules}
        render={({ field }: any) => (
          <TimePicker
            {...field}
            renderInput={(props: any) => <TextField {...props} margin="normal" inputVariant="outlined" size="small" />}
            label={label}
            {...iProps}
            margin="normal" inputVariant="outlined"
            size="small"
            clearable={true}
            helperText={helperText}
            fullWidth
            error={errors}
          />
        )}
      />
    </>
  );
};

export const CheckboxField = (props: any) => {
  const {
    fieldName,
    label,
    control,
    iProps,
    rules,
    initValue,
    helperText,
    errors,
    isLoading,
    checked
  } = props;
  const [load, setLoad] = useState(true);
  useEffect(() => {
    props.valueGot && props.setValue(fieldName, props.valueGot);
    if (
      props.valueGot ||
      props.valueGot === "" ||
      props.valueGot === null ||
      isLoading
    )
      setLoad(false);
  }, [props.valueGot, checked]);

  return (
    <>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={initValue || initValue === 0 ? initValue : ""}
        rules={rules}
        render={({ field }: any) => (
          <Checkbox
            {...field}
            {...iProps}
            helperText={helperText}
            name={fieldName}
            checked={checked}
            error={errors}
          />
        )}
      />
    </>
  );
};

export const RadioField = (props: any) => {
  const {
    fieldName,
    label,
    control,
    iProps,
    rules,
    initValue,
    helperText,
    errors,
    isLoading,
    checked
  } = props;
  const [load, setLoad] = useState(true);
  useEffect(() => {
    props.valueGot && props.setValue(fieldName, props.valueGot);
    if (
      props.valueGot ||
      props.valueGot === "" ||
      props.valueGot === null ||
      isLoading
    )
      setLoad(false);
  }, [props.valueGot, checked]);

  return (
    <>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={initValue || initValue === 0 ? initValue : ""}
        render={({ field }) => {
          const { name, onBlur, onChange, value } = field;
          return (
            <RadioGroup
              value={value}
              onBlur={onBlur}
              onChange={(e) => {
                onChange(e);
           
              }}
            >
              <FormControlLabel
                value=""
                control={<Radio />}
                label="Business"
              />

            </RadioGroup>
          );
        }
        }
      />
    </>
  );
};