import ht from "date-fns/esm/locale/ht/index.js";
import httpservice from "./httpservice";

//facilities Function Start
export function createFacilities(body: any) {
  return httpservice.post("/facilities", body);
}
export function getFacilities(body: any = null) {
  return httpservice.get("/facilityvenues", { params: body });
}
export function getFacility(body:any = null){
 return httpservice.get('/facilities',body )
}
export function updateFacilities(body: any = null) {
  return httpservice.put("/facilities",body);
}
//facilities Function End

// ContactUs Function Start
export function getContactUs(body: any = null) {
  return httpservice.get("/contact", { params: body });
}
export function createContactUs(body: any ) {
  return httpservice.post("/contact",  body);
}
export function updateContactUs(body: any ) {
  return httpservice.put("/contact", body);
}
//ContactUs Function End

// Venue Function Start
export function getVenue(body: any = null) {
  return httpservice.get("/venue", { params: body });
}
export function createVenue(body: any ) {
  return httpservice.post("/venue",  body );
}
export function updateVenue(body: any ) {
  return httpservice.put("/venue", body );
}
//Venue Function End

// /holiday Function Start
export function getHoliday(body: any = null) {
  return httpservice.get("/holiday", { params: body });
}
export function createHoliday(body: any ) {
  return httpservice.post("/holiday", body );
}
export function updateHoliday(body: any ) {
  return httpservice.put("/holiday",body);
}
export function getHolidayExcludedfacilities(body: any = null) {
  return httpservice.get("/excludedfacilities", { params: body });
}
//holiday Function End

//customer Function Start
export function getCustomer(body: any = null) {
  return httpservice.get("/customer", { params: body });
}
export function updateCustomer(body: any ) {
  return httpservice.put("/customer", body);
}
//customer Function End

// Transaction Function Start
export function getTransaction(body: any = null) {
  return httpservice.get("/payment/response", { params: body });
}
// Transaction Function End

// Booking Function Start
export function getBooking(body: any = null) {
  return httpservice.get("/booking", { params: body });
}
export function getBookingDetailView() {
  return httpservice.get("/report/bookingdetail");
}
export function getBookingOdata(body:any) {
  return httpservice.get("/report/odata"+body);
}
export function createBooking(body: any ) {
  return httpservice.post("/booking",body);
}
export function updateBooking(body: any ) {
  return httpservice.put("/booking",body);
}
///Booking Function End

// Faq Function Start
export function getFaq(body: any = null) {
  return httpservice.get("/faq",{ params: body });
}
export function createFaq(body: any ) {
  return httpservice.post("/faq", body);
}
export function updateFaq(body: any ) {
  return httpservice.put("/faq",body);
}
///Faq Function End

// FaqType Function Start
export function getFaqType(body: any = null) {
  return httpservice.get("/faq-types", { params: body });
}
export function createFaqType(body: any ) {
  return httpservice.post("/faq-types",body);
}
export function updateFaqType(body: any ) {
  return httpservice.put("/faq-types",body);
}
///FaqType Function End

// Application Settings Function Start

export function getApplicationSetting(body: any = null) {
  return httpservice.get("/applicationsetting", { params: body });
}
export function updateApplicationSetting(body: any ) {
  return httpservice.put("/applicationsetting",body);
}
export function getThemeSetting(body: any = null) {
  return httpservice.get("/themesettings", { params: body });
}
export function updateThemeSetting(body: any ) {
  return httpservice.put("/themesettings",body);
}
///Application Settings Function End

export default function facilitiesImg(files:any){
  return httpservice.post("/upload/facilitiesimages",files, { headers:{'Content-Type':'multipart/form-data'}})

}

export const otherImg=(files:any)=>{
  return httpservice.post("/upload/otherimages",files, { headers:{'Content-Type':'multipart/form-data'}})
}

export const venueFileUpload=(files:any)=>{
  return httpservice.post("/upload/venueimages",files,{ headers:{'Content-Type':'multipart/form-data'}})
}

export const videoUpload=(files:any)=>{
  return httpservice.post("/upload/video",files,{ headers:{'Content-Type':'multipart/form-data'}})
}

















function createBranch(body: any) {
  return httpservice.post("/venue", body);
}

function createSchedule(body: any) {
  return httpservice.post("/schedule/", body);
}

function createCity(body: any) {
  return httpservice.post("/city/", body);
}

function getCity(body: any = null) {
  return httpservice.get("/city", { params: body });
}

function updateCountry(body: any = null) {
  return httpservice.put('/country', body)
}



function getScheduleDays() {
  return httpservice.get("/schedule/days");
}

function getSchedules(body: any = null) {
  return httpservice.get("/schedule", { params: body });
}

// export function getVenue(body: any = null) {
//   return httpservice.get("/venue", { params: body });
// }

// export function getFaqType(body: any = null) {
//   return httpservice.get("/getFaqType", { params: body });
// }



function updateSchedule(body: any) {
  return httpservice.put("/schedule/", body);
}

function getZone(body: any = null) {
  return httpservice.get("/zone", { params: body });
}

function updateCity(body: any) {
  return httpservice.put("/city", body)
}

function updateBranch(body: any) {
  return httpservice.put('/branch', body)
}
function updateZone(body: any) {
  return httpservice.put('/zone', body)
}
function getVehicleBrand(body: any = null) {
  return httpservice.get('vehicle/brand', body)
}
function createVehicleBrand(body: any) {
  return httpservice.post('/vehicle/brand', body)
}

function updateVehicleBrand(body: any) {
  return httpservice.put('/vehicle/brand', body)
}
function getVehicleType(body: any = null) {
  return httpservice.get('/vehicle/type/', body)
}
function getVehicleModel(body: any = null) {
  return httpservice.get('/vehicle/model', body)
}
function createVehicleModel(body: any) {
  return httpservice.post('/vehicle/model', body)
}
function updateVehicleModel(body: any) {
  return httpservice.put('/vehicle/model', body)
}
function createVehicleType(body: any) {
  return httpservice.post('/vehicle/type', body)
}
function updateVehicleType(body: any) {
  return httpservice.put('/vehicle/type', body)
}
function getServiceCategory(body: any = null) {
  return httpservice.get('/services/category', { params: body })
}
function createServiceCategory(body: any) {
  return httpservice.post('/services/category', body)
}
function updateServiceCategory(body: any) {
  return httpservice.put('/services/category', body)
}
function getServiceTypes(body: any = null) {
  return httpservice.get('/services/types', body)
}
function getServices(body: any = null) {
  return httpservice.get('/services', { params: body })
}
function createService(body: any) {
  return httpservice.post('/services', body)
}
function updateService(body: any) {
  return httpservice.put('/services', body)
}
function getLevels(body: any = null) {
  return httpservice.get('/level', { params: body })
}
function createServiceConfig(body: any) {
  return httpservice.post('/services/configs', body)
}
function updateServiceConfig(body: any) {
  return httpservice.put('/services/configs', body)
}
function getServiceConfig(body: any = null) {
  return httpservice.get('/services/configs', { params: body })
}
function getPromotions(body: any = null) {
  return httpservice.get("/promotions", { params: body })
}
export function createFacilitie(body: any) {
  return httpservice.post('/promotions', body)
}
function updatePromotion(body: any) {
  return httpservice.put("/promotions", body)
}

export const getRoles: any = async (body: any = null) => {
  return httpservice.get("/admin/roles", { params: body })
}
export const createAdmin = (body: any) => {
  return httpservice.post("/admin/user", body)

}
export const verifyCode = (body: any) => {
  return httpservice.post("/admin/user/verify", body)

}
export const getAdmin = async (body: any = null) => {
  return httpservice.get('/admin/user', { params: body })
}

export const createRoles = (body: any) => {
  return httpservice.post("/admin/roles", body)

}
export const updateRoles = (body: any) => {
  return httpservice.put("/admin/roles", body)
}
export const updateAdmin = (body: any) => {
  return httpservice.put("/admin/user", body)
}
export const verifyCodeValidity = (body: any) => {
  return httpservice.put("/admin/user/verify/code", body)
}

export const regenerateCode = (body: any) => {
  return httpservice.put("/admin/user/regenerate", body)
}













export {
  
  createCity,
  createBranch,
  getScheduleDays,
  getSchedules,
  getCity,
  getZone,
  createSchedule,
  updateCountry,
  updateSchedule,
  updateCity,
  updateBranch,
  updateZone,
  getVehicleBrand,
  createVehicleBrand,
  updateVehicleBrand,
  getVehicleType,
  getVehicleModel,
  createVehicleModel,
  updateVehicleModel,
  createVehicleType,
  updateVehicleType,
  getServiceCategory,
  createServiceCategory,
  updateServiceCategory,
  getServiceTypes,
  createService,
  updateService,
  getServices,
  getLevels,
  createServiceConfig,
  updateServiceConfig,
  getServiceConfig,
  getPromotions,
  updatePromotion,
  
};
