import * as yup from "yup";

const createVenueValidation: any = yup.object().shape({
  name: yup.string().max(17, "Max character limit is 17").required("Please enter name").nullable(),
  address: yup.string().max(35, "Max character limit is 35").required("Please enter  address").nullable(),
  address_2: yup.string().max(28, "Max character limit is 28").required("Please enter address 2").nullable(),
  contact_phone: yup.string().required("Please enter mobile number").nullable(),
  contact_email: yup.string().email("Please enter correct email").required("Please enter an email").nullable(),
  slug: yup.string().required("Please enter slug").nullable(),
  description: yup.string().max(240, "Max character limit is 240"),
  bannerImage: yup.string().required("Please enter banner image").nullable(),
  galleryImageLarge: yup.string().required("Please enter gallery image large").nullable(),
  galleryImageMedium: yup.string().required("Please enter gallery image medium").nullable(),
  galleryImageSmall: yup.string().required("Please enter gallery image small").nullable(),

  lat: yup.string().required("Please select location"),


  featured_amenities: yup.array().of(

    yup.object().shape({

      featured_amenities: yup.string().nullable(),

    })),
  
    
  })

export {
  createVenueValidation,

}