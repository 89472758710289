import React, { useEffect } from 'react'

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { InputField, TextEditorField } from "../Forms/Formfield.component";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import { AccordionActions, Box, Button, CardMedia, Container } from '@material-ui/core';
import { DropzoneArea, DropzoneAreaBase } from 'material-ui-dropzone';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { copyrightTextValidation } from "./SettingValidation/copyrightTextValidation"
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from 'react-hook-form';

// const classes = useStyles();
const Accordion = withStyles({
  root: {
    // border: '1px solid rgba(0, 0, 0, .125)',
    borderRadius: '10px',
    boxShadow: '2px 3px 15px 10px rgba(0,0,0,2)',
    WebkitBoxShadow: ' 2px 3px 4px rgba(0,0,0,2)',
    marginBottom: 10,
    marginTop: 5,

    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',


    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#043cae',
    borderRadius: '5px',
    color: 'white',

    // borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    marginTop: 5,
    // boxshadow: '0px 0px 15px -10px',
    minHeight: 56,
    '&$expanded': {
      minHeight: 20,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',

    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),


  },
}))(MuiAccordionDetails);

const ExpandIcon = {
  color: "#ffffff"
}

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 180,
    width: 180,
  },
  drop: {
    maxHeight: 400,
    width: 400,
  },
  cont1: {
    display: `flex`,

  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210
  },
  textClass: {
    fontSize: 18
  },



});

const copyrightText = (props: any) => {

  const defaultFormValues: any = {
    
  }

  const { getData, Data, permission } = props;


  const { control, register, handleSubmit, formState: { errors }, setValue, reset, getValues, trigger } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultFormValues,
    resolver: yupResolver(copyrightTextValidation)
  });

  const data = Data?.cookiePolicy

  useEffect(() => {
    setValue('copyright_text', Data.copyright_text);


  }, [Data]);
  const onSubmit = (data: any) => {
    getData(data)
  };


  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Accordion >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={ExpandIcon} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Copyright Text</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <GridItem xs={12} sm={12} md={12} className={"mt-15"}>
              <InputField
                className={"mt-15"}
                errors={!!errors?.copyright_text}
                textareaName="copyright_text"
                fieldName="copyright_text"
                type="text"
                autoComplete="false"
                label="Copyright Text"

                control={control}
                isLoading={true}
                valueGot={Data.copyright_text}
                setValue={setValue}
                helperText={errors?.copyright_text && errors?.copyright_text?.message}
              />

            </GridItem>
          </AccordionDetails>
          <AccordionActions>
            <GridItem
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Box display="flex-end" style={{ marginBottom: '10px' }}>
                <Button type="submit" variant="contained" color="primary">Submit</Button>
              </Box>
            </GridItem>
          </AccordionActions>
        </Accordion>
      </form>

    </div>
  )
}

export default copyrightText
