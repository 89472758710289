import React, { useEffect } from "react";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
// @material-ui/icons
import Avatar from '@material-ui/core/Avatar';
import Person from "@material-ui/icons/Person";
import { AccountCircle } from "@material-ui/icons";
import { ListItemIcon } from "@material-ui/core";
import { ListItemText } from "@material-ui/core";
import Notifications from "@material-ui/icons/Notifications";
import Dashboard from "@material-ui/icons/Dashboard";
import Search from "@material-ui/icons/Search";
import ExitToApp from "@material-ui/icons/ExitToApp";
// core components
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";

import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle";
import moment from "moment-timezone";
import {signOut} from "services/cognitouser";

import { spinLoaderShow } from 'redux/action/spinLoader'
import { useDispatch } from "react-redux";
import {

  useHistory

} from "react-router-dom";

const styles:any = headerLinksStyle;
const useStyles = makeStyles(styles);

export default function AdminNavbarLinks() {
  const dispatch = useDispatch()
  let history = useHistory();

  const classes = useStyles();
  const [openNotification, setOpenNotification] = React.useState<any>(null);
  const [currentTime, setCurrentTime] = React.useState<any>(moment().tz('Asia/Dubai').format('DD-MMM-YYYY HH:mm:ss'));
  const [openProfile, setOpenProfile] = React.useState<any>(null);
  const [name ,setName]=React.useState<any>("")
  const handleClickNotification = (event:any) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const handleClickProfile = (event:any) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = async (type?:any) => {
    if(type == "logout"){
      dispatch(spinLoaderShow(true))

      await signOut();
      window.location.href = "/"
      dispatch(spinLoaderShow(false))

    }
    setOpenProfile(null);
  };

  useEffect(()=>{
    setInterval(()=>{
      setCurrentTime(moment().tz('Asia/Dubai').format('DD-MMM-YYYY HH:mm:ss'))
    },1000)
    let name = localStorage.getItem("name")
    setName(name)
  },[])


  
  return (
    <div>

      <div className={classes.manager}>
       {currentTime}
      </div>
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}
        >
          <AccountCircle fontSize="large" />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText} >Profile</p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              //id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                  <MenuItem>
          <ListItemIcon>
            <Person  />
          </ListItemIcon>
          <ListItemText>{name}</ListItemText>
          
        </MenuItem>
                    <Divider light />
                    <MenuItem onClick={(e)=>{handleCloseProfile(); history.push("/admin/profile")}}>
          <ListItemIcon>
            <AccountCircle />
          </ListItemIcon>
          <ListItemText >Profile</ListItemText>
          
        </MenuItem>
                    <Divider light />
                    <MenuItem onClick={(e)=>{ handleCloseProfile("logout")}}>
          <ListItemIcon>
           <ExitToApp />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
          
        </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    </div>
  );
}
