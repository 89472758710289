import { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import GridItem from "../../Grid/GridItem";
import { FileUploadArea } from 'components/fileupload/fileuploadArea.component';
import otherImg from 'services/userservices'
import { useDispatch } from "react-redux";
import { spinLoaderShow } from 'redux/action/spinLoader'
import {svgChecker} from 'helper/common'


export const ImageField = (props: any) => {

    const [image, setImage] = useState<any>(null);
    const dispatch = useDispatch()

    useEffect(() => {
        if (props) {
            setImage(props?.getValues(`section2[${props?.index}.image]`))
        }
    }, [props])

    const handlesaveImage = async (files: any) => {
        dispatch(spinLoaderShow(true))
        if (files) {
            const formData = new FormData()
            formData.append('images', files[0].file)
            const { data } = await otherImg(formData)
            setImage(data?.result[0])
            props?.setValue(`section2[${props?.index}.image]`, data?.result[0])
            dispatch(spinLoaderShow(false))
        }
        dispatch(spinLoaderShow(false))

    }

    return (
        <>
            <GridItem xs={3} sm={3} md={3} className={"mt-15" +" " + svgChecker(image) ? "svg-background" : ""}>
                <img className={props?.classes?.media} src={image} />
            </GridItem>

            <GridItem xs={9} sm={9} md={9} className={"mt-15"}>
                <Box className={props?.classes?.drop}>
                    <FileUploadArea
                        handleSave={handlesaveImage}
                        message={"Drag and drop a file here or Click. Note: Only svg will be allowed Optimize image size is 75 x 75 pixels"}
                    />
                </Box>
            </GridItem>
        </>

    )


}