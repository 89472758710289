import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import PageHeader from "components/PageHeader/PageHeader";
import StatusComponent from "components/Mix/Status";
import AgGridCustom from "components/AgGridCustom/AgGridCustom";
import moment from "moment";
import EditIcon from '@material-ui/icons/Edit';
import { Link } from "react-router-dom";
import { verifyPermission } from 'helper/permission'
import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux'
import { spinLoaderShow } from "redux/action/spinLoader";
import {createFacilities, getFacilities, updateFacilities,getFacility}  from 'services/userservices'

import axios from "axios";
import { Button } from "@material-ui/core";

const styles: any = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);


//main function FacilitiesList...

export const FacilitiesList = (props: any) => {
  const getPermission = useSelector((state: any) => { return state.userPermission.permissions })
  const classes = useStyles();

  //create states....// 

  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState<any>(null);
  const [rowData, setRowData] = useState<any>([])
  const [disabled, setDisabled] = useState<any>(false)
  const [exportData, setExportData] = useState<any>(null)
  const dispatch = useDispatch()

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

 // create routes

  const routes = [
    {
      'name': "Admin",
      'link': '/admin'
    },
    {
      'name': "Sports/Classes ",
      'link': ''
    },
    {
      'name': "Add ",
      'link': '/admin/facilities/add'
    },
  ];

  //get facilityvenues data using Get Api...

  useEffect(() => {
    dispatch(spinLoaderShow(true))
    getFacility().then((res) => {
     
      setRowData(res.data.result)
      dispatch(spinLoaderShow(false))
    }).catch((err) => {
     
      dispatch(spinLoaderShow(false))
    })

    let ispermission = !(verifyPermission(
      getPermission,
      'Facility',
      'Manage',
      'update'
    ))
   
    setDisabled(ispermission)

  }, [])

  //set data in facilityvenues field........

  const columnDefs: any = [

    {
      headerName: "Name",
      field: "name",
    },
    {
      headerName: "Type",
      field: "type"
    },

    {
      headerName: 'Status',
      field: "status",
      cellRendererFramework: (params: any) => {
        // put the value in bold
        return <StatusComponent data={params} />;
      }
    },
    {
      headerName: 'Created Date',
      field: "createdAt",
      filter: "agDateColumnFilter",
      cellRendererFramework: (params: any) => {
        return moment(params.value).format('llll');
      },
    },
    {
      headerName: 'Modified Date',
      field: "updatedAt",
      filter: "agDateColumnFilter",
      sort: "desc",
      cellRendererFramework: (params: any) => {
        return moment(params.value).format('llll');
      }
    },

    {
      headerName: 'Actions',
      field: "action",
      sortable: false,
      filter: false,
      hide: disabled,
      cellRendererFramework: (params: any) => {
        // put the value in bold
        return <Link to={'facilities/edit?uuid=' + params?.data?.uuid}><EditIcon /></Link>;
      }
    },
  ]

  //start JSX//
  
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GridItem xs={12} sm={12} md={12}>
          <PageHeader
            title="Sports/Classes "
            routes={routes}
            newItem={[{ text: 'Add New Sports/Classes', link: "facilities/add" }]}
            permission="Facility.Manage.create"

          />
        </GridItem>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary" className="card-header-export-btn">
            <h4 className={classes.cardTitleWhite}>Sports/Classes</h4>
            <Button variant="outlined" className="custom-btn export-btn" onClick={(e: any) => exportData.exportDataAsCsv()}>Export to Csv</Button>


          </CardHeader>
          <CardBody>
            <AgGridCustom
              columnDefs={columnDefs}
              rowData={rowData}
              getRowNodeId={(data: any) => {
                return data.id;
              }}
              setExportData={setExportData}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
