import React, { Fragment, useDebugValue, useEffect, useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { useFieldArray } from "react-hook-form";
import { Box, Button, ButtonGroup } from "@material-ui/core";
import TimeScheduledArray from "./TimeScheduledArray";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Theme, createStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    cardCategoryWhite: {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: 300,
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
    }
  }),
);

const
  TimeScheduledContainer = (props: any) => {

    const { modifyEditData } = props;
    const { control, errors, setValue, editData, trigger, getValues, nestIndex, register, setRemoveVenue, removeVenue } = props;
    const { fields, remove, append } = useFieldArray({
      control: control,
      name: `venuesData[${nestIndex}].days`
    });

    let days: any = [
      { uuid: 'f6890dc0-3d5e-11ec-88ca-f993264f78ff', name: 'Monday' },
      { uuid: 'fa40c920-3d5f-11ec-88ca-f993264f78ff', name: 'Tuesday' },
      { uuid: 'fd47c790-3d5f-11ec-88ca-f993264f78ff', name: 'Wednesday' },
      { uuid: '004465c0-3d60-11ec-88ca-f993264f78ff', name: 'Thursday' },
      { uuid: '0285ca40-3d60-11ec-88ca-f993264f78ff', name: 'Friday' },
      { uuid: '203b8890-3d60-11ec-88ca-f993264f78ff', name: 'Saturday' },
      { uuid: 'f113a760-3d5e-11ec-88ca-f993264f78ff', name: 'Sunday' }
    ];

    useEffect(() => {

      if (props?.dataLength < (nestIndex + 1)) {
        days.map((item: any, index: any) => {
          remove(index)
          append({
            day: item,
            schedules: [],
            venue_id: getValues(`venues[${nestIndex}]`)?.uuid
          })
        })
      }
      // return ()=>{
      //   for (let index = 0; index < getValues(`venuesData[${nestIndex}].days`).length; index++) {
      //     remove(index)
      //   }
      // }
    }, [])

    return (
      <Box className={"w-100"}>
        {fields.map((item: any, index: any) => {
          return (
            <Accordion className={errors?.venuesData?.[nestIndex]?.days?.[index]?.schedules?.length > 0 ? 'error-accordion' : ""}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id={`panel-${index}-header`}
              >
                <Typography>{item?.day?.name}</Typography>
              </AccordionSummary>
              <AccordionDetails className={" d-block  "}>
                <TimeScheduledArray modifyEditData={modifyEditData} nestIndex={index} day={item?.day} venuesNestedIndex={nestIndex} {...{ control, register, setValue, getValues, errors, trigger }} />
              </AccordionDetails>
            </Accordion>
          )
        })
        }
      </Box >
    );
  }

export default TimeScheduledContainer;