import React, { useEffect, useState } from 'react'
// @material-ui/core components
import { PermIdentity, MailOutline, PhoneAndroid, Message } from '@material-ui/icons';
import Card from '@material-ui/core/Card';
// core components
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PageHeader from "components/PageHeader/PageHeader";
import axios from 'axios';
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router';
import { spinLoaderShow } from 'redux/action/spinLoader';
import { getContactUs } from 'services/userservices';



const useStyles = makeStyles({
  root: {
    minWidth: 275,
    width: '100%',
    maxWidth: 360,
  },
});

//create routes
const routes = [
  {
    'name': "Admin",
    'link': '/admin'
  },
  {
    'name': "Contact Us ",
    'link': ''
  }
];

export default function Contact() {
  const dispatch = useDispatch()
//create states....//
  const [getData, setGetData] = useState<any>({})
  const { search } = useLocation()
  let uuid = new URLSearchParams(search).get("uuid");

 //get contact data using Get Api...//

  useEffect(() => {
    dispatch(spinLoaderShow(true));
   
    getContactUs({ uuid: uuid }).then((res) => {
      let data = res.data.result?.[0];
      setGetData(data);
      dispatch(spinLoaderShow(false));
    })

  }, [])



  //start jSx....
  
  return (
    <>
      <PageHeader
        title={`Contact Us`}
        routes={routes}
        newItem={[{ text: 'Back', link: "contactus" }]}
      />
      <Card>
        <CardContent>
          <List>
            <ListItem button>
              <ListItemIcon>
                <PermIdentity />
              </ListItemIcon>
              <ListItemText primary={getData.first_name} />
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <MailOutline />
              </ListItemIcon>
              <ListItemText primary={getData.email} />
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <Message />
              </ListItemIcon>
              <ListItemText primary={getData.message} />
            </ListItem>

            
          </List>
        </CardContent>
      </Card></>

  )
}


