import React, { useEffect, useState } from "react";
// react plugin for creating charts
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Danger from "components/Typography/Danger";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CardFooter from "components/Card/CardFooter";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle";
import { Link } from "react-router-dom";
import { getBookingDetailView, getCustomer, getFacility, getVenue } from "services/userservices";
import { useDispatch } from "react-redux";
import { spinLoaderShow } from "redux/action/spinLoader";

declare var google: any;
const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  // const [defaultMarkers, setDefaultMarkers] = useState<any>([]);
  const [customer, setCustomer] = useState<any>(null);
  const [venue, setVenue] = useState<any>(null);
  const [booking, setBooking] = useState<any>();
  const [Sports, setSports] = useState<any>(null)
  const dispatch = useDispatch();

  var locations: any = [
    [-33.890542, 151.274856, 4],
    [-33.923036, 151.259052, 5],
    [-34.028249, 151.157507, 3],
    [-33.80010128657071, 151.28747820854187, 2],
    [-33.950198, 151.259302, 1]
  ];

  const onLoad = (marker: any) => {
    
  }
  const position: any = {
    lat: 37.772,
    lng: -122.214
  }


  // useEffect(() => {
  //   let temp = [];
  //   for (var i = 0; i < locations.length; i++) {
  //     temp.push({
  //       lat: locations[1][0],
  //       lng: locations[1][1]
  //     });
  //   }
  //   setDefaultMarkers(temp)
  // }, [])

  // get customer data ===============>
  useEffect(() => {
    dispatch(spinLoaderShow(true));
    getCustomer().then((res: any) => {
      let getCustomer = res.data.result
      let getCustomersize = Object.keys(getCustomer).length;
      setCustomer(getCustomersize)
      dispatch(spinLoaderShow(false));




    }).catch((err) => {
  
      dispatch(spinLoaderShow(false));


    })
  }, [])
  // get Facility data ===============>

  useEffect(() => {
    getFacility().then((res: any) => {
      let Facilitytemp = res.data.result
      let size = Object.keys(Facilitytemp).length;
      setSports(size)



    }).catch((err) => {



    })
  }, [])

  // get getVenue data ===============>

  useEffect(() => {
    getVenue().then((res: any) => {
      let temp = res.data.result
      let size = Object.keys(temp).length;
      setVenue(size)      
    }).catch((err) => {
   


    })
  }, [])

  // get BookingDetail data ===============>

  useEffect(() => {
    getBookingDetailView().then((res: any) => {
      let BookingDetailView = res.data.result
      let BookingDetailViewsize = Object.keys(BookingDetailView).length;
      setBooking(BookingDetailViewsize)

    }).catch((err) => {



    })
  }, [])

  const onHeatmapLayerLoad = (heatmapLayer: any) => {

  }

  const onUnmount = (heatmapLayer: any) => {

  }

  return (
    <div>
      {/* <GridContainer>
        <GridItem xs={12} sm={6} md={3}>


          <Link to={"./customer"}>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <Icon>person_icon</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Customer</p>
                <h3 className={classes.cardTitle}>
                  { customer }
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <Danger>
                  </Danger>
                </div>
              </CardFooter>
            </Card>
          </Link>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Link to={"./facilities"}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <Icon>sports_cricket_icon</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Sports/Classes</p>
                <h3 className={classes.cardTitle}>{Sports}</h3>
              </CardHeader>
              <CardFooter stats>
              </CardFooter>
            </Card>
          </Link>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Link to={"./venue"}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <Icon>room_icon</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Venues</p>
                <h3 className={classes.cardTitle}>{venue}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                </div>
              </CardFooter>
            </Card>
          </Link>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Link to={"./booking"}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <Icon>today_icon</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Bookings</p>
                <h3 className={classes.cardTitle}>{booking}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                </div>
              </CardFooter>
            </Card>
          </Link>
        </GridItem>

      </GridContainer> */}


      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>

          <div className={"backgroundImage"} >
            <img src={"https://assets.playa.ae/assets/facilities_images/8ab480fe-d426-4529-9cdc-46f6d4b28e4b.png"} alt="image" />
          </div>

        </GridItem>
      </GridContainer>

    </div>
  )
}