import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { Box, Button, Container, makeStyles } from '@material-ui/core';
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { useForm } from 'react-hook-form';
import PageHeader from 'components/PageHeader/PageHeader';
import { spinLoaderShow } from 'redux/action/spinLoader'
import { useDispatch, useSelector } from "react-redux";
import WhatsappNumber from "components/ApplicationSetting/whatsappNumber.component";
import Email from "components/ApplicationSetting/Email.component";
// import ApplicationSecurity from "components/ApplicationSetting/ApplicationSecurity.component";
// import TermsCondition from "components/ApplicationSetting/TermsCondition.component";
// import PrivacyPolicy from "components/ApplicationSetting/PrivacyPolicy.component";
// import CookiePolicy from "components/ApplicationSetting/CookiePolicy.component";
import Vat from "components/ApplicationSetting/vat.component";
import VatNumber from "components/ApplicationSetting/VatNumber.component";
import Socials from "components/ApplicationSetting/Socials.component";
import Footer from "components/ApplicationSetting/copyrightText.component";
import MaxDays from "components/ApplicationSetting/maxDays.component";
import FooterText from "components/ApplicationSetting/footerText.component";
import OfficeAdress from "components/ApplicationSetting/adress.component";
import ContactNumber from "components/ApplicationSetting/phone.component";
import ContactusMap from "components/ApplicationSetting/contactMap.component";
// import AboutUs from "components/ThemeSetting/AboutUs.component";
import { getApplicationSetting, updateApplicationSetting } from 'services/userservices'
import { verifyPermission } from 'helper/permission'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




const Accordion = withStyles({
  root: {
    borderRadius: '10px',
    boxShadow: '2px 3px 15px 10px rgba(0,0,0,2)',
    WebkitBoxShadow: ' 2px 3px 4px rgba(0,0,0,2)',
    marginBottom: 10,
    marginTop: 5,

    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#043cae',
    borderRadius: '5px',
    color: 'white',
    marginBottom: -1,
    marginTop: 5,
    minHeight: 56,
    '&$expanded': {
      minHeight: 20,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),


  },
}))(MuiAccordionDetails);

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 180,
    width: 180,
  },
  drop: {
    maxHeight: 400,
    width: 400,
  },
  cont1: {
    display: `flex`,

  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210
  },
  textClass: {
    fontSize: 18
  },



});


//Home Function.... 
export default function Home() {
  let name = "Collapse";
  const [expanded, setExpanded] = useState<any>(false);
  const [storeData, setData] = useState<any>([]);
  // const [updateData, setUpdateData] = useState<any>([]);
  const getPermission = useSelector((state: any) => { return state.userPermission.permissions })
  const [disabled, setDisabled] = useState<any>(false)
  const dispatch = useDispatch()

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  //Get data applicationsetting

  useEffect(() => {

    dispatch(spinLoaderShow(true))
    getApplicationSetting().then((res) => {
      setData(res.data.result[0])
      dispatch(spinLoaderShow(false))

    }).catch((err) => {
      dispatch(spinLoaderShow(false))
 
    })
    let ispermission = !(verifyPermission(
      getPermission,
      'Application Setting',
      'Setting',
      'update'
    ))
 
    setDisabled(ispermission)

  }, [])



  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setValue,
    getValues
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    // defaultValues: defaultFormValues,
    // resolver: yupResolver(homeValidations),
  });

  //Routes 
  const routes = [
    {
      'name': "Admin",
      'link': '/admin'
    },
    {
      'name': "Setting ",
      'link': ''
    }
  ];

  const classes = useStyles();

  //create function get Child Data from components...

  const childData = (data: any) => {
    // get Child Data from components & update data 
    dispatch(spinLoaderShow(true))
   
    if (data) {
      dispatch(spinLoaderShow(true))
      updateApplicationSetting(data).then((res) => {
        toast.success("Record updated successfully")
        dispatch(spinLoaderShow(false))
      }).catch((err) => {
        dispatch(spinLoaderShow(false))

      })
    }



  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <PageHeader
            title={`Setting`}
            routes={routes}
          />
        </GridItem>

      </GridContainer>

      {/* <WhatsappNumber
        classes={classes}
        getData={childData}
        Data={storeData}
        permission={disabled}
      /> */}

      <Email
        classes={classes}
        getData={childData}
        Data={storeData}
        permission={disabled} />

      <OfficeAdress
        classes={classes}
        getData={childData}
        Data={storeData}
        permission={disabled} />
        
      <ContactNumber
        classes={classes}
        getData={childData}
        Data={storeData}
        permission={disabled} />


      <ContactusMap
        classes={classes}
        getData={childData}
        Data={storeData}
        permission={disabled} />

        

      {/* <ApplicationSecurity
        classes={classes}
        getData={childData}
        Data={storeData}
        permission={disabled} />

      <TermsCondition
        classes={classes}
        getData={childData}
        Data={storeData}
        permission={disabled}
      />

      <PrivacyPolicy
        classes={classes}
        getData={childData}
        Data={storeData}
        permission={disabled} />

      <CookiePolicy
        classes={classes}
        getData={childData}
        Data={storeData}
        permission={disabled} /> */}

      {/* <Vat
        classes={classes}
        getData={childData}
        Data={storeData}
        permission={disabled} /> */}

      {/* <VatNumber
        classes={classes}
        getData={childData}
        Data={storeData}
        permission={disabled} /> */}
        
      <MaxDays
        classes={classes}
        getData={childData}
        Data={storeData}
        permission={disabled} />


      <Socials
        classes={classes}
        getData={childData}
        Data={storeData}
        permission={true} />

      <Footer
        classes={classes}
        getData={childData}
        Data={storeData}
        permission={disabled} />

      <FooterText
        classes={classes}
        getData={childData}
        Data={storeData}
        permission={disabled} />



    </div>
  );
}




