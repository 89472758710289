
import {ADD_USER , ADD_USER_ATTRIBUTES} from '../types/user';
const initialState = {
  user: {},
  userattr : null  
}


const getUser = (state:any, payload:any) => ({
  ...state,
  user: payload
})
const getUserAtriv = (state:any, payload:any) => ({
  ...state,
  userattr: payload
})



export default function user(state = initialState, action:any) {
    switch (action.type) {
      case ADD_USER_ATTRIBUTES:
        return getUserAtriv(state,action.payload)
      case ADD_USER:
        return getUser(state,action.payload)
        default:
        return state
    }
  }