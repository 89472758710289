import React, { useEffect, useState } from "react";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
const AgGridCustom = (props: any) => {
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState<any>(null);
  const onGridReady = (params: any) => {
    if(props?.setExportData){
      props?.setExportData(params?.api)
    }
  
    // setGridApi(params.api);
    // setGridColumnApi(params.columnApi);
    // params.api.showNoLoadingOverlay()
  };


  const { columnDefs, rowData, serverSideDatasource, headerCheckboxSelection, headerCheckboxSelectionFilteredOnly, checkboxSelection, getRowNodeId,id } = props;
  const onBtnExport = () => {
    gridApi.exportDataAsCsv();
  };


  return (
    <div className="example-wrapper">
      <div
        id="myGrid"
        style={{
          height: '80vh',
          width: '100%',
        }}
        className="ag-theme-material"
      >
        
        <div className="ag-grid-custom-table" style={{"height":"92%"}}>
          <AgGridReact
            columnDefs={columnDefs}
            
            defaultColDef={{
              minWidth: 125,
              flex: 1,
              sortable: true,
              filter: true,
              resizable: true
            }}
            id={id}
            scroll={true}
            animateRows={true}
            rowData={rowData}
            rowSelection='multiple'
            getRowNodeId={getRowNodeId}
            enableCellTextSelection={true}
            onGridReady={onGridReady}
            serverSideDatasource={serverSideDatasource}
            {...props}
            pagination={true}
            paginationPageSize={10}
          />
        </div>
      </div>
    </div>

  )
}

export default AgGridCustom;