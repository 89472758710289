import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import PageHeader from 'components/PageHeader/PageHeader'
import { CardMedia, Container, Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { InputField, AutoCompleteField, AutoCompleteMultipleField, SwitchField, SwitchFieldDefault, DateField, TextEditorField } from "components/Forms/Formfield.component";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux'
import { createfacilitiesValidations } from './Validations/facilitiesValidations'
import TimeScheduledContainer from 'components/TimeScheduledArray/TimeScheduledContainer'
import facilitiesImg, { createFacilities, getFacilities, updateFacilities } from 'services/userservices'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useLocation
} from "react-router-dom";
import { getVenue } from 'services/userservices'
import moment from "moment";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { spinLoaderShow } from "redux/action/spinLoader";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import { FileUploadArea } from "components/fileupload/fileuploadArea.component";
import _ from 'lodash'




const styles: any = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },

  accord: {
    color: "white",
    backgroundColor: "#043cae",
    borderRadius: "5px",
    marginTop: "5px"

  },
  mt15: {
    marginTop: "0px",
  },

  text: {
    width: "100%",
    marginTop: "1rem"
  },
  drop: {
    height: 250,
    width: 500,
    fontSize: "15px",
    display: "flex",
    flexDirection: "row"
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210
  },
  media: {
    height: "100%",
    width: 300,
  },
  textClass: {
    fontSize: 13
  },
  contaienr: {
    padding: 10,
    height: 100

  },
  swiperContainer: {
    ".swiper-slide": {
      width: '150px'
    },

  },

  button: {
    width: '100%'
  },
  img: {
    width: "150px",
    height: "150px",
    objectfit: "contain"
  }


};

const defaultFormValues: any = {
  name: '',
  description: '',
  venue: '',
  status: true,
  venuesData: []
}


const useStyles = makeStyles(styles);
declare var google: any;

//main function AddFacilities...
export default function AddFacilities(props: any) {
  const classes = useStyles();
  const [Venues, setVenues] = useState<any>([])
  const [editData, setEditData] = useState<any>(null)
  const [modifyEditData, setModifyEditData] = useState<any>([])
  const [headerTitle, setHeaderTitle] = React.useState<string>('Add');
  const [venue, setVenue] = useState<any>([])
  const [removeVenue, setRemoveVenue] = useState<any>('')
  const typeOption = [{ id: 1, name: 'Classes' }, { id: 2, name: 'Sports' }]
  const [listingImgMob, setListingImgMob] = useState<any>("")
  const [listingImgDesk, setListingImgDesk] = useState<any>("")
  const [carousalImg, setCarousalImg] = useState<any>("")
  const [bannerImg, setBannerImg] = useState<any>("")
  const [dataLength, setDataLength] = useState<any>(0)

  let history = useHistory();
  const { search } = useLocation()
  let uuid = new URLSearchParams(search).get("uuid");


  useEffect(() => {
    getVenue({ status: true }).then((e) => {
      setVenues(e?.data?.result)
    })
  }, [])

  //get facilityvenues data  using Get Api...

  const getFacilityData = () => {
    setHeaderTitle('Edit')

    getFacilities({ 'facility_id': uuid }).then((res) => {
      let facility: any = res?.data?.result[0]?.facilities
      let data: any = res?.data?.result
      setValue("name", facility?.name);
      setValue("title", facility?.name);
      setValue("long_desc", facility?.long_desc);
      setValue("short_desc", facility?.short_desc);
      setValue("status", facility?.status);
      setValue("slug", facility?.slug);
      setValue("listingImage_Desktop", facility?.listingImage_Desktop);
      setValue("listingImage_Mobile", facility?.listingImage_Mobile);
      setValue("carouselImage", facility?.carouselImage);
      setValue("bannerImage",facility?.bannerImage);


      setListingImgMob(facility?.listingImage_Mobile);
      setListingImgDesk(facility?.listingImage_Desktop);
      setCarousalImg(facility?.carouselImage);
      setBannerImg(facility?.bannerImage)
      setEditData(data?.[0])
      setVenue(data.map((x: any) => {
        return x.venues
      }))

      let days: any = [
        { day: { uuid: 'f6890dc0-3d5e-11ec-88ca-f993264f78ff', name: 'Monday' } },
        { day: { uuid: 'fa40c920-3d5f-11ec-88ca-f993264f78ff', name: 'Tuesday' } },
        { day: { uuid: 'fd47c790-3d5f-11ec-88ca-f993264f78ff', name: 'Wednesday' } },
        { day: { uuid: '004465c0-3d60-11ec-88ca-f993264f78ff', name: 'Thursday' } },
        { day: { uuid: '0285ca40-3d60-11ec-88ca-f993264f78ff', name: 'Friday' } },
        { day: { uuid: '203b8890-3d60-11ec-88ca-f993264f78ff', name: 'Saturday' } },
        { day: { uuid: 'f113a760-3d5e-11ec-88ca-f993264f78ff', name: 'Sunday' } }
      ];

      var v: any = []
      data.map((item: any) => {
        let daysModify: any = days.map((x: any) => {
          let day: any = x;
          return {
            ...day,
            schedules: item?.timeslots?.filter((y: any) => x?.day?.uuid == y.day_id).map((o: any) => {
              // o.end_time = new Date('04 Dec 2021 ' + o.end_time + ':00');
              // o.start_time = new Date('04 Dec 2021 ' + o.start_time + ':00')

              o.end_time = moment(o.end_time, "HH:mm").toDate();
              o.start_time = moment(o.start_time, "HH:mm").toDate();

              return o
            }).sort(function compare(a:any, b:any) {
              var dateA:any = new Date(a.start_time);
              var dateB:any = new Date(b.end_time);
              return dateA - dateB;
            }),
            ...{ venue_id: item.venue_id }
          }
        })

        v.push(
          {
            "days": daysModify
          }
        )
      })

      setDataLength(v.length)
      setModifyEditData({ 'venuesData': v })
      setValue('venuesData', v)
      dispatch(spinLoaderShow(false))

    }).catch((err) => {
    
    })
  };



  //create useEffect to spinLoaderShow and run function getFacilityData....

  useEffect(() => {
    if (uuid) {
      dispatch(spinLoaderShow(false))
      getFacilityData()

    }
    else {

      setHeaderTitle('Add')
      reset(defaultFormValues)
      setEditData({})


    }
  }, [uuid])


  const dispatch = useDispatch()
  const { control, register, handleSubmit, formState: { errors }, setValue, reset, getValues, trigger } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultFormValues,
    resolver: yupResolver(createfacilitiesValidations)
  });

  // create routes
  const routes = [
    {
      'name': "Admin",
      'link': '/admin'
    },
    {
      'name': "Sports/Classes ",
      'link': '/admin/facilities'
    },
    {
      'name': headerTitle,
      'link': ''
    },
  ];


  const onSubmit = (data: any) => {
    dispatch(spinLoaderShow(true))
    data.title = data.name;
    var duplicate :any= [];
    var duplicateMessage :any= "";

    var overLap = false;
    var overLapMessage = "";

    let _data = data.venuesData.map(function (item: any, index: any) {
      var bookData = item;

      let days_modify = item.days
        .flat()
        .filter((item: any) => item.schedules.length > 0)
        .map((item: any, index: any) => item.schedules)
        .flat()
        .map((item: any, index: any) => {
          return {
            "uuid": item.uuid,
            "venue_id": item.venue_id,
            "start_time": moment(item.start_time).format('HH:mm'),
            "end_time": moment(item.end_time).format('HH:mm'),
            "persons": item.persons,
            "price": item.price,
            "day_id": item.day_id,
            "status": item.status,
            "slug": item.slug
          }
        })
      let schedules_slots: any = {}
      schedules_slots.schedules = days_modify;
      const unique:any = [];

       var tempDup = schedules_slots.schedules.filter((o:any)=> {

        if(unique.find((i:any) => i.start_time === o.start_time && i.end_time === o.end_time && i.day_id === o.day_id)) {
          return true
        }
     
        unique.push(o)
        return false;
     })
     
     if(duplicate.length < 1 && tempDup.length > 0){
      duplicate = tempDup
      duplicateMessage = "Please check on " + Venues.find((x :any)=> x.uuid == bookData.days[0].venue_id).name;
     
     }

     
     if(isOverlapping(schedules_slots.schedules)){
      
      overLapMessage = "Please check on " + Venues.find((x :any)=> x.uuid == bookData.days[0].venue_id).name;
      overLap = true;
     }



      schedules_slots.venue_id = item?.days?.[0]?.venue_id
      return schedules_slots
    }).filter((item: any) => item.schedules.length > 0)
    delete data.venue;
    delete data.venuesData;
    data.venues = _data;
    data.type = data.type.name
    if(duplicate && duplicate.length > 0){
      toast.error("Duplicate Time Slot Found! "+ duplicateMessage)
      dispatch(spinLoaderShow(false))

     return ;
    }

    if(overLap){
      toast.error("Overlapping Time Slots are not allowed! " + overLapMessage)
      dispatch(spinLoaderShow(false))

     return ;
    }

    
    if (uuid) {
      data.uuid = uuid
      updateOnSubmit(data)

    }
    else {
      createOnSubmit(data)

    }
   
  }

  const overlapping = (a:any, b:any) => {
    const getMinutes = (s:any) => {
       const p = s.split(':').map(Number);
       return p[0] * 60 + p[1];
    };
    return getMinutes(a.end_time) > getMinutes(b.start_time) && getMinutes(b.end_time) > getMinutes(a.start_time) && a.day_id == b.day_id;
 };
 const isOverlapping = (arr:any) => {
    let i, j;
    for (i = 0; i < arr.length - 1; i++) {
        for (j = i + 1; j < arr.length; j++) {
          if (overlapping(arr[i], arr[j])) {
             return true;
          }
       };
    };
    return false;
 };


  const handleVenueChange = (data: any) => {
   
    var dif: any = _.differenceBy(venue, data, 'uuid');
    if (dif.length > 0) {
      
      let venueRemoveIndex:any = venue.findIndex((e: any) => e.uuid == dif?.[0]?.uuid)
      setRemoveVenue(venueRemoveIndex)
      setVenue(data);

    }
    setVenue(data);
  }


  const handleSaveListingMob = async (files: any) => {
    if (files.length > 0) {
      dispatch(spinLoaderShow(true))
      const formData = new FormData()
      formData.append('images', files[0].file)
      const { data } = await facilitiesImg(formData)
      setListingImgMob(data.result[0])
      setValue("listingImage_Mobile",data.result[0])
      dispatch(spinLoaderShow(false))
    }

  }
  
  const handleSaveListingDesk = async (files: any) => {
    if (files.length > 0) {
      dispatch(spinLoaderShow(true))
      const formData = new FormData()
      formData.append('images', files[0].file)
      const { data } = await facilitiesImg(formData)
      setListingImgDesk(data.result[0])
      setValue("listingImage_Desktop",data.result[0])
      dispatch(spinLoaderShow(false))
    }

  }
  const handleSaveCarousal = async (files: any) => {
    if (files.length > 0) {
      dispatch(spinLoaderShow(true))
      const formData = new FormData()
      formData.append('images', files[0].file)
      const { data } = await facilitiesImg(formData)
      setCarousalImg(data.result[0])
      setValue("carouselImage",data.result[0])
      dispatch(spinLoaderShow(false))
    }

  }
  const handleSaveBanner = async (files: any) => {
    if (files.length > 0) {
      dispatch(spinLoaderShow(true))
      const formData = new FormData()
      formData.append('images', files[0].file)
      const { data } = await facilitiesImg(formData)
      setBannerImg(data.result[0])
      setValue("bannerImage",data.result[0])

      dispatch(spinLoaderShow(false))
    }

  }
  //post facilities data using Get Api...

  const createOnSubmit = (data: any) => {

    dispatch(spinLoaderShow(true))
    createFacilities(data).then((res) => {
      dispatch(spinLoaderShow(true))
      toast.success("Record inserted successfully.");
      reset(defaultFormValues)
      dispatch(spinLoaderShow(false))
      history.push("/admin/facilities");
    }).catch((err) => {
     
      dispatch(spinLoaderShow(false))
    })

    //  
  };



  //Update  function create facilities...

  const updateOnSubmit = (data: any) => {

    updateFacilities(data).then((e: any) => {
      dispatch(spinLoaderShow(true));
      setEditData([])
      toast.success("Record updated successfully");
      setVenue([])
      reset(defaultFormValues)
      history.push("/admin/facilities");

    })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });

  };


  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <PageHeader
              title="Sports/Classes "
              routes={routes}
              newItem={[
                { text: 'Back', link: "/admin/facilities" }]}
              backIcon
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>{headerTitle} Sports/Classes </h4>
              </CardHeader>
              <CardBody>
                <GridContainer spacing={3}>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.name}
                      fieldName="name"
                      type="text"
                      autoComplete='off'
                      label="* Name"
                      control={control}
                      valueGot={""}
                      setValue={setValue}
                      helperText={errors?.name && errors?.name?.message}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.long_desc}
                      // rows={2}
                      multiline={1}
                      fieldName="long_desc"
                      type="text"
                      autoComplete='off'
                      label="* Long Description"
                      control={control}
                      valueGot={""}
                      setValue={setValue}
                      helperText={errors?.long_desc && errors?.long_desc?.message}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.short_desc}
                      multiline={1}
                      fieldName="short_desc"
                      type="text"
                      autoComplete='off'
                      label="* Short Description"
                      control={control}
                      valueGot={""}
                      setValue={setValue}
                      helperText={errors?.short_desc && errors?.short_desc?.message}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <AutoCompleteMultipleField
                      errors={!!errors?.venues}
                      fieldName="venues"
                      autoComplete="off"
                      label="*Venues"
                      control={control}
                      setValue={setValue}
                      iProps={{
                        onChange: handleVenueChange
                      }}
                      options={Venues}
                      isLoading={true}
                      optionKey="uuid"
                      optionValue="name"
                      helperText={
                        errors?.venues && errors?.venues?.message
                      }
                      valueGot={venue}


                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <AutoCompleteField
                      errors={!!errors?.type}
                      fieldName="type"
                      autoComplete="off"
                      label="* Type"
                      control={control}
                      setValue={setValue}
                      options={typeOption}
                      returnObject={true}
                      isLoading={true}
                      optionKey="id"
                      optionValue="name"
                      helperText={
                        errors?.type && errors?.type?.message
                      }
                      valueGot={editData?.facilities?.type && typeOption.find((i: any) => i.name == editData?.facilities?.type)}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={4} md={4}>
                    <InputField
                      errors={!!errors?.slug}
                      fieldName="slug"
                      type="text"
                      autoComplete="false"
                      label="* Slug"
                      control={control}
                      valueGot={""}
                      setValue={setValue}
                      helperText={errors?.slug && errors?.slug?.message}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4} style={{ marginTop: "15px", marginBottom: "15px" }}>
                    <SwitchFieldDefault
                      errors={!!errors?.status}
                      fieldName="status"
                      autoComplete="off"
                      label="Status"
                      control={control}
                      isLoading={true}
                      setValue={setValue}
                      helperText={
                        errors?.status &&
                        errors?.status?.message
                      }
                    />
                  </GridItem>

                  {/* Search Desktop  accordion start */}
                  <GridItem xs={12} sm={12} md={12} >
                    <Typography className={classes.heading} variant="subtitle1"><b>Images</b> </Typography>
                    <Accordion  >
                      <AccordionSummary className={classes.accord}
                        expandIcon={<ExpandMoreIcon className={classes.accord} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Search Desktop </Typography>

                      </AccordionSummary>
                      <AccordionDetails>
                      <input {...register("listingImage_Desktop")} type="hidden" />
                        <GridItem xs={12} sm={12} md={12} >
                          <Container className={classes.cont1}>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={4}>
                                <CardMedia
                                  className={classes.media}
                                  image={listingImgDesk ? listingImgDesk : "https://www.lifewire.com/thmb/P856-0hi4lmA2xinYWyaEpRIckw=/1920x1326/filters:no_upscale():max_bytes(150000):strip_icc()/cloud-upload-a30f385a928e44e199a62210d578375a.jpg"}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={4}>
                                <Box className={classes.drop}>
                                  <FileUploadArea

                                    handleSave={handleSaveListingDesk}
                                    message={"Drag and drop a file here or Click. Note: Only jpeg, png will be allowed Optimize image size is 490 x 678 pixels"}
                                  />
                                </Box>
                                  <Typography> <span className="imageError">{errors.listingImage_Desktop &&  errors?.listingImage_Desktop?.message}</span></Typography>
                              </GridItem>
                            </GridContainer>
                          </Container>
                        </GridItem>
                      </AccordionDetails>
                    </Accordion>
                  </GridItem>
                  {/* Search  Desktop accordion end */}


                  {/*  Search Mobile  accordion start */}
                  <GridItem xs={12} sm={12} md={12} >

                    <Accordion  >
                      <AccordionSummary className={classes.accord}
                        expandIcon={<ExpandMoreIcon className={classes.accord} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Search Mobile </Typography>

                      </AccordionSummary>
                      <AccordionDetails>
                      <input {...register("listingImage_Mobile")} type="hidden" />

                        <GridItem xs={12} sm={12} md={12} >
                          <Container className={classes.cont1}>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={4}>
                                <CardMedia
                                  className={classes.media}
                                  image={listingImgMob ? listingImgMob : "https://www.lifewire.com/thmb/P856-0hi4lmA2xinYWyaEpRIckw=/1920x1326/filters:no_upscale():max_bytes(150000):strip_icc()/cloud-upload-a30f385a928e44e199a62210d578375a.jpg"}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={4}>
                                <Box className={classes.drop}>
                                  <FileUploadArea

                                    handleSave={handleSaveListingMob}
                                    message={"Drag and drop a file here or Click. Note: Only jpeg, png will be allowed Optimize image size is 400 x 320 pixels"}
                                  />
                                </Box>
                                <Typography> <span className="imageError">{errors.listingImage_Mobile &&  errors?.listingImage_Mobile?.message}</span></Typography>
                              </GridItem>
                            </GridContainer>
                          </Container>
                        </GridItem>
                      </AccordionDetails>
                    </Accordion>
                  </GridItem>
                  {/* Search Mobile  accordion start */}


                  {/* carousel  accordion start */}
                  <GridItem xs={12} sm={12} md={12} >
                    <Accordion  >
                      <AccordionSummary className={classes.accord}
                        expandIcon={<ExpandMoreIcon className={classes.accord} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Home Page Carousel</Typography>

                      </AccordionSummary>
                      <AccordionDetails>
                      <input {...register("carouselImage")} type="hidden" />
                        <GridItem xs={12} sm={12} md={12} >
                          <Container className={classes.cont1}>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={4}>
                                <CardMedia
                                  className={classes.media}
                                  image={carousalImg ? carousalImg : "https://www.lifewire.com/thmb/P856-0hi4lmA2xinYWyaEpRIckw=/1920x1326/filters:no_upscale():max_bytes(150000):strip_icc()/cloud-upload-a30f385a928e44e199a62210d578375a.jpg"}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={4}>
                                <Box className={classes.drop}>
                                  <FileUploadArea

                                    handleSave={handleSaveCarousal}
                                    message={"Drag and drop a file here or Click. Note: Only jpeg, png will be allowed Optimize image size is 333 x 629 pixels"}
                                  />
                                </Box>
                                <Typography> <span className="imageError">{errors.carouselImage &&  errors?.carouselImage?.message}</span></Typography>
                              </GridItem>
                            </GridContainer>
                          </Container>
                        </GridItem>
                      </AccordionDetails>
                    </Accordion>
                  </GridItem>
                  {/* carousel  accordion end */}


                  {/* Banner Image  accordion start */}
                  <GridItem xs={12} sm={12} md={12} >
                    <Accordion  >
                      <AccordionSummary className={classes.accord}
                        expandIcon={<ExpandMoreIcon className={classes.accord} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Banner Image </Typography>

                      </AccordionSummary>
                      <AccordionDetails>
                      <input {...register("bannerImage")} type="hidden" />

                        <GridItem xs={12} sm={12} md={12} >
                          <Container className={classes.cont1}>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={4}>
                                <CardMedia
                                  className={classes.media}
                                  image={bannerImg ? bannerImg : "https://www.lifewire.com/thmb/P856-0hi4lmA2xinYWyaEpRIckw=/1920x1326/filters:no_upscale():max_bytes(150000):strip_icc()/cloud-upload-a30f385a928e44e199a62210d578375a.jpg"}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={4}>
                                <Box className={classes.drop}>
                                  <FileUploadArea

                                    handleSave={handleSaveBanner}
                                    message={"Drag and drop a file here or Click. Note: Only jpeg, png will be allowed Optimize image size is 1920 x 350 pixels"}
                                  />
                                </Box>
                                <Typography> <span className="imageError">{errors.bannerImage &&  errors?.bannerImage?.message}</span></Typography>

                              </GridItem>
                            </GridContainer>
                          </Container>
                        </GridItem>
                      </AccordionDetails>
                    </Accordion>
                  </GridItem>
                  {/* Banner Image  accordion end */}

                </GridContainer>
              </CardBody>

              <GridItem xs={12} sm={12} md={12}>
                {venue && venue.length > 0 && <Typography className={classes.heading} variant="subtitle1"> <b>Slots Management</b> </Typography>}

                {venue.map((item: any, index: any) => {
                  return <>
                    <Accordion className={errors?.venuesData?.[index]?.days?.length > 0 ? 'error-accordion' : ""}>
                      <AccordionSummary className={classes.accord}
                        expandIcon={<ExpandMoreIcon className={classes.accord} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.heading}> {item.name} </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <TimeScheduledContainer setRemoveVenue={setRemoveVenue} removeVenue={removeVenue} modifyEditData={modifyEditData} dataLength={dataLength} isEdit={!!uuid} nestIndex={index} {...{ control, register, setValue, getValues, errors, trigger }} />
                      </AccordionDetails>
                    </Accordion>
                    {/* {errors?.venuesData?.[index]?.days?.length > 0 && 'Error'} */}
                  </>

                })}
              </GridItem>
              <CardFooter>
                <Box display="flex" justifyContent="flex-end" style={{ width: '100%' }}>
                  <Button color="primary" className={"custom-btn"} type="submit" >{uuid ? 'Update Sports/Classes' : "Create Sports/Classes"}</Button>
                </Box>
              </CardFooter>
            </Card>

          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}
