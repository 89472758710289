import * as yup from "yup";

const aboutValidations  :any = yup.object().shape({
    who_we_are: yup.string().required("This field is empty").nullable(),
    our_mission: yup.string().max(100, "Max character limit is 100").required("This field is empty").nullable(),
    our_vision: yup.string().max(100, "Max character limit is 100").required("This field is empty").nullable(),
    book_person: yup.string().nullable(),

    testimonial: yup.array().of(

      yup.object().shape({

         description: yup.string().required('This field is empty').nullable(),

         name: yup.string().required('This field is empty').nullable(),
         designation: yup.string().required('This field is empty').nullable()

      }))
   //  description: yup.string().required("Please enter book person").nullable(),
   //  name: yup.string().required("Please enter book person").nullable(),
   //  designation: yup.string().required("Please enter book person").nullable(),

    
  });
  export{
     aboutValidations,
  }