import React, { useEffect } from 'react'
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import { Box, Button } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import otherImg from 'services/userservices'
import { useDispatch } from "react-redux";
import { spinLoaderShow } from 'redux/action/spinLoader'
import { FileUploadArea } from "components/fileupload/fileuploadArea.component"
import { Navigation, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { AccordionActions, Typography } from "@material-ui/core";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useState } from "react";


const Accordion = withStyles({
    root: {
        borderRadius: '10px',
        boxShadow: '2px 3px 15px 10px rgba(0,0,0,2)',
        WebkitBoxShadow: ' 2px 3px 4px rgba(0,0,0,2)',
        marginBottom: 10,
        marginTop: 5,

        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#043cae',
        borderRadius: '5px',
        color: 'white',

        marginBottom: -1,
        marginTop: 5,
        minHeight: 56,
        '&$expanded': {
            minHeight: 20,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',

        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(1),

    },
}))(MuiAccordionDetails);

const ExpandIcon = {
    color: "#ffffff"
};

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 180,
        width: 180,
    },
    drop: {
        maxHeight: 400,
        width: 400,
    },
    cont1: {
        display: `flex`,

    },
    previewChip: {
        minWidth: 160,
        maxWidth: 210
    },
    textClass: {
        fontSize: 18
    },
});




const HomeDesktop = (props: any) => {
    const dispatch = useDispatch()
    const [disable, setDisable] = useState<any>(true)
    const [imageSlider, setImageSlider] = useState<any>([])
    const { getData, Data, permission } = props;
    const [open, setOpen] = useState(false);
    const [removeImage, setRemoveImage] = useState("");

    const homeDesktopImg = async (files: any) => {

        dispatch(spinLoaderShow(true));
        if (files) {
            setDisable(false)
            const formData = new FormData()
            for (let i = 0; i < files.length; i++) {
                formData.append("images", files[i].file);
            }
            const { data } = await otherImg(formData)
            let temp: any = [];
            data?.result?.map((item: any, index: any) => {
                temp.push(item);
            });
            const mergeTemp = [...imageSlider, ...temp];
            setImageSlider(mergeTemp);
        }
        dispatch(spinLoaderShow(false));
    }

    useEffect(() => {
        if (Data) {
            setImageSlider(Data?.home)
        }
    }, [props])

    const uploadImage = async () => {
        dispatch(spinLoaderShow(true));
        if (imageSlider.length > 0) {
            let temp = {
                "home": imageSlider
            }
            getData(temp)
        }
        dispatch(spinLoaderShow(false));
    }

    //Delete Image
    const deletImg = (index?: any) => {
        dispatch(spinLoaderShow(true))
        const deletImg = imageSlider.filter((fileObjects: any, i: any) => index !== i);
    
        setImageSlider(deletImg)
      dispatch(spinLoaderShow(false))
        handleClose()

    };
    //Dialog Box

    const handleClose = () => {
        setOpen(false);
    };

    // Dialog Box

    return (
        <div>
            <form>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={ExpandIcon} />}>
                        <Typography> Home Desktop </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <GridContainer>
                            <GridItem xs={12} md={12} sm={12} className={"swiperContainer"}>
                                {/* Swiper */}
                                <Swiper
                                    modules={[Navigation, A11y]}
                                    spaceBetween={20}
                                    slidesPerView='auto'
                                    navigation
                                >
                                    {
                                        // swiper map
                                        imageSlider?.map((item: any, index: any) => {
                                            return <SwiperSlide>
                                                <Box>
                                                    <img src={item} style={imagesss} />
                                                    <Button
                                                        variant="outlined"
                                                        style={btn}
                                                        startIcon={<DeleteIcon />}
                                                        onClick={() => {
                                                            setOpen(true);
                                                            setRemoveImage(index);
                                                            setDisable(false)
                                                        }}
                                                    >
                                                        Delete
                                                    </Button>

                                                    <Dialog
                                                        open={open}
                                                        onClose={handleClose}
                                                        aria-labelledby="alert-dialog-title"
                                                        aria-describedby="alert-dialog-description"
                                                    >
                                                        <DialogTitle id="alert-dialog-title">
                                                            {"Are You Sure You want to Delete?"}
                                                        </DialogTitle>

                                                        <DialogActions>
                                                            <Button onClick={handleClose} color="primary">
                                                                Cancel
                                                            </Button>
                                                            <Button
                                                                onClick={() => {
                                                                    deletImg(index);
                                                                }}
                                                                color="primary"
                                                                autoFocus
                                                            >
                                                                Yes
                                                            </Button>
                                                        </DialogActions>
                                                    </Dialog>

                                                </Box>
                                            </SwiperSlide>
                                        })
                                    }
                                </Swiper>
                            </GridItem>
                        </GridContainer>
                    </AccordionDetails>
                    <AccordionActions>
                        <Box display="flex" justifyContent="center" style={{ width: '100%' }}>
                            <FileUploadArea 
                            handleSave={homeDesktopImg} 
                            message={"Drag and drop a file here or Click. Note: Only jpeg, png will be allowed Optimize image size is 1920 x 500 pixels"}
                            />
                        </Box>
                    </AccordionActions>
                    <AccordionActions>
                        <GridItem
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <Box display="flex-end" style={{ marginBottom: '10px' }}>
                                {permission === true ? <Button disabled={disable} onClick={uploadImage} variant="contained" color="primary">Submit</Button> : ''}
                            </Box>
                        </GridItem>
                    </AccordionActions>
                </Accordion>

            </form>

        </div>
    )
    // classes for style components
}
const imagesss = {
    width: "150px",
    height: "150px",
    objectfit: "contain"
};
const btn = { width: '100%' }

const swiperContainer = {
    ".swiper-slide": {
        width: '150px'
    },

}

export default HomeDesktop
