import { useState,useEffect } from 'react';
import {  Box  } from '@material-ui/core';
import GridItem from "../../Grid/GridItem";
import { FileUploadArea } from 'components/fileupload/fileuploadArea.component';
import otherImg from 'services/userservices'
import { useDispatch } from "react-redux";
import { spinLoaderShow } from 'redux/action/spinLoader'







export const ImageField =(props:any)=>{

    const [image, setImage] = useState<any>(null);
    const dispatch=useDispatch()

useEffect(()=>{
if(props){
    setImage( props?.getValues(`testimonial[${props?.index}.image]`))
}
},[props])

    const handlesaveImage = async (files:any) => {
       dispatch(spinLoaderShow(true))
         if(files) {
            const formData = new FormData()
            formData.append('images', files[0].file)
            const { data } = await otherImg(formData)
            setImage(data?.result[0])
        props?.setValue( `testimonial[${props?.index}.image]`, data?.result[0])
        dispatch(spinLoaderShow(false))
         }
        dispatch(spinLoaderShow(false))

      }

return(
<>
    <GridItem xs={3} sm={3} md={3}  className={"mt-15"}>
  <img className={props?.classes?.media} src={image} />
</GridItem>

<GridItem xs={9} sm={9} md={9} className={"mt-15"}>
    <Box className={props?.classes?.drop}>
        <FileUploadArea
            handleSave={handlesaveImage}
            message={"Drag and drop a file here or Click. Note: Only jpeg, png will be allowed Optimize image size is 720 x 875 pixels"}
        />
    </Box>
</GridItem>
</>

)


}