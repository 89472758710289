import React from 'react'
import Dashboard from "@material-ui/icons/Dashboard";
import LocationOn from "@material-ui/icons/LocationOn";
import RoomIcon from '@material-ui/icons/Room';
import Notifications from '@material-ui/icons/Notifications';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import SportsCricketIcon from '@material-ui/icons/SportsCricket';
import PersonIcon from '@material-ui/icons/Person';
import TodayIcon from '@material-ui/icons/Today';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import SettingsIcon from '@material-ui/icons/Settings';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import PeopleIcon from '@material-ui/icons/People'; 
export const itemsList: any = [
  {
    key: 11,
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    layout: "/admin",
  },
  {
    key: 123273,
    path: "/users",
    name: "Users",
    rtlName: "طباعة",
    icon: PeopleIcon,
    layout: "/admin",

  },
  {
    key: 12393,
    path: "/venue",
    name: "Venue",
    rtlName: "طباعة",
    icon: RoomIcon,
    layout: "/admin",

  },
  {
    key: 128893,
    path: "/holiday",
    name: "Holiday",
    rtlName: "طباعة",
    icon: AccessTimeIcon,
    layout: "/admin",

  },
  {
    key: 16548,
    path: "/facilities",
    name: "Sports/Classes",
    rtlName: "إخطارات",
    icon: SportsCricketIcon,
    layout: "/admin",
  },

  {
    key: 1232132,
    path: "/customer",
    name: "Customer",
    rtlName: "طباعة",
    icon: PersonIcon,
    layout: "/admin",

  },
  {
    key: 123223,
    path: "/booking",
    name: "Booking",
    rtlName: "طباعة",
    icon: TodayIcon,
    layout: "/admin",

  },
  // {
  //   key: 123224,
  //   path: "/transaction",
  //   name: "Transaction",
  //   rtlName: "طباعة",
  //   icon: AccountBalanceIcon,
  //   layout: "/admin",

  // },
  {
    key: 123218,
    path: "/contactus",
    name: "Contact Us",
    rtlName: "طباعة",
    icon: RecentActorsIcon,
    layout: "/admin",

  },
 
  // {
  //   key: 123243,
  //   name: "FAQ",
  //   icon: LiveHelpIcon,
  //   submenus: '8',
  //   children: [
  //     {
  //       submenus: '12345',
  //       key: 7044,
  //       path: "/faq",
  //       name: "FAQ",
  //       rtlName: "طباعة",
  //       icon: QuestionAnswerIcon,
  //       layout: "/admin",
  //     },
  //     {
  //       submenus: '1234567',
  //       key: 7166,
  //       path: "/faq/type",
  //       name: "FAQ Types",
  //       rtlName: "طباعة",
  //       icon: ContactSupportIcon,
  //       layout: "/admin",
  //     },
  //   ]
  // },
 
  {
    key: 10,
    name: "Application Setting",
    icon: SettingsIcon,
    submenus: '5',
    children: [
      {
        submenus: '6',
        key: 70111,
        path: "/setting",
        name: "Settings",
        rtlName: "طباعة",
        icon: SettingsApplicationsIcon,
        layout: "/admin",
      },
      {
        submenus: '7',
        key: 7022,
        path: "/theme-setting",
        name: "Theme Setting",
        rtlName: "طباعة",
        icon: PhonelinkSetupIcon,
        layout: "/admin",
      },
    ]
  },


]