import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import AgGridCustom from "components/AgGridCustom/AgGridCustom";
import PageHeader from "components/PageHeader/PageHeader";
import Button from "components/CustomButtons/Button";
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { spinLoaderShow } from "redux/action/spinLoader";
import { Visibility } from "@material-ui/icons";
import {getContactUs}  from 'services/userservices'
import { verifyPermission } from "helper/permission";
import moment from "moment";



const styles: any = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

//main function ContactUs...

export const ContactUs = (props: any) => {

  // create states....
  const classes = useStyles();
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState<any>(null);
  const [exportData, setExportData] = useState<any>(null)
  const [disabled, setDisabled] = useState<any>(false)
  const [contact, setContact] = useState<any>([])
  const dispatch = useDispatch()



  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  // create routes

  const routes = [
    {
      'name': "Admin",
      'link': '/admin'
    },
    {
      'name': "Contact Us ",
      'link': ''
    }
  ];

  //get contact data using Get Api...

  useEffect(() => {
    dispatch(spinLoaderShow(true));
    getContactUs().then((res) => {
      setContact(res.data.result)
      dispatch(spinLoaderShow(false))
    }).catch((err) => {
      dispatch(spinLoaderShow(false))

    })

    // let ispermission = !(verifyPermission(
    //   getPermission,
    //   'Facilities',
    //   'Manage',
    //   'update'
    // ))
   
    // setDisabled(ispermission)

  }, [])


//set data in contact field........

  const columnDefs: any = [
    {
      headerName: "Name",
      field: "first_name",

    },
    , 
    // {
    //   headerName: "Last Name",
    //   field: "last_name",

    // },
    {
      headerName: "Email",
      field: "email",

    },
    {
      headerName: "Message",
      field: "message",

    },
  
    {
      headerName: "Date",
      field: "createdAt",
      cellRendererFramework: (params: any) => {
        return moment(params.value).format('llll');
      },
    },
    {
      headerName: 'View',
      field: "action",
      sortable: false,
      filter: false,
      hide: disabled,
      cellRendererFramework: (params: any) => {
        // put the value in bold
        return <Link to={'contact-details?uuid=' + params?.data?.uuid}><Visibility /></Link>;
        // return <Link to={'contactus/edit?uuid=' + params?.data?.uuid}><EditIcon /></Link>;
      }
    },
  ]

  //start jSx....
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GridItem xs={12} sm={12} md={12}>
          <PageHeader
            title={`Contact Us`}
            routes={routes}
          // newItem={[{ text: 'Add New Contact', link: "contact-manager/add" }]}
          />
        </GridItem>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary" className="card-header-export-btn">
            <h4 className={classes.cardTitleWhite}>Contact Listing</h4>
            <Button variant="outlined" className="custom-btn export-btn" onClick={(e: any) => exportData.exportDataAsCsv()}>Export to Csv</Button>

          </CardHeader>
          <CardBody>
            <AgGridCustom
              columnDefs={columnDefs}
              rowData={contact}
              headerCheckboxSelection="true"
              headerCheckboxSelectionFilteredOnly="true"
              checkboxSelection="true"
              getRowNodeId={function (data: any) {
                return data.id;
              }}
              
              setExportData={setExportData}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

