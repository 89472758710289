/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
import LanguageIcon from '@material-ui/icons/Language';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import PlaceIcon from '@material-ui/icons/Place';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import BusinessIcon from '@material-ui/icons/Business';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import TranslateIcon from '@material-ui/icons/Translate';
import CommuteIcon from '@material-ui/icons/Commute';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import SettingsIcon from '@material-ui/icons/Settings';
import AirlineSeatReclineExtraIcon from '@material-ui/icons/AirlineSeatReclineExtra';
import EditIcon from '@material-ui/icons/Edit';
export const routes: any = [
  {
    key: 11,
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    layout: "/admin",
  },
  {
    key: 12,
    path: "/user",
    name: "Staff Mananger",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    layout: "/admin",
  },
  {
    key: 123123,
    path: "/scheduler",
    name: "Scheduler",
    rtlName: "طباعة",
    icon: AttachMoneyIcon,
    layout: "/admin",
  },
  {
    key: 1231236,
    name: "Location",
    icon: LanguageIcon,
    submenus: '554',
    children: [
      {
        submenus: '1',
        key: 31131,
        path: "/country-manager",
        name: "Country",
        rtlName: "طباعة",
        icon: LanguageIcon,
        layout: "/admin",
      },
      {
        submenus: '2',
        key: 1541,
        path: "/city-manager",
        name: "City",
        rtlName: "طباعة",
        icon: LocationCityIcon,
        layout: "/admin",
      },
      {
        submenus: '3',
        key: 711234,
        path: "/branch-manager",
        name: "Branch",
        rtlName: "خرائط",
        icon: LocationOn,
        layout: "/admin",
      },
      {
        submenus: '4',
        key: 5123123,
        path: "/zone-manager",
        name: "Zone",
        rtlName: "طباعة",
        icon: PlaceIcon,
        layout: "/admin",
      },
    ]
  },
  {
    key: 15,
    path: "/language-manager",
    name: "Language",
    rtlName: "طباعة",
    icon: TranslateIcon,
    layout: "/admin",
  },
  {
    key: 6,
    name: "Vehicles",
    icon: AirportShuttleIcon,
    submenus: '1',
    children: [
      {
        submenus: '1',
        key: 61,
        path: "/vehicletype-manager",
        name: "Vehicle Type",
        rtlName: "طباعة",
        icon: CommuteIcon,
        layout: "/admin",
      },
      {
        submenus: '2',
        key: 61,
        path: "/vehiclemodel-manager",
        name: "Vehicle Model",
        rtlName: "طباعة",
        icon: LibraryBooks,
        layout: "/admin",
      },
      {
        submenus: '3',
        key: 64,
        path: "/vehiclemake-manager",
        name: "Vehicle Make",
        rtlName: "طباعة",
        icon: LibraryBooks,
        layout: "/admin",
      }
    ]
  },
  {
    key: 10,
    name: "Customer",
    icon: PeopleOutlineIcon,
    submenus: '2',
    children: [
      {
        submenus: '2',
        key: 66,
        path: "/customervehicle-manager",
        name: "Customer Vehicles",
        rtlName: "طباعة",
        icon: LibraryBooks,
        layout: "/admin",
      },

    ]
  },
  {
    key: 99,
    path: "/promotion-manager",
    name: "Promotion",
    rtlName: "إخطارات",
    icon: Notifications,
    layout: "/admin",
  },
  {
    key: 10,
    name: "Notification",
    icon: Notifications,
    submenus: '3',
    children: [
      {
        submenus: '3',
        key: 69,
        path: "/push-notification",
        name: "Push Notification",
        rtlName: "طباعة",
        icon: Notifications,
        layout: "/admin",
      },

    ]
  },
  {
    key: 21,
    path: "/driver-manager",
    name: "Driver",
    rtlName: "إخطارات",
    icon: AirlineSeatReclineExtraIcon,
    layout: "/admin",
  },
  {
    key: 16,
    path: "/notifications",
    name: "Booking",
    rtlName: "إخطارات",
    icon: Notifications,
    layout: "/admin",
  },
 
  {
    key: 10,
    name: "Content",
    icon: EditIcon,
    submenus: '4',
    children: [
      {
        submenus: '5',
        key: 70,
        path: "/content-manager",
        name: "Content Management",
        rtlName: "طباعة",
        icon: LibraryBooks,
        layout: "/admin",
      },
      {
        submenus: '6',
        key: 71,
        path: "/email-template",
        name: "Email Template",
        rtlName: "طباعة",
        icon: LibraryBooks,
        layout: "/admin",
      }

    ]
  },

  {
    key: 10,
    name: "Setting",
    icon: SettingsIcon,
    submenus: '5',
    children: [
      {
        submenus: '6',
        key: 70,
        path: "/site-maintenance",
        name: "Site Maintenance",
        rtlName: "طباعة",
        icon: LibraryBooks,
        layout: "/admin",
      },
      {
        submenus: '7',
        key: 71,
        path: "/application-setting",
        name: "Application Setting",
        rtlName: "طباعة",
        icon: LibraryBooks,
        layout: "/admin",
      },
     

    ]
  },
  {
    key: 28,
    path: "/vendor-manager",
    name: "Vendor",
    rtlName: "إخطارات",
    icon: AirlineSeatReclineExtraIcon,
    layout: "/admin",
  },
];
