import React, { useEffect, useState } from 'react'
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import GridItem from "../../Grid/GridItem";
import { AccordionActions, Box, Button } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDispatch } from "react-redux";
import { InputField, TextEditorField } from 'components/Forms/Formfield.component';
import { useForm, useFieldArray } from "react-hook-form";
import GridContainer from 'components/Grid/GridContainer';
import { termsAndConditionsValidations } from "./Validation/termsAndConditionsValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import { ImageField } from "./fieldArr-image"
import 'react-toastify/dist/ReactToastify.css';
import { spinLoaderShow } from 'redux/action/spinLoader'



const Accordion = withStyles({
    root: {
        borderRadius: '10px',
        boxShadow: '2px 3px 15px 10px rgba(0,0,0,2)',
        WebkitBoxShadow: ' 2px 3px 4px rgba(0,0,0,2)',
        marginBottom: 10,
        marginTop: 5,

        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',


        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#043cae',
        borderRadius: '5px',
        color: 'white',

        marginBottom: -1,
        marginTop: 5,
        minHeight: 56,
        '&$expanded': {
            minHeight: 20,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',

        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(1),


    },
}))(MuiAccordionDetails);

const ExpandIcon = {
    color: "#ffffff"
};

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
    },

    media: {
        height: 200,
        width: 259,
        marginTop: 15
    },
    drop: {
        maxHeight: 100,
        width: 100,
    },
    cont1: {
        display: `flex`,

    },
    previewChip: {
        minWidth: 160,
        maxWidth: 210
    },
    textClass: {
        fontSize: 18
    },



});
const imagesss = {
    height: 200,
    width: 259,
    marginTop: 15
};



const termsAndConditions = (props: any) => {
    const dispatch = useDispatch()
    const [bannerImg, setBannerImg] = useState<any>("");
    const [corporateVideo, setCorporateVide] = useState<any>("")



    // hookForm============================>

    const { classes, getData, Data, permission } = props;

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setError,
        setValue,
        getValues,
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(termsAndConditionsValidations),


    });

    //   field array ================================>

    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control,
        name: "section2",
    });



    // useEffect(() => {
    //     if (Data) {
    //       
    //         setValue("section1", String(Data?.termsandconditionPage?.section1))
    //         setValue("section2", Data?.termsandconditionPage?.section2)
    //         setValue("section3", String(Data?.termsandconditionPage?.section3))
    //     }
    // }, [Data])

    const onSubmit = (data: any) => {

        dispatch(spinLoaderShow(true));

        let modifyData = {
            "termsandconditionPage": data.termsandconditionPage
        }
        if (data) {
            getData(modifyData)
        }

        dispatch(spinLoaderShow(true));



    }

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Accordion  >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={ExpandIcon} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Terms And Conditions Page</Typography>

                    </AccordionSummary>
                    <AccordionDetails>

                        <GridItem xs={12} sm={12} md={12} className={"mt-15"}>
                            <GridContainer className={classes.cont1}>

                                {/* termsAndConditions======================> */}

                                <GridItem xs={12} sm={12} md={12}>
                                    <Typography> Terms And Conditions OF SBP </Typography>
                                    {/* <InputField
                                        className={"mt-15"}
                                        errors={!!errors?.section1}
                                        multiline={true}
                                        rows={5}
                                        fieldName="section1"
                                        type="text"
                                        autoComplete="false"
                                        label="Section 1"
                                        control={control}
                                        isLoading={true}
                                        valueGot={''}
                                        setValue={setValue}
                                        helperText={
                                            errors?.section1 && errors?.section1?.message
                                        }
                                    /> */}

                                    <TextEditorField
                                        className={"mt-15"}
                                        errors={!!errors?.applicationSecurity}
                                        textareaName="termsandconditionPage"
                                        fieldName="termsandconditionPage"
                                        type="text"
                                        autoComplete="false"
                                        label=""
                                        control={control}
                                        isLoading={true}
                                        valueGot={Data.termsandconditionPage}
                                        setValue={setValue}
                                        helperText={errors?.termsandconditionPage && errors?.termsandconditionPage?.message}


                                    />
                                </GridItem>
                                {/* Testimonial ======================> */}


                                {/* fieldArr ======================> */}
{/* 
                                <GridItem xs={12} sm={12} md={12} className={"mt-15"}>
                                    <Typography>Personal data collected for the following purposes</Typography>
                                    <hr />
                                    {fields?.map((item: any, index: any) => {
                                        return (
                                            <GridContainer>

                                           

                                                <ImageField key={`section2[${index}.image]`} className={classes.cont1} index={index} setValue={setValue} getValues={getValues} control={control} />

                                                <GridItem xs={3} sm={3} md={3}>
                                                    <InputField
                                                        errors={!!errors?.section2?.[index]?.title}
                                                        fieldName={`section2[${index}.title]`}
                                                        type="text"
                                                        autoComplete="off"
                                                        label="* Title"
                                                        control={control}
                                                        valueGot={""}
                                                        setValue={setValue}
                                                        helperText={errors?.section2?.[index]?.title && errors?.section2?.[index]?.title?.message}
                                                    />
                                                </GridItem>

                                                <GridItem xs={3} sm={3} md={3}>
                                                    <InputField
                                                        errors={!!errors?.section2?.[index]?.description}
                                                        fieldName={`section2[${index}.description]`}
                                                        type="text"
                                                        autoComplete="off"
                                                        label="* Description"
                                                        control={control}
                                                        valueGot={""}
                                                        setValue={setValue}
                                                        helperText={errors?.section2?.[index]?.description && errors?.section2?.[index]?.description?.message}
                                                    />
                                                </GridItem>



                                                <GridItem xs={3} sm={3} md={3} className={"mt-15"}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        className={"custom-btn"}
                                                        onClick={() => {
                                                            remove(index);
                                                        }}
                                                    >
                                                        Remove
                                                    </Button>
                                                </GridItem>

                                            </GridContainer>
                                        )
                                    })}

                                    <GridContainer
                                        justify="end"
                                        alignItems="center"
                                        className={"girdarrayaccordian"}
                                    >
                                     

                                        <GridItem xs={12} sm={12} md={12}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={"custom-btn"}
                                                onClick={() => {
                                                    append({ image: "", title: "", description: "" });
                                                }}
                                            >
                                                Add more
                                            </Button>
                                        </GridItem>


                                    </GridContainer>

                                </GridItem>

                                {/* Book Person Sport Now ============================> */}

                                {/* <GridItem xs={12} sm={12} md={12} className={"mt-15"}>

                                    <hr />
                                    <InputField
                                        className={"mt-15"}
                                        errors={!!errors?.section3}
                                        multiline={true}
                                        rows={5}
                                        fieldName="section3"
                                        type="text"
                                        autoComplete="false"
                                        label="Section 3"
                                        control={control}
                                        isLoading={true}
                                        valueGot={''}
                                        setValue={setValue}
                                        helperText={
                                            errors?.section3 && errors?.section3?.message
                                        }
                                    />

                                </GridItem> */}

                            </GridContainer>
                        </GridItem>
                    </AccordionDetails>

                    <AccordionActions>
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            style={{ width: "100%" }}
                        >
                            <Button
                                color="primary"
                                variant="contained"
                                className={"custom-btn"}
                                type="submit"
                            >
                                submit
                            </Button>
                        </Box>



                    </AccordionActions>
                </Accordion>
            </form>


        </div>
    )
}

export default termsAndConditions

