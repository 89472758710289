import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import PageHeader from 'components/PageHeader/PageHeader'
import _ from 'lodash';
import { Box } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { InputField, LoadingButton, SwitchFieldDefault,PhoneNoField, SelectField } from "components/Forms/Formfield.component";
import { yupResolver } from "@hookform/resolvers/yup";
import { createCustomerValidation } from "./Validations/customerValidation";


const styles: any = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};
const options: any = [{ id: 1, name: "Bilal" }, { id: 2, name: "Haziq" }];
const countryOptions:any = [{id:1,name:"INDIA"},{id:2,name:"QATAR"},{id:2,name:"DUBAI"}];
const defautlFormValues = {
  first_name:'',
  last_name:'',
  email: '',
  gender:'',
  phone_number:'',
  status:true
}


const useStyles = makeStyles(styles);
declare var google: any;

//main function AddCustomer...
export default function AddCustomer(props: any) {
  const classes = useStyles();
  

  const { control, register, handleSubmit, clearErrors, setError, formState: { errors }, setValue } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defautlFormValues,
    resolver:yupResolver(createCustomerValidation)
  });

   // create routes//

  const routes = [
    {
      'name': "Admin",
      'link': '/admin'
    },
    {
      'name': "Customer",
      'link': '/admin/customer'
    },
    {
      'name': "Add",
      'link': ''
    }
  ];

  const onSubmit = async (data: any) => {
   
  };


  //start jSx....
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <PageHeader
              title="Customer"
              routes={routes}
              newItem={{ text: 'Back', link: "/admin/customer-manager" }}
              backIcon
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>New Customer</h4>
              </CardHeader>
              <CardBody>
                <GridContainer spacing={3}>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.first_name}
                      fieldName="first_name"
                      type="text"
                      autoComplete='false'
                      label="* First Name"
                      control={control}
                      valueGot={""}
                      setValue={setValue}
                      helperText={errors?.first_name && errors?.first_name?.message}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.last_name}
                      fieldName="last_name"
                      type="text"
                      autoComplete='false'
                      label="* Last Name"
                      control={control}
                      valueGot={""}
                      setValue={setValue}
                      helperText={errors?.last_name && errors?.last_name?.message}
                      
                    />
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <InputField
                      errors={!!errors?.email}
                      fieldName="email"
                      type="text"
                      autoComplete="false"
                      label="* Email Address"
                      control={control}
                      valueGot={""}
                      setValue={setValue}
                      helperText={
                        errors?.email && errors?.email?.message
                      }
                    />
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <SelectField
                      errors={!!errors?.gender}
                      fieldName="gender"
                      type="text"
                      autoComplete="false"
                      label="* Gender"
                      control={control}
                      valueGot={""}
                      selectOptions={
                        [{'id':'male','name':'Male'},{'id':'female','name':'Female'}]
                      }
                      setValue={setValue}
                      helperText={
                        errors?.gender && errors?.gender?.message
                      }
                    />
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <PhoneNoField
                      errors={!!errors?.phone_number}
                      fieldName="phone_number"
                      autoComplete="off"
                      label="* Phone Number"
                      control={control}
                      valueGot={""}
                      setValue={setValue}
                      helperText={
                        errors?.phone_number && errors?.phone_number?.message
                      }
                    />
                  </GridItem> 
                  <GridItem xs={12} sm={12} md={4} style={{ marginTop: "15px" }}>
                  <SwitchFieldDefault
                      errors={!!errors?.status}
                      fieldName="status"
                      autoComplete="off"
                      label="Status"
                      control={control}
                      isLoading={true}
                      setValue={setValue}
                      helperText={
                        errors?.status &&
                        errors?.status?.message
                      }
                    />
                  </GridItem>
                </GridContainer>

              </CardBody>
              <CardFooter>
              <Box display="flex" justifyContent="flex-end" style={{width:'100%'}}>
                    <LoadingButton
                      type="submit"
                      loading={false}
                      text="Add Customer"
                    />
                  </Box>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}

