import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import AgGridCustom from "components/AgGridCustom/AgGridCustom";
import PageHeader from "components/PageHeader/PageHeader";
import StatusComponent from 'components/Mix/Status';
import Button from "components/CustomButtons/Button";
import { getCustomer, updateCustomer } from 'services/userservices'
import { spinLoaderShow } from 'redux/action/spinLoader'
import { useDispatch } from "react-redux";
import { Switch, Grid } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from "react-toastify";
import moment from "moment";
// import {UPDATE_SUCCESS_MSG} from 'json/constants'


const styles: any = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

//main function CustomerList...

export const CustomerList = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState<any>(null);
  const [exportData, setExportData] = useState<any>(null)
  const [customer, setCustomer] = useState<any>([]);
  const [open, setOpen] = useState<any>(false)
  const [temp, setTemp] = useState<any>(false)
  const [rowData, setRowData] = useState([]);
  const [rowDataMain, setRowDataMain] = useState([]);

  //get customer data using Get Api...
  useEffect(() => {
    getData()
  }, [])



  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  // create routes

  const routes = [
    {
      'name': "Admin",
      'link': '/admin'
    },
    {
      'name': "Customer ",
      'link': ''
    }
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };


  const handleClose = () => {
    setOpen(false);
    getData();
  };



  // GET DATA FROM API
  const getData = () => {
    // START LOADER
    dispatch(spinLoaderShow(true))

    getCustomer().then((res:any)=>{
      let data =  res?.data?.result.map((item:any)=>{
        item.status=item.status? 'active' : 'inactive' 
         return item;
       })
      setRowData(data)
      dispatch(spinLoaderShow(false))
    }).catch((err)=>{

      dispatch(spinLoaderShow(false))

      
    })

  }

 

  const handleSwitchChange: any = (data: any) => {
  
    let val = {
      uuid: data.uuid,
      status: !data?.status
    }

    setTemp(val)
    setOpen(true)
  }



  //set data in customer field........

  const columnDefs: any = [
    {
      headerName: "First Name",
      field: "first_name",

    },
    {
      headerName: "Last Name",
      field: "last_name",

    },

    {
      headerName: "Email",
      field: "email",

    },
    {
      headerName: "Phone Number",
      field: "phone_number",

    },
    {
      headerName: "Nationality",
      field: "nationality",

    },
    {
      headerName: "Gender",
      field: "gender",

    },
    {
      headerName: "Date Of Birth",
      field: "date_of_birth",

    },
    {
      headerName: "Address",
      field: "address_line_one",

    },
    {
      headerName: "Address 2",
      field: "address_line_two",

    },
    {
      headerName: "Postal Code",
      field: "postal_code",

    },
    {
      headerName: "City",
      field: "city",

    },
    {
      headerName: "Country",
      field: "country",

    },
    {

      headerName: 'Status',
      field: "status",
      cellRendererFramework: (params: any) => {

        // put the value in bol
        return <StatusComponent data={params} />;

      }

    },
    {

      headerName: 'Marketing Opt',
      field: "marketOpt",
      cellRendererFramework: (params: any) => {

        // put the value in bol
        return <StatusComponent data={params} />;

      }

    },
    {
      headerName: "Modified Date",
      field: "updatedAt",
      cellRendererFramework: (params: any) => {
        return moment(params.value).format('llll');
      },
    },
    {
      headerName: "Created Date",
      field: "createdAt",
      cellRendererFramework: (params: any) => {
        return moment(params.value).format('llll');
      },
    },
    {
      headerName: 'Active',
      field: "enable",
      sortable: false,
      filter: false,
      cellRendererFramework: (params: any) => {
        // put the value in bold

        
        params.data.status = params.data.status == 'active' || (params?.data?.status !=="inactive" && params?.data?.status)? true : false
        let getData: any = params.data
     
      
        return (

          <Grid component="label" container alignItems="center" spacing={1}>
            {/* <Grid item>Off</Grid> */}
            <Grid item>
              <Switch
                color="primary"
                name="checked"
                checked={params.data.status}
                onChange={() =>{ handleSwitchChange(getData)}}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Grid>
            {/* <Grid item>On</Grid> */}
          </Grid>
        )
      }
    },


    
  ]

  const updateCostumerStatus = () => {
 
    dispatch(spinLoaderShow(true))


    // update Driver config api 
    updateCustomer(temp).then((e:any)=>{
      dispatch(spinLoaderShow(false))
      toast.success("Record updated Successfully");
      setTemp([])
      getData()
      setOpen(false)
    }).catch((err)=>{
      dispatch(spinLoaderShow(false));
    })


  }


  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GridItem xs={12} sm={12} md={12}>
          <PageHeader
            title={`Customer`}
            routes={routes}
          // newItem={[{ text: 'Add New Customer', link: "customer/add" }]}
          />
        </GridItem>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary" className="card-header-export-btn">
            <h4 className={classes.cardTitleWhite}>Customer Listing</h4>
            <Button variant="outlined" className="custom-btn export-btn" onClick={(e: any) => exportData.exportDataAsCsv()}>Export to Csv</Button>

          </CardHeader>
          <CardBody>
            <AgGridCustom
              columnDefs={columnDefs}
              rowData={rowData}
              headerCheckboxSelection="true"
              headerCheckboxSelectionFilteredOnly="true"
              checkboxSelection="true"
              getRowNodeId={function (data: any) {
                return data.id;
              }}
              setExportData={setExportData}
            />
          </CardBody>
        </Card>
      </GridItem>
      <Dialog
        open={open}
        onClose={handleClose}
        className={classes.dialog88}
        disableBackdropClick
      >
        <DialogTitle id="alert-dialog-title">{"Enable/Disable Customer Status?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure  ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { handleClose() }} color="primary">
            No
          </Button>
          <Button onClick={() => { updateCostumerStatus() }} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

    </GridContainer>

  );
}



