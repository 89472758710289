import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";
import { routes } from "../routes";
import { itemsList } from 'json/NavigationList';
import adminStyle from "assets/jss/material-dashboard-react/layouts/adminStyle";
const styles: any = adminStyle;
import bgImage from "assets/img/sidebar-2.jpg";
import logo from "assets/img/reactlogo.png";
import { useEffect } from "react";
import ProtectedRoute from "components/Route/ProtectedRoute";
import PrivateRoute from "components/Route/PrivateRoute";
import Home from "containers/ApplicationSetting/home";
import Bannerimages from "containers/ApplicationSetting/bannerimages";
import { VenueList } from "containers/Venue/venue-list-contrainter";
import AddVenue from "containers/Venue/venue-c.container";
import { useSelector } from 'react-redux';
import { PushNotification } from "containers/PushNotification/pushnotification-c-container";
import { EmailTemplate } from "containers/EmailTemplateManager/emailtemplate.container";
import EmailEdit from "containers/EmailTemplateManager/emailtemplate-e.container";
import Dashboard from "containers/Dashboard/Dashboard";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AdminList } from "containers/Admin/admin-list-contrainter";
import AddRole from "containers/Admin/roles-c.container";
import AddAdmin from 'containers/Admin/admin-c.container';
import FaqTypeList from 'containers/FaqTypes/faqtypes-list-container';
import { FacilitiesList } from 'containers/Facilities/facilities-list.container';
import AddFacilities from 'containers/Facilities/facilities-c.container';
import { Profile } from 'containers/Profile/profile-c.container';
import { CustomerList } from 'containers/Customer/customer-list-container';
import AddCustomer from 'containers/Customer/customer-c.container';
import AddHoliday from "containers/Holidays/holiday-c.container";
import { HolidayList } from "containers/Holidays/holiday-list.container";
import Users from 'containers/Users/Users';
import { BookingList } from 'containers/Booking/booking-list.container';
import { TransactionList } from 'containers/Transaction/transaction-list.container';
import AddBooking from 'containers/Booking/booking-c.contaner';
import { ContactUs } from 'containers/ContactUs/contactus-list-container';
import Contact from 'containers/ContactUs/contact';
import { FaqList } from 'containers/FAQ/faq-list.container';
import AddFaq from 'containers/FAQ/faq-c.container';
import ThemeSetting from "containers/ApplicationSetting/themesetting";
let ps: any;
const switchRoutes: any = (
  <Switch>
    <PrivateRoute
      path="/admin/dashboard"
      exact
      component={Dashboard}
    />
    <PrivateRoute path="/admin/users/add" exact component={AddAdmin} />
    <PrivateRoute path="/admin/users/edit" exact component={AddAdmin} />

    <PrivateRoute path="/admin/users" exact component={AdminList} />

    <PrivateRoute
      path="/admin/venue"
      exact
      component={VenueList}
    />
    <PrivateRoute
      path="/admin/venue/add"
      exact
      component={AddVenue}
    />
    <PrivateRoute
      path="/admin/venue/edit"
      exact
      component={AddVenue}
    />


    <PrivateRoute path="/admin/holiday" exact component={AddHoliday} />
    {/* <PrivateRoute
      path="/admin/holiday/add"
      exact
      component={AddHoliday}
    />
    <PrivateRoute
      path="/admin/holiday/edit"
      component={AddHoliday}
      exact
    /> */}
    <PrivateRoute path="/admin/facilities" exact component={FacilitiesList} />
    <PrivateRoute
      path="/admin/facilities/add"
      component={AddFacilities}
    />
    <PrivateRoute
      path="/admin/facilities/edit"
      component={AddFacilities}
      exact
    />
    <ProtectedRoute path="/admin/profile" exact component={Profile} />
    <PrivateRoute
      path="/admin/customer"
      exact
      component={CustomerList}
    />
    <PrivateRoute
      path="/admin/customer/add"
      exact
      component={AddCustomer}
    />
    <PrivateRoute
      path="/admin/setting"
      exact
      component={Home}
    />
    <PrivateRoute
      path="/admin/theme-setting"
      exact
      component={Bannerimages}
    />
    <PrivateRoute
      path="/admin/contact-details"
      exact
      component={Contact}
    />
    <PrivateRoute
      path="/admin/booking"
      exact
      component={BookingList}
    />
    <ProtectedRoute
      path="/admin/booking/view"
      exact
      component={AddBooking}
    />

    {/* <PrivateRoute
      path="/admin/transaction"
      exact
      component={TransactionList}
    /> */}
    <PrivateRoute
      path="/admin/contactus"
      exact
      component={ContactUs}
    />

    <Redirect from="/admin" to="/admin/dashboard" />

  </Switch >
);

const useStyles = makeStyles(styles);

const Admin = ({ ...rest }) => {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef<HTMLDivElement>();
  // states and functions
  const color = "green";
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  const spinLoaderShow: any = useSelector((state: any) => state?.spinLoader?.spinLoaderShow)

  // initialize and destroy the PerfectScrollbar plugin
  // React.useEffect(() => {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     let _mainPanel: any = mainPanel.current;
  //     ps = new PerfectScrollbar(_mainPanel, {
  //       suppressScrollX: true,
  //       suppressScrollY: false,
  //     });
  //     // document.body.style.overflow = "hidden";
  //   }
  //   window.addEventListener("resize", resizeFunction);
  //   // Specify how to clean up after this effect:
  //   return function cleanup() {
  //     if (navigator.platform.indexOf("Win") > -1) {
  //       ps.destroy();
  //     }
  //     window.removeEventListener("resize", resizeFunction);
  //   };
  // }, [mainPanel]);

  return (
    <div className={classes.wrapper}>
      <ToastContainer />
      <Sidebar
        routes={itemsList}
        logo={logo}
        image={bgImage}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes}</div>
        )}
        {getRoute() ? <Footer /> : null}
      </div>
      <Backdrop className={classes.backdrop} style={{ zIndex: 99 }} open={spinLoaderShow}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Admin;
