import * as yup from "yup";

const privacyPolicyValidations  :any = yup.object().shape({
   section1: yup.string().required("This field is empty").nullable(),
   section3: yup.string().required("This field is empty").nullable(),

    section2: yup.array().of(

      yup.object().shape({

         title: yup.string().required('This field is empty').nullable(),
         description: yup.string().required('This field is empty').nullable(),
      }))
    
  });
  export{
     privacyPolicyValidations,
  }