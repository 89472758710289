import React, { Fragment, useDebugValue, useEffect, useState } from "react";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { InputField, TimePickerField, SwitchField } from "components/Forms/Formfield.component";
import { useFieldArray } from "react-hook-form";
import { Box } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';


const TimeScheduledArray = (props: any) => {

  const { control, errors, setValue, editData, trigger, getValues, nestIndex, venuesNestedIndex, day, modifyEditData } = props;
  const [dateDisabled, setDateDisabled] = useState<any>([]);

  const { fields, remove, append } = useFieldArray({
    control: control,
    name: `venuesData[${venuesNestedIndex}].days[${nestIndex}].schedules`
  });

  const handleChange = (event: any) => {
    let fieldName: any = event.target.name.split(".")?.[3];
    //setDateDisabled({ ...dateDisabled, ...{ [fieldName]: event.target.checked } })
    setValue(event.target.name, event.target.checked)
  };

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
  }));
  const classes = useStyles();


  return (
    <Box className={"w-100"}>
      <GridContainer spacing={3} justify="center" alignItems="center">

        <GridItem xs={4} sm={4} md={3}>
          Start Time
        </GridItem>
        <GridItem xs={4} sm={4} md={3}>
          End Time
        </GridItem>
        <GridItem xs={4} sm={4} md={3}>
          Capacity
        </GridItem>
        <GridItem xs={4} sm={4} md={2}>
          Price (AED)
        </GridItem>
        <GridItem xs={2} sm={2} md={1}>
          On/Off
        </GridItem>
      </GridContainer>
      <GridContainer spacing={3} justify="center" alignItems="center">
        <GridItem xs={12} sm={12} md={12}>
          {fields.length > 0 && fields.map((item: any, index: any) => {
            let day_name: string = item?.key?.toLowerCase();
            return (
              <GridContainer key={item.id}>
                <GridItem xs={4} sm={4} md={3}>

                  {
                    item.uuid &&
                    <InputField
                      errors={!!errors?.venuesData?.[venuesNestedIndex]?.days?.[nestIndex]?.schedules?.[index]?.uuid}
                      fieldName={`venuesData[${venuesNestedIndex}].days[${nestIndex}].schedules.${index}.uuid`}
                      type="hidden"
                      autoComplete='false'
                      control={control}
                      setValue={setValue}
                      valueGot={item.uuid}
                      helperText={''}
                    />
                  }

                  <InputField
                    errors={!!errors?.venuesData?.[venuesNestedIndex]?.days?.[nestIndex]?.schedules?.[index]?.persons}
                    fieldName={`venuesData[${venuesNestedIndex}].days[${nestIndex}].schedules.${index}.venue_id`}
                    type="hidden"
                    autoComplete='false'
                    control={control}
                    iProps={{
                      // disabled: !(!!dateDisabled?.[index]),
                    }}
                    setValue={setValue}
                    valueGot={getValues(`venues[${venuesNestedIndex}]`)?.uuid}
                    helperText={errors?.venuesData?.[venuesNestedIndex]?.days?.[nestIndex]?.schedules?.[index]?.persons && errors?.venuesData[venuesNestedIndex]?.days?.[nestIndex]?.schedules?.[index]?.persons?.message}
                  />

                  <TimePickerField
                    errors={!!errors?.venuesData?.[venuesNestedIndex]?.days?.[nestIndex]?.schedules?.[index]?.start_time}
                    fieldName={`venuesData[${venuesNestedIndex}].days[${nestIndex}].schedules.${index}.start_time`}
                    type="date"
                    autoComplete='off'
                    label="* Start Time"
                    control={control}
                    isLoading={true}
                    iProps={{
                      // disabled: !(!!dateDisabled?.[index]),
                      ampm: false
                    }}
                    setValue={setValue}
                    helperText={errors?.venuesData?.[venuesNestedIndex]?.days?.[nestIndex]?.schedules?.[index]?.start_time && errors?.venuesData[venuesNestedIndex]?.days?.[nestIndex]?.schedules?.[index]?.start_time?.message}

                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={3}>
                  <TimePickerField
                    errors={!!errors?.venuesData?.[venuesNestedIndex]?.days?.[nestIndex]?.schedules?.[index]?.end_time}
                    fieldName={`venuesData[${venuesNestedIndex}].days[${nestIndex}].schedules.${index}.end_time`}
                    type="text"
                    autoComplete='off'
                    label="* End Time"
                    control={control}
                    isLoading={true}
                    iProps={{
                      //  disabled: !(!!dateDisabled?.[index]),
                      ampm: false
                    }}
                    setValue={setValue}
                    helperText={errors?.venuesData?.[venuesNestedIndex]?.days?.[nestIndex]?.schedules?.[index]?.end_time && errors?.venuesData[venuesNestedIndex]?.days?.[nestIndex]?.schedules?.[index]?.end_time?.message}
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={3}>
                  <InputField
                    errors={!!errors?.venuesData?.[venuesNestedIndex]?.days?.[nestIndex]?.schedules?.[index]?.persons}
                    fieldName={`venuesData[${venuesNestedIndex}].days[${nestIndex}].schedules.${index}.persons`}
                    type="text"
                    autoComplete='false'
                    label="* Seats"
                    control={control}
                    valueGot={""}
                    iProps={{
                      // disabled: !(!!dateDisabled?.[index]),
                    }}
                    setValue={setValue}
                    helperText={errors?.venuesData?.[venuesNestedIndex]?.days?.[nestIndex]?.schedules?.[index]?.persons && errors?.venuesData[venuesNestedIndex]?.days?.[nestIndex]?.schedules?.[index]?.persons?.message}
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={2}>
                  <InputField
                    errors={!!errors?.venuesData?.[venuesNestedIndex]?.days?.[nestIndex]?.schedules?.[index]?.price}
                    fieldName={`venuesData[${venuesNestedIndex}].days[${nestIndex}].schedules.${index}.price`}
                    type="text"
                    autoComplete='false'
                    label="* Price"
                    control={control}
                    valueGot={""}
                    iProps={{
                      // disabled: !(!!dateDisabled?.[index]),
                    }}
                    setValue={setValue}
                    helperText={errors?.venuesData?.[venuesNestedIndex]?.days?.[nestIndex]?.schedules?.[index]?.price && errors?.venuesData[venuesNestedIndex]?.days?.[nestIndex]?.schedules?.[index]?.price?.message}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={1} className="d-flex align-item-center">
                  <SwitchField
                    errors={!!errors?.venuesData?.[venuesNestedIndex]?.days?.[nestIndex]?.schedules?.[index] ?.status}
                    control={control}
                    setValue={setValue}
                    checked={modifyEditData?.['venuesData']?.[venuesNestedIndex]?.days?.[nestIndex]?.schedules?.[index]?.status}
                    isLoading={true}
                    // defaultChecked={true}
                    // helperText={errors?.schedule_details?.[index]?.[day_name]?.status && errors?.schedule_details?.[index]?.[day_name]?.status?.message}
                    // iProps={{
                    //   onChange: handleChange
                    // }}
                    valueGot={item.status != undefined ? item.status : true}
                    fieldName={`venuesData[${venuesNestedIndex}].days[${nestIndex}].schedules.${index}.status`}
                  />
                </GridItem>
               
                <GridItem xs={4} sm={4} md={2}>
                  { !item.uuid ? <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    startIcon={<DeleteIcon />}
                    onClick={() => remove(index)}

                  >
                    Delete
                  </Button> : null}
                </GridItem>
              </GridContainer>
            )
          })}
        </GridItem>
      </GridContainer >


      <GridContainer spacing={3} justify="end" alignItems="center" className={"textAlignEnd"}>
        <GridItem xs={12} sm={12} md={12}>
          <Button
            variant="contained"
            color="primary"
            className={"custom-btn"}
            onClick={async () => {

              let errorField: any = errors;
              const result = await trigger(`venuesData[${venuesNestedIndex}].days[${nestIndex}].schedules`);
              if (result) {
                append({
                  status: true,
                  end_time: null,
                  start_time: null,
                  day_id: day?.uuid
                })
                let _id = getValues(`venuesData[${venuesNestedIndex}].days[${nestIndex}].schedules`)?.length;
                setDateDisabled({ ...dateDisabled, ...{ [_id]: false } });
                
              }

            }}
          >
            Add more

          </Button>
        </GridItem>
      </GridContainer>


    </Box >
  );
}

export default TimeScheduledArray;