/*eslint-disable*/
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink, useLocation } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks";
import RTLNavbarLinks from "components/Navbars/RTLNavbarLinks";
import sidebarStyle from "assets/jss/material-dashboard-react/components/sidebarStyle";
import { Collapse, ListItemIcon } from "@material-ui/core";
const styles: any = sidebarStyle;
const useStyles = makeStyles(styles);
import { sections } from 'json/sections';
import { checkPermission } from 'services/common';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
export default function Sidebar(props: any) {
  const classes = useStyles();
  const history = useHistory();
  const draw = true;
  const [sub, setSub] = useState([])
  const [selected, setSelected] = useState();
  const tempArr: any = {};
  let location = useLocation();
  const getPermission = useSelector((state: any) => state.userPermission.permissions)
  

  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName: any) {
    return location.pathname === routeName;
  }
  
  


  const isPermission = (url: any) => {
    var sectionobj = sections.filter((x: any) => x.pathName === url);
    var isSpecific = sectionobj.find((x: any) => x.specific);
    if (isSpecific) {
      var y: any = false;
      if (sectionobj != undefined) {
        y = sectionobj.map((section: any) => {
          return checkPermission(getPermission, section.sectionName, section.subsection, section.specific)
        })
        if (y.find((x: any) => x === true)) {
          return true
        } else {
          return false
        }
      } else {
        return y;
      }
    } else {
      return true
    }
  }
   


  const handleClick = (item: any) => {
    var subtemp: any = sub
    subtemp[item] = !subtemp[item];
    setSub(Object.assign({}, subtemp))
  }

  const { color, logo, image, logoText, routes } = props;
  const handler: any = (children: any, subclass: any) => {
    return children.filter((x: any) => !x.hideSideBar).map((subOption: any) => {

      var activePro: any = " ";
      var listItemClasses: any;
      var mainMenuPermission: any = [];


      listItemClasses = classNames({
        [" subMenuCollapsactive " + classes[color]]: activeRoute(subOption.layout + subOption.path),
      });

      const whiteFontClasses: any = classNames({
        [" " + classes.whiteFont]: activeRoute(subOption.layout + subOption.path),
      });


      subOption?.children?.length > 0 && subOption?.children?.map((a: any) => {
        mainMenuPermission.push(isPermission(a.layout + a.path))
      });
    

      if (!subOption.children) {

        return (
          <React.Fragment key={subOption.name}>

            {isPermission(subOption.layout + subOption.path) ?
              <ListItem className={classes.itemLink + listItemClasses} button selected={selected === subOption.key} key={subOption.key} onClick={() => history.push(subOption.layout + subOption.path)}>
                {typeof subOption.icon === "string" ? (
                  <Icon
                    className={classNames(classes.itemIcon, whiteFontClasses, {
                      [classes.itemIconRTL]: props.rtlActive,
                    })}
                  >
                    {subOption.icon}
                  </Icon>
                ) : (
                  <subOption.icon
                    className={classNames(classes.itemIcon, whiteFontClasses, {
                      [classes.itemIconRTL]: props.rtlActive,
                    })}
                  />
                )}
                <ListItemText primary={props.rtlActive ? subOption.rtlName : subOption.name}
                  className={classNames(classes.itemText, whiteFontClasses, {
                    [classes.itemTextRTL]: props.rtlActive,
                  })}
                  disableTypography={true} />
              </ListItem>
              :
              <></>
            }
          </React.Fragment>
        )
      }

      return (

        <React.Fragment key={subOption.name}>
          {tempArr[subOption.submenus] = false}
          {mainMenuPermission.filter(Boolean)?.[0] == true &&
            <>
              <ListItem
                className={classes.itemLink + listItemClasses + ' children-sub-menu'}
                button selected={sub[subOption.submenus]}
                onClick={() => handleClick(subOption.submenus)}
              >
                {typeof subOption.icon === "string" ? (
                  <Icon
                    className={classNames(classes.itemIcon, whiteFontClasses, {
                      [classes.itemIconRTL]: props.rtlActive,
                    })}
                  >
                    {subOption.icon}
                  </Icon>
                ) : (
                  <subOption.icon
                    className={classNames(classes.itemIcon, whiteFontClasses, {
                      [classes.itemIconRTL]: props.rtlActive,
                    })}
                  />
                )}
                <ListItemText primary={props.rtlActive ? subOption.rtlName : subOption.name }
                  className={classNames(classes.itemText, whiteFontClasses, {
                    [classes.itemTextRTL]: props.rtlActive,
                  })}
                  disableTypography={true} />
                <ArrowForwardIosIcon className={classNames(classes.menuArrow, { [classes.menuOpen]: sub[subOption.submenus] }, { [classes.listTextNone]: !draw })} />
              </ListItem>
              <Collapse in={sub[subOption.submenus]} timeout={1} unmountOnExit className={classes.subMenuCollaps + ' subMenuCollaps'}>
                <List component="div" disablePadding>
                  {getPermission != undefined && handler(subOption.children, true)}
                </List>
              </Collapse>
            </>
          }
        </React.Fragment>
      )
    });
  }

  var brand = (
    <div className={classes.logo}>
      <div

        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive,
        })}

      >
        <div className={classes.logoImage}>
        <img src={"https://assets.playa.ae/assets/facilities_images/08417ec6-7c9c-455f-a861-7d0fc2eb26df.png"} alt="logo" className={"logoImage"} />
        </div>
        {logoText}
      </div>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />}
            {getPermission != undefined && handler(routes, false)}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper + " admin-side-bar"}>{getPermission != undefined && handler(routes, false)}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
}