import React,{useState} from 'react'
import { DropzoneAreaBase } from "material-ui-dropzone";
import { makeStyles } from "@material-ui/core/styles";



const styles: any = {
    dropboxContainer: {
        width:'100%',
        minHeight:'100px'
    },
    textClass: {
        fontSize: '15px'
    }
  };
  
  const useStyles = makeStyles(styles);

export const FileUploadArea = (props:any)=>{
    const classes = useStyles();
    const{open,setOpen,handleSave }=props
    const[fileObjects,setFileObjects]=useState<any>([])

return (

   < DropzoneAreaBase 
   fileObjects={fileObjects}
   dropzoneClass={classes.dropboxContainer}
   showPreviews={false}
   filesLimit={props?.filesLimit ? props?.filesLimit : 1}
   showPreviewsInDropzone={false}
   dropzoneParagraphClass={classes.textClass}
   showAlerts={false}
   previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
   previewChipProps={{ classes: { root: classes.previewChip } }}
   previewText="Selected files"
   dropzoneText={ props.message ? props?.message: "Drag and drop a file here or Click. Note: Only jpeg, png will be allowed"  }
   maxFileSize={20000000}
   onAdd={handleSave}
   acceptedFiles={props?.video ? props?.video : ['image/jpeg', 'image/png', 'image/svg+xml']}
/>
 
)

}


