import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { makeStyles } from '@material-ui/core';
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { useForm } from 'react-hook-form';
import PageHeader from 'components/PageHeader/PageHeader';
import { spinLoaderShow } from 'redux/action/spinLoader'
import { useDispatch, useSelector } from "react-redux";
import HeaderLogo from "components/ThemeSetting/HeaderLogo.component";
import SingupImage from "components/ThemeSetting/SingupImage.component";
import ForgetImage from "components/ThemeSetting/ForgetImage.component";
import LoginImage from "components/ThemeSetting/LoginImage.component";
import { getThemeSetting, updateThemeSetting } from 'services/userservices'
import AboutUs from "components/ThemeSetting/aboutUs.component";
import ContactUs from "components/ThemeSetting/contactUs.component";
import Facility from "components/ThemeSetting/facility.component";
import Activity from "components/ThemeSetting/activity.component";
import Venue from "components/ThemeSetting/venue.component";
import Booking from "components/ThemeSetting/booking.component";
import Order from "components/ThemeSetting/cart.component";
import Dashboard from "components/ThemeSetting/dashboard.component";
import MyProfile from "components/ThemeSetting/myProfile.component";
import AboutusPage from "components/ThemeSetting/AboutUs_Page/aboutusPage.component";
import { verifyPermission } from 'helper/permission'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HomeAccordion from 'components/ThemeSetting/homeDektop.accordion.component'
import HomeMobile from 'components/ThemeSetting/homeMobile.component';
import Footer from 'components/ThemeSetting/footer.component';
import PrivacyPolicy from 'components/ThemeSetting/Privacy_Policy/privacyPolicy.component';
import TermsAndConditions from 'components/ThemeSetting/Terms_and_Conditions/termsAndConditions.component';
import PrivacyPolicyImg from 'components/ThemeSetting/privacyPolicyImg.component';
import TermsAndConditionsImg from 'components/ThemeSetting/termsAndConditionsImg.component'


const Accordion = withStyles({
  root: {
    borderRadius: '10px',
    boxShadow: '2px 3px 15px 10px rgba(0,0,0,2)',
    WebkitBoxShadow: ' 2px 3px 4px rgba(0,0,0,2)',
    marginBottom: 10,
    marginTop: 5,

    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',


    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#043cae',
    borderRadius: '5px',
    color: 'white',
    marginBottom: -1,
    marginTop: 5,
    minHeight: 56,
    '&$expanded': {
      minHeight: 20,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',

    },
    cardCategoryWhite: {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
    },


    button: {
      width: '100%'
    },
    imag: {
      width: "150px",
      height: "150px",
      objectfit: "contain"
    },
    media: {
      height: "100%",
      width: 300,
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),


  },
}))(MuiAccordionDetails);

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 180,
    width: 180,
  },
  drop: {
    maxHeight: 400,
    width: 400,
  },
  cont1: {
    display: `flex`,

  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210
  },
  textClass: {
    fontSize: 18
  },



});


//Home Function.... 
export default function Home() {
  let name = "Collapse";
  const [expanded, setExpanded] = useState<any>(false);
  const [storeData, setData] = useState<any>([]);
  const [updateData, setUpdateData] = useState<any>([]);
  const [disabled, setDisabled] = useState<any>(true)
  const getPermission = useSelector((state: any) => { return state.userPermission.permissions })
  const dispatch = useDispatch()

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  //Get data applicationsetting
  const getApiDAta = () => {

    getThemeSetting().then((res) => {
      setData(res?.data?.result[0])
      dispatch(spinLoaderShow(false))

    }).catch((err) => {
      dispatch(spinLoaderShow(false))
    })

  }

  useEffect(() => {
    dispatch(spinLoaderShow(true))
    getApiDAta()

    let ispermission = !(verifyPermission(
      getPermission,
      'Application Setting',
      'Theme Setting',
      'update'
    ))
  
    // setDisabled(ispermission)

  }, [])


  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setValue,
    getValues
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",

  });

  //Routes 
  const routes = [
    {
      'name': "Admin",
      'link': '/admin'
    },
    {
      'name': "Theme Setting ",
      'link': ''
    }
  ];

  const classes = useStyles();

  //create function get Child Data from components...

  const childData = (data: any) => {

    dispatch(spinLoaderShow(true))

    // get Child Data from components & update data 
 
    if (data) {
      updateThemeSetting(data).then((res) => {
        toast.success("Record updated successfully")
        dispatch(spinLoaderShow(true))
        setUpdateData(res?.data?.result)

        getApiDAta()
        dispatch(spinLoaderShow(false))

      }).catch((error) => {
        dispatch(spinLoaderShow(false))
       
      })
    }



  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <PageHeader
            title={`Theme Setting`}
            routes={routes}
          />
        </GridItem>

      </GridContainer>

      <SingupImage
        classes={classes}
        getData={childData}
        Data={storeData}
        permission={disabled}
      />

      {/* <LoginImage
        classes={classes}
        getData={childData}
        Data={storeData}
        permission={disabled}

      /> */}

      {/* <ForgetImage
        classes={classes}
        getData={childData}
        Data={storeData}
        permission={disabled}
      /> */}

      <HeaderLogo
        classes={classes}
        getData={childData}
        Data={storeData}
        permission={disabled}
      />
      <Footer
        classes={classes}
        getData={childData}
        Data={storeData}
        permission={disabled}
      />
      <Facility
        classes={classes}
        getData={childData}
        Data={storeData}
        permission={disabled}
      />
      <ContactUs
        classes={classes}
        getData={childData}
        Data={storeData}
        permission={disabled}
      />
      <AboutUs
        classes={classes}
        getData={childData}
        Data={storeData}
        permission={disabled}
      />




      {/* <Activity
        classes={classes}
        getData={childData}
        Data={storeData}
        permission={disabled}
      /> */}

      <PrivacyPolicyImg
        classes={classes}
        getData={childData}
        Data={storeData}
        permission={disabled}
      />

      <TermsAndConditionsImg
        classes={classes}
        getData={childData}
        Data={storeData}
        permission={disabled}
      />

      {/* <Venue
        classes={classes}
        getData={childData}
        Data={storeData}
        permission={disabled}
      /> */}
      <Booking
        classes={classes}
        getData={childData}
        Data={storeData}
        permission={disabled}
      />
      <Order
        classes={classes}
        getData={childData}
        Data={storeData}
        permission={disabled}
      />
      <Dashboard
        classes={classes}
        getData={childData}
        Data={storeData}
        permission={disabled}
      />
      <MyProfile
        classes={classes}
        getData={childData}
        Data={storeData}
        permission={disabled}
      />
      <HomeAccordion
        classes={classes}
        getData={childData}
        Data={storeData}
        permission={disabled}

      />
      <HomeMobile
        classes={classes}
        getData={childData}
        Data={storeData}
        permission={disabled}

      />
      <AboutusPage
        classes={classes}
        getData={childData}
        Data={storeData}
        permission={disabled}

      />

      <PrivacyPolicy
        classes={classes}
        getData={childData}
        Data={storeData}
        permission={disabled}
      />

      <TermsAndConditions
        classes={classes}
        getData={childData}
        Data={storeData}
        permission={disabled}
      />

    </div>
  );
}




