import React, { useEffect, useState } from "react";
import forgotPasswordStyles from "./ForgotPasswordStyle";
// import logo from "../assets/images/picpax.svg";
import Button from "components/CustomButtons/Button";

import {
  CssBaseline,
  Grid,
  Typography,
  Container,
  Box,
  Backdrop,
  CircularProgress,
  TextField,
} from "@material-ui/core";

import logo from "assets/img/blackimage.png";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { forgotPasswordValidation, forgotPasswordSubmitValidation } from "./ForgotPasswordValidator";
import { InputField } from "components/Forms/Formfield.component";
import { Auth } from "aws-amplify";
import { congitoConfig, isvalid, getRoleId, signOut } from "services/cognitouser";
import { useHistory, useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { regenerateCode, verifyCodeValidity } from "services/userservices";
import { userPermissions } from "redux/action/permission";
import { useDispatch } from "react-redux";
import { InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { spinLoaderShow } from "redux/action/spinLoader";
import { result } from "lodash";
import zxcvbn from "zxcvbn";
import { Router } from "@material-ui/icons";



export default function forgotPasswordLayout(props: any) {
  const dispatch = useDispatch();

  const {

    visibleAlert,
    handleClose,
    alert,
    splash,
    heading,
    children,
  } = props;

  const createPasswordLabel = (result: any) => {
    switch (result?.score) {
      case 0:
        return "";
      case 1:
        return "Weak";
      case 2:
        return "Fair";
      case 3:
        return "Good";
      case 4:
        return "Excellent";
      default:
        return "Weak";
    }
  };

  const classes = forgotPasswordStyles();
  const [loading, setLoading] = useState(false);

  const [isOtpSent, setOtpSent] = useState(false);
  const [emailSent, setEmailSent] = useState("");

  const [isSuccess,setisSuccess] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState<any>("");
  const [showPassword, setShowPassword] = useState(false);

  const [code, setCode] = useState('');
  const [otp, setOtp] = useState("");

  const [codeDisabled, setcodeDisabled] = useState<any>(true);
  const [editData, setEditData] = useState<any>(null)

  const [validationScheme, setValidationScheme] = useState<any>(forgotPasswordValidation)
  

  

  
  const { search } = useLocation()
  let uuid = new URLSearchParams(search).get("uuid");
  let temp:any = new URLSearchParams(search ).get("code");

  const result = zxcvbn(passwordStrength);
  
  // useEffect(() => {
  //   if (editData?.cognito_id == 'Created') {
  //     setcodeDisabled(false)
  //   }
  //   else {
  //     setcodeDisabled(true)
  //   }
  // }, [editData])


  useEffect(() => {
    // setLoading(true);
    Auth.configure(congitoConfig);

    setCode(temp)
    return () =>{
      isSuccess ?  toast.success("Code verified") :  toast.error("Invalid Attempt") ;
    }

  }, []);



  var resolver = yupResolver(validationScheme);


  const {
    control,
    reset,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver
  });

  let history = useHistory();

  const handleChange = (otp: any) => {
    if (String(otp).length == 6) {
      props.handleOtp(otp)
    }
    setOtp(otp)
  }

  const regenerateVerificationCode = () => {
    if (editData.cognito_id == "Created") {
      // dispatch(spinLoaderShow(true));
      regenerateCode({ uuid: uuid }).then((e: any) => {
        dispatch(spinLoaderShow(false));
        setOtpSent(true)
        toast.success("Code sent on email.");
      }).catch((err: any) => {
    
      })
    }
    else {
      setcodeDisabled(true)
    }

  }

  const handlePasswordChange = (e: any) => {
    setPasswordStrength(e.target.value);
    setValue("password", e.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };




  const onSubmit = (data: any) => {
   
    // setLoading(true);
    if(!data.code){
      Auth.forgotPassword(data.email).then(
        async (res: any) => {
      
          // Change kro validation
          setEmailSent(data.email)
          setValidationScheme(forgotPasswordSubmitValidation)
          setOtpSent(true)
          
        }
      ).catch(err => {
     
        toast.error("Invalid Attempt " + String(err.message).toLocaleUpperCase());
        setLoading(false);
  
      });
    } else{
      Auth.forgotPasswordSubmit(emailSent, data.code, data.password).then(
        async (res: any) => {
          setLoading(true);
          history.push("/admin/dashboard")       
        }
      ).catch(err => {
      
        toast.error("Invalid Attempt " + String(err.message).toLocaleUpperCase());
        setLoading(false);
  
      });
    }
  

  };

 



  return (

    <div className={classes.mainContain}>
      <ToastContainer />
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress color="primary" />
      </Backdrop>
      <CssBaseline />
      <Grid container alignItems="stretch">
        <Grid item md={6}>
          <Box component="div" className={classes.introSide}>
            <Box pt={6} pb={4}>
              <Typography color="primary" component="h2" variant="h4">
                Forgot Password!
              </Typography>
            </Box>
            <Box component="div">
              <img
                className={classes.splashImg}
                src={
                  "https://assets.playa.ae/assets/other_images/a29098a5-4c12-44ba-86d0-ad50c42f77e9.jpg"
                }
                alt="Splash"
              />
            </Box>
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box component="div" className={classes.loginSide}>
            <Container maxWidth="sm">
              <div className={classes.paper}>
                <div></div>
                <Typography
                  className={classes.loginhead}
                  color="secondary"
                  component="h4"
                  variant="h4"
                >
                  {heading}
                </Typography>
                {/* {children} */}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid
                    container
                    item
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={4}
                  >
                    <Grid
                      className={"loginlogo"}
                      container
                      item
                      xs={12}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <img src={"https://assets.playa.ae/assets/facilities_images/41ecdfae-1298-45f2-b663-336eb19d7fb3.png"} width="50%" alt="SBP"></img>
                    </Grid>

                   {!isOtpSent && <Grid container item xs={12} >
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            error={errors?.email}
                            variant="outlined"
                            fullWidth
                            label="Email"
                            type="text" 
                            helperText={errors?.email && errors?.email?.message}
                          />
                        )}
                      />
                    </Grid>}

                    {isOtpSent && <Grid container item xs={12}>
                    <InputField
                      errors={!!errors?.code}
                      fieldName="code"
                      type="text"
                      autoComplete="off"
                      label="* Code"
                      value={otp}
                      onChange={handleChange}
                      control={control}
                      disabled={false}
                      valueGot={code}
                      setValue={setValue}
                      helperText={
                        errors?.code && errors?.code?.message
                      }
                    />
                    </Grid>}

                    {isOtpSent && <Grid container item xs={12}>
                    <InputField
                    errors={!!errors?.password}
                    fieldName="password"
                    type={showPassword? 'text':'password'}
                    autoComplete="false"
                    label="* Password"
                    control={control}
                    isLoading={true}
                    iProps={{
                        onChange: handlePasswordChange,
                        InputProps:{endAdornment:(<InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                         
                        </IconButton>
                      </InputAdornment>)}
                      }}
                    setValue={setValue}
                    helperText={
                      errors?.password && errors?.password?.message
                    }
                  />
                          <div className="password-strength-meter">
                    <progress
                      className={`password-strength-meter-progress strength-${createPasswordLabel(
                        result
                      )}`}
                      value={result?.score}
                      max="4"
                    />
                    <br />
                    <label className="password-strength-meter-label">
                      {passwordStrength && <>{createPasswordLabel(result)}</>}
                    </label>
                  </div>
                    </Grid>}            
                    
                    <Grid container item xs={12}>
                    <Button fullWidth color="primary" type="submit"
                      onClick={() => regenerateVerificationCode}
                     >
                       Reset</Button>

                    </Grid>
                  </Grid>
                </form>
              </div>
            </Container>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
