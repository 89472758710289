import React, { useEffect, useState } from "react";
import secretStyle from './SecretStyle'
// import logo from "../assets/images/picpax.svg";
import Button from "components/CustomButtons/Button";

import {
  CssBaseline,
  Grid,
  Typography,
  Container,
  Box,
  Backdrop,
  CircularProgress,
  TextField,
} from "@material-ui/core";

import logo from "assets/img/blackimage.png";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import {secretPageValidation} from './Validations/SecretValidator'
import { InputField } from "components/Forms/Formfield.component";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import zxcvbn from "zxcvbn";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import { useParams,useLocation } from "react-router-dom";
import queryString from 'querystring'
import { verifyCode,verifyCodeValidity } from "services/userservices";
import { useDispatch } from "react-redux";
import { spinLoaderShow } from 'redux/action/spinLoader'
import { Auth } from "aws-amplify";
import { congitoConfig, isvalid } from "services/cognitouser";



export default function SecretLayout(props: any) {
  const {
 
    visibleAlert,
    handleClose,
    alert,
    splash,
    heading,
    children,
  } = props;
  const createPasswordLabel = (result: any) => {
    switch (result?.score) {
      case 0:
        return "";
      case 1:
        return "Weak";
      case 2:
        return "Fair";
      case 3:
        return "Good";
      case 4:
        return "Excellent";
      default:
        return "Weak";
    }
  };

  const classes = secretStyle();
  const [loading,setLoading] = useState(false);
  const [isSuccess,setisSuccess] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState<any>("");
  const [confirmPasswordStrength, setConfirmPasswordStrength] = useState<any>("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  
  const [code, setCode] = useState('');


  const { search } = useLocation()
  let temp:any = new URLSearchParams(search ).get("code");

  const result = zxcvbn(passwordStrength);
  const confirmResult = zxcvbn(confirmPasswordStrength);

  const {
    control,
    reset,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(secretPageValidation),
  });

  let history = useHistory();
  const dispatch= useDispatch()

  useEffect(()=>{
    Auth.configure(congitoConfig);
    setLoading(true)
    
    if (isvalid()) {
      history.push("/admin/dashboard")
    }

    if(!temp){
      history.push("/")
    }

    verifyCodeValidity({code:temp}).then((e:any)=>{
      setLoading(false)
      setisSuccess(true)
      toast.success("Code is Valid");
 
     }).catch((err)=>{
      setisSuccess(false)
       setLoading(false)
       history.push("/")
     })


    setCode(temp)
    return () =>{
      isSuccess ?  toast.success("Code verified") :  toast.error("Invalid Attempt") ;
    }
  },[])

  
  const handlePasswordChange = (e: any) => {
    setPasswordStrength(e.target.value);
    setValue("password", e.target.value);
  };
  const handleConfirmChange = (e: any) => {
    setConfirmPasswordStrength(e.target.value);
    setValue("confirmPassword", e.target.value);
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowPasswordConfirm = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };

  const onSubmit = (data: any) => {
   
    setLoading(true);
    verifyCode(data).then((res)=>{
      setLoading(false);     
      toast.success("Code verified");
      setisSuccess(true)
      history.push("/")
    }).catch((err)=>{
      setisSuccess(false)
      setLoading(false);   
    })


    //  if (isvalid()) {
    //    history.push("/admin/dashboard")
    // }
    
  };



  return (

    <div className={classes.mainContain}>
      <ToastContainer />
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress color="primary" />
      </Backdrop>
      <CssBaseline />
      <Grid container alignItems="stretch">
        <Grid item md={6}>
          <Box component="div" className={classes.introSide}>
           
            <Box component="div">
              <img
                className={classes.splashImg}
                src={
                  "https://assets.playa.ae/assets/other_images/a29098a5-4c12-44ba-86d0-ad50c42f77e9.jpg"
                }
                alt="Splash"
              />
            </Box>
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box component="div" className={classes.secretSide}>
            <Container maxWidth="sm">
              <div className={classes.paper}>
                <div></div>
                <Typography
                  className={classes.loginhead}
                  color="secondary"
                  component="h4"
                  variant="h4"
                >
                  {heading}
                </Typography>
                {/* {children} */}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                   
                  >
                    <Grid
                      container
                      item
                      xs={12}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <img src={"https://assets.playa.ae/assets/facilities_images/41ecdfae-1298-45f2-b663-336eb19d7fb3.png"}  width="50%" alt="SBP"></img>
                    </Grid>
                    <Typography
                  // className={classes.loginhead}
                  color="secondary"
                
                  variant="h6"
                >
                 Code sent on email 
                </Typography>
                    

                    <Grid container item xs={12}>
                    {/* <Controller
                        name="code"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            error={errors?.code}
                            variant="outlined"
                            fullWidth
                            label="Code"
                            type="text"
                            value={code}
                            helperText={errors?.code && errors?.code?.message}
                          />
                        )}
                      /> */}
                    <InputField
                      errors={!!errors?.code}
                      fieldName="code"
                      type="text"
                      autoComplete="off"
                      label="* Code"
                      control={control}
                      disabled={true}
                      valueGot={code}
                      setValue={setValue}
                      helperText={
                        errors?.code && errors?.code?.message
                      }
                    />
                    </Grid>
                    <Grid container item xs={12}>
                    {/* <Controller
                        name="password"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            error={errors?.password}
                            variant="outlined"
                            fullWidth
                            label="Password"
                            type="password"
                            inputProps={{
                              onChange: handlePasswordChange,
                              InputProps:{endAdornment:(<InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                               
                              </IconButton>
                            </InputAdornment>)}

                            }}
                            helperText={
                              errors?.password && errors?.password?.message
                            }
                          />
                        )}
                      /> */}

                    <InputField
                    errors={!!errors?.password}
                    fieldName="password"
                    type={showPassword? 'text':'password'}
                    autoComplete="false"
                    label="* Password"
                    control={control}
                    isLoading={true}
                    iProps={{
                        onChange: handlePasswordChange,
                        InputProps:{endAdornment:(<InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                         
                        </IconButton>
                      </InputAdornment>)}
                      }}
                    setValue={setValue}
                    helperText={
                      errors?.password && errors?.password?.message
                    }
                  />
                          <div className="password-strength-meter">
                    <progress
                      className={`password-strength-meter-progress strength-${createPasswordLabel(
                        result
                      )}`}
                      value={result?.score}
                      max="4"
                    />
                    <br />
                    <label className="password-strength-meter-label">
                      {passwordStrength && <>{createPasswordLabel(result)}</>}
                    </label>
                  </div>
                    </Grid>
                    <Grid container item xs={12}>
                    <InputField
                    errors={!!errors?.confirmPassword}
                    fieldName="confirmPassword"
                    type={showPasswordConfirm? 'text':'password'}
                    autoComplete="false"
                    label="* Confirm Password"
                    control={control}
                    isLoading={true}
                    iProps={{
                        onChange: handleConfirmChange,
                        InputProps:{endAdornment:(<InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPasswordConfirm}
                        >
                          {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                         
                        </IconButton>
                      </InputAdornment>)}
                      }}
                    setValue={setValue}
                    helperText={
                      errors?.confirmPassword && errors?.confirmPassword?.message
                    }
                  />
                   <div className="password-strength-meter">
                    <progress
                      className={`password-strength-meter-progress strength-${createPasswordLabel(
                        confirmResult
                      )}`}
                      value={confirmResult?.score}
                      max="4"
                    />
                    <br />
                    <label className="password-strength-meter-label">
                      {confirmPasswordStrength && <>{createPasswordLabel(confirmResult)}</>}
                    </label>
                  </div>
                    </Grid>
                    <Grid container item xs={12}>
                      <Button fullWidth color="primary" type="submit">
                        Verify
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </Container>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
