import * as yup from 'yup'

const passwordValidation = yup.object().shape({
    current_password:yup.string().required("Please enter current password").nullable(),
    new_password:yup.string().required("Please enter new password").matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
    confirm_password: yup.string().oneOf([yup.ref('new_password'), null], 'Passwords must match'),
})

export{
    passwordValidation
}