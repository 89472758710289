import React, { useEffect } from 'react'
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { InputField, TextEditorField } from "../Forms/Formfield.component";
import GridItem from "../Grid/GridItem";
import { AccordionActions, Box, Button, CardMedia, Container } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import 'react-toastify/dist/ReactToastify.css';
import { socialValidations } from "./SettingValidation/SocialsValidation"
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from 'react-hook-form';

// const classes = useStyles();
const Accordion = withStyles({
  root: {
    // border: '1px solid rgba(0, 0, 0, .125)',
    borderRadius: '10px',
    boxShadow: '2px 3px 15px 10px rgba(0,0,0,2)',
    WebkitBoxShadow: ' 2px 3px 4px rgba(0,0,0,2)',
    marginBottom: 10,
    marginTop: 5,

    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',


    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#043cae',
    borderRadius: '5px',
    color: 'white',

    // borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    marginTop: 5,
    // boxshadow: '0px 0px 15px -10px',
    minHeight: 56,
    '&$expanded': {
      minHeight: 20,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',

    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),


  },
}))(MuiAccordionDetails);
	
const ExpandIcon = {
  color: "#ffffff"
};


const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 180,
    width: 180,
  },
  drop: {
    maxHeight: 400,
    width: 400,
  },
  cont1: {
    display: `flex`,

  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210
  },
  textClass: {
    fontSize: 18
  },



});



const Socials = (props: any) => {

  const { getData, Data, permission } = props;


  const defaultFormValues: any = {
    facebook: '',

  }

  const { control, register, handleSubmit, formState: { errors }, setValue, reset, getValues, trigger } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultFormValues,
    resolver: yupResolver(socialValidations)
  });

  useEffect(() => {

    if (Data) {

      var temp = Data?.socials?.length > 0 && Data?.socials?.map((value: any, index: any, arr: any) => {
        setValue(Object.keys(value)[0], Object.values(value)[0]);
      })

      setValue("facebook", String(Data?.socials?.find((x: any) => x.name == 'Facebook').value))
      setValue("instagram", String(Data?.socials?.find((x: any) => x.name == 'Instagram').value))
      setValue("twitter", String(Data?.socials?.find((x: any) => x.name == 'Twitter').value))
      setValue("youtube", String(Data?.socials?.find((x: any) => x.name == 'Youtube').value))
    }
  }, [Data]);




  const onSubmit = (data: any) => {

    let modify_data = [
      {
        name: "Facebook",
        value: String(data?.facebook)
      },
      {
        name: "Twitter",
        value: String(data?.twitter)
      },
      {
        name: "Instagram",
        value: String(data?.instagram)
      },
      {
        name: "Youtube",
        value: String(data?.youtube)
      }
    ]
    data.socials = modify_data
    delete data.twitter
    delete data.facebook
    delete data.youtube
    delete data.instagram

    getData(data)


  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Accordion >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={ExpandIcon} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Social Media Link</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <GridItem xs={12} sm={12} md={12} className={"mt-15"}>
              <InputField
                errors={!!errors?.facebook}
                fieldName="facebook"
                type="text"
                autoComplete="off"
                label="Facebook"
                control={control}
                valueGot={String(Data.socials)}
                setValue={setValue}
                helperText={
                  errors?.facebook && errors?.facebook?.message
                }
              />
              <InputField
                errors={!!errors?.twitter}
                fieldName="twitter"
                type="text"
                autoComplete="off"
                label="Twitter"
                control={control}
                valueGot={Data.socials}
                setValue={setValue}
                helperText={
                  errors?.twitter && errors?.twitter?.message
                }
              />
              <InputField
                errors={!!errors?.instagram}
                fieldName="instagram"
                type="text"
                autoComplete="off"
                label="Instagram"
                control={control}
                valueGot={Data.socials}
                setValue={setValue}
                helperText={
                  errors?.instagram && errors?.instagram?.message
                }
              />
              <InputField
                errors={!!errors?.youtube}
                fieldName="youtube"
                type="text"
                autoComplete="off"
                label="Youtube"
                control={control}
                valueGot={Data.socials}
                setValue={setValue}
                helperText={
                  errors?.youtube && errors?.youtube?.message
                }
              />

            </GridItem>

          </AccordionDetails>

          <AccordionActions>
            <GridItem
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Box display="flex-end" style={{ marginBottom: '10px' }}>
                <Button type="submit" variant="contained" color="primary">Submit</Button>
              </Box>
            </GridItem>
          </AccordionActions>

        </Accordion>
      </form>



    </div>
  )
}

export default Socials
