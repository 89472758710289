import * as yup from 'yup'


const startTimeRules: any = yup.date().when("status", {
  is: true,
  then: yup.date().required('Please select start time.').nullable()
}).when("status", {
  is: false,
  then: yup.date().nullable()
}).when("end_time", {
  is: !null,
  then: yup.date().required('Please select start time.').max(yup.ref('end_time'), 'Start time should be greater than end time').nullable()
});

const endTimeRules: any = yup.date().when("status", {
  is: true,
  then: yup.date().required('Please select end time.').min(yup.ref('start_time'), 'End time should be later than start time').nullable()
}).when("status", {
  is: false,
  then: yup.date().nullable()
});

const capacity: any = yup.string().when("status", {
  is: true,
  then: yup.string().required("Please enter capacity").nullable()
}).when("status", {
  is: false,
  then: yup.string().nullable()
});


const price: any = yup.string().when("status", {
  is: true,
  then: yup.string().required("Please enter price").nullable()
}).when("status", {
  is: false,
  then: yup.string().nullable()
});



const HolidayValidations: any = yup.object().shape({
  description:yup.string().required("field is empty").nullable(),
  days: yup.array().of(yup.object().shape({
    schedules: yup.array().of(
      yup.object().shape({
        status: yup.boolean().default(true),
        start_time: startTimeRules,
        end_time: endTimeRules,
        capacity: capacity,
        price: price,
        
      })
    ),
  }))
})


// yup.object().shape({
//   status: yup.boolean().default(true),
//   start_time: startTimeRules,
//   end_time: endTimeRules
// })

export {
  HolidayValidations
}