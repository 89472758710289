import React, { useEffect, useState } from 'react'
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import GridItem from "../../Grid/GridItem";
import { AccordionActions, Box, Button, CardMedia, Container } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDispatch } from "react-redux";
import { spinLoaderShow } from 'redux/action/spinLoader'
import { InputField } from 'components/Forms/Formfield.component';
import { useForm, useFieldArray } from "react-hook-form";
import GridContainer from 'components/Grid/GridContainer';
import { otherImg, videoUpload } from "services/userservices";
import { FileUploadArea } from 'components/fileupload/fileuploadArea.component';
import { aboutValidations } from "./Validation/aboutValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import { ImageField } from "./fieldArr-image"




const Accordion = withStyles({
    root: {
        borderRadius: '10px',
        boxShadow: '2px 3px 15px 10px rgba(0,0,0,2)',
        WebkitBoxShadow: ' 2px 3px 4px rgba(0,0,0,2)',
        marginBottom: 10,
        marginTop: 5,

        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',


        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#043cae',
        borderRadius: '5px',
        color: 'white',

        marginBottom: -1,
        marginTop: 5,
        minHeight: 56,
        '&$expanded': {
            minHeight: 20,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',

        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(1),


    },
}))(MuiAccordionDetails);

const ExpandIcon = {
    color: "#ffffff"
};

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
    },

    media: {
        height: 200,
        width: 259,
        marginTop: 15
    },
    drop: {
        maxHeight: 100,
        width: 100,
    },
    cont1: {
        display: `flex`,

    },
    previewChip: {
        minWidth: 160,
        maxWidth: 210
    },
    textClass: {
        fontSize: 18
    },



});
const imagesss = {
    height: 200,
    width: 259,
    marginTop: 15
};



const AboutusPage = (props: any) => {
    const dispatch = useDispatch()
    const [bannerImg, setBannerImg] = useState<any>("");
    const [corporateVideo, setCorporateVide] = useState<any>("")



    // hookForm============================>

    const { classes, getData, Data, permission } = props;

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setError,
        setValue,
        getValues,
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(aboutValidations),


    });

    //   field array ================================>

    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control,
        name: "testimonial",
    });

    useEffect(() => {
        if (Data) {
         
            setValue("who_we_are", String(Data?.aboutusPage?.who_we_are))
            setValue("our_mission", String(Data?.aboutusPage?.our_mission))
            setValue("our_vision", String(Data?.aboutusPage?.our_vision))
            setValue("testimonial", Data?.aboutusPage?.testimonial)
            setValue("book_person", String(Data?.aboutusPage?.book_person))
            setValue("corporate_video", String(Data?.aboutusPage?.corporate_video))
            setCorporateVide(Data?.aboutusPage?.corporate_video)
        }
    }, [Data])

    const handleSaveCorporateVideo = async (files: any) => {

     

        if (files.length > 0) {
            dispatch(spinLoaderShow(true));
            const formData = new FormData();
            formData.append("images", files[0].file);
            const { data } = await videoUpload(formData);
           
            setCorporateVide(data.result[0]);
            dispatch(spinLoaderShow(false));
        }
    };

    const onSubmit = (data: any) => {
        data.corporate_video = corporateVideo
  

        let modifyData = {
            "aboutusPage": data
        }
        if (data) {
            getData(modifyData)
        }



    }



    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Accordion  >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={ExpandIcon} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>About Us Page</Typography>

                    </AccordionSummary>
                    <AccordionDetails>

                        <GridItem xs={12} sm={12} md={12} className={"mt-15"}>
                            <GridContainer className={classes.cont1}>

                                {/* who_we_are======================> */}

                                <GridItem xs={12} sm={12} md={12}>
                                    <Typography> Who We Are: </Typography>
                                    <InputField
                                        className={"mt-15"}
                                        errors={!!errors?.who_we_are}
                                        multiline={true}
                                        rows={5}
                                        fieldName="who_we_are"
                                        type="text"
                                        autoComplete="false"
                                        label="Who We Are"
                                        control={control}
                                        isLoading={true}
                                        valueGot={''}
                                        setValue={setValue}
                                        helperText={
                                            errors?.who_we_are && errors?.who_we_are?.message
                                        }
                                    />

                                </GridItem>
                                {/* our_mission ======================> */}
                                <GridItem xs={12} sm={12} md={12}>
                                    <Typography> Our Mission: </Typography>
                                    <InputField
                                        className={"mt-15"}
                                        errors={!!errors?.our_mission}
                                        multiline={true}
                                        rows={5}
                                        fieldName="our_mission"
                                        type="text"
                                        autoComplete="false"
                                        label="Our Mission"
                                        control={control}
                                        isLoading={true}
                                        valueGot={''}
                                        setValue={setValue}
                                        helperText={
                                            errors?.our_mission && errors?.our_mission?.message
                                        }
                                    />
                                </GridItem>

                                {/* our_vision ======================> */}

                                <GridItem xs={12} sm={12} md={12}>
                                    <Typography> Our Vision: </Typography>
                                    <InputField
                                        className={"mt-15"}
                                        errors={!!errors?.our_vision}
                                        multiline={true}
                                        rows={5}
                                        fieldName="our_vision"
                                        type="text"
                                        autoComplete="false"
                                        label="Our Vision"
                                        control={control}
                                        isLoading={true}
                                        valueGot={''}
                                        setValue={setValue}
                                        helperText={
                                            errors?.our_vision && errors?.our_vision?.message
                                        }
                                    />
                                </GridItem>

                                {/* Testimonial ======================> */}
                                {/* fieldArr ======================> */}

                                <GridItem xs={12} sm={12} md={12} className={"mt-15"}>
                                    <Typography>Testimonial </Typography>
                                    <hr />
                                    {fields?.map((item: any, index: any) => {
                                        return (
                                            <GridContainer  >

                                                {/* image ============================> */}
                                                <ImageField key={`testimonial[${index}.image]`} className={classes} index={index} setValue={setValue} getValues={getValues} control={control} />

                                                <GridItem xs={3} sm={3} md={3}>
                                                    <InputField
                                                        errors={!!errors?.testimonial?.[index]?.name}
                                                        fieldName={`testimonial[${index}.name]`}
                                                        type="text"
                                                        autoComplete="off"
                                                        label="* Name"
                                                        control={control}
                                                        valueGot={""}
                                                        setValue={setValue}
                                                        helperText={errors?.testimonial?.[index]?.name && errors?.testimonial?.[index]?.name?.message}
                                                    />

                                                </GridItem>

                                                <GridItem xs={3} sm={3} md={3}>
                                                    <InputField
                                                        errors={!!errors?.testimonial?.[index]?.designation}
                                                        fieldName={`testimonial[${index}.designation]`}
                                                        type="text"
                                                        autoComplete="off"
                                                        label="* Designation"
                                                        control={control}
                                                        valueGot={""}
                                                        setValue={setValue}
                                                        helperText={errors?.testimonial?.[index]?.designation && errors?.testimonial?.[index]?.designation?.message}
                                                    />

                                                </GridItem>

                                                <GridItem xs={3} sm={3} md={3}>
                                                    <InputField
                                                        errors={!!errors?.testimonial?.[index]?.description}
                                                        fieldName={`testimonial[${index}.description]`}
                                                        type="text"
                                                        autoComplete="off"
                                                        label="* Description"
                                                        control={control}
                                                        valueGot={""}
                                                        setValue={setValue}
                                                        helperText={errors?.testimonial?.[index]?.description && errors?.testimonial?.[index]?.description?.message}
                                                    />

                                                </GridItem>

                                                <GridItem xs={3} sm={3} md={3} className={"mt-15"}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        className={"custom-btn"}
                                                        onClick={() => {
                                                            remove(index);
                                                        }}
                                                    >
                                                        Remove
                                                    </Button>
                                                </GridItem>




                                            </GridContainer>
                                        )
                                    })}



                                    <GridContainer
                                        justify="end"
                                        alignItems="center"
                                        className={"girdarrayaccordian"}
                                    >
                                        {/* Add more Button ============================> */}
                                        <GridItem xs={12} sm={12} md={12}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={"custom-btn"}
                                                onClick={() => {
                                                    append({ image: "", description: "", name: "", designation: "" });
                                                }}
                                            >
                                                Add more
                                            </Button>
                                        </GridItem>


                                    </GridContainer>

                                </GridItem>

                                <GridItem xs={12} sm={12} md={12} >
                                    <Typography>Corporate Video </Typography>
                                    <hr />
                                    <GridContainer>

                                        <GridItem xs={3} sm={3} md={3} >
                                            <CardMedia
                                                component={corporateVideo ? "video" :"div"}
                                                image={corporateVideo? corporateVideo : "https://www.lifewire.com/thmb/P856-0hi4lmA2xinYWyaEpRIckw=/1920x1326/filters:no_upscale():max_bytes(150000):strip_icc()/cloud-upload-a30f385a928e44e199a62210d578375a.jpg"}
                                                title='title'
                                            />

                                        </GridItem>

                                        <GridItem xs={9} sm={9} md={9} className={"mt-15"}>
                                            <Box className={classes.drop}>
                                                <FileUploadArea
                                                    handleSave={handleSaveCorporateVideo}
                                                    video={['video/*']}
                                                    message={"Only mp4 is allowed.The maximum size is 20mb"}
                                                />
                                            </Box>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>

                                {/* Book Person Sport Now ============================> */}

                                <GridItem xs={12} sm={12} md={12} className={"mt-15"}>
                                    <Typography> Book Person Sport Now </Typography>
                                    <hr />
                                    <InputField
                                        className={"mt-15"}
                                        errors={!!errors?.book_person}
                                        multiline={true}
                                        rows={5}
                                        fieldName="book_person"
                                        type="text"
                                        autoComplete="false"
                                        label="Book Person Sport Now"
                                        control={control}
                                        isLoading={true}
                                        valueGot={''}
                                        setValue={setValue}
                                        helperText={
                                            errors?.book_person && errors?.book_person?.message
                                        }
                                    />

                                </GridItem>



                            </GridContainer>
                        </GridItem>
                    </AccordionDetails>

                    <AccordionActions>


                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            style={{ width: "100%" }}
                        >
                            <Button
                                color="primary"
                                variant="contained"
                                className={"custom-btn"}
                                type="submit"
                            >
                                submit
                            </Button>
                        </Box>



                    </AccordionActions>
                </Accordion>
            </form>


        </div>
    )
}

export default AboutusPage

