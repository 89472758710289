import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import PageHeader from "components/PageHeader/PageHeader";
import AgGridCustom from "components/AgGridCustom/AgGridCustom";
import { spinLoaderShow } from "redux/action/spinLoader";
import { useDispatch } from "react-redux";
import { getBooking, getBookingOdata,getBookingDetailView } from 'services/userservices'
import { Link } from "react-router-dom";
import { Visibility } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import moment from 'moment';



const styles: any = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);


// main function Booking data

export const BookingList = (props: any) => {

  //create States ................

  const classes = useStyles();
  const [gridApi, setGridApi] = useState<any>(null);
  const [, setGridColumnApi] = useState<any>(null);
  const [booking, setBooking] = useState<any>();
  const [disabled, setDisabled] = useState<any>(false)
  const [exportData, setExportData] = useState<any>(null)
  const dispatch = useDispatch()

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };


  // Get data Booking Apis=========================>

  useEffect(() => {
    dispatch(spinLoaderShow(true))
    getBookingDetailView().then((res) => {
      // let data = res.data.result[0]
      // let temp = Object.assign({}, data.booking_details[0])
      // data.booking_details[0] = temp
      let data = res.data.result.map((item:any) =>{
       item.TransactionDate =  moment(item.TransactionDate).tz("Asia/Dubai");
       return item;
      })
      setBooking(data)
      dispatch(spinLoaderShow(false))
    }).catch((err) => {
      dispatch(spinLoaderShow(false))
   
    })

  }, [])

  // Get data Booking Apis=========================>

  const routes = [
    {
      'name': "Admin",
      'link': '/admin'
    },
    {
      'name': "Booking List",
      'link': ''
    }
  ];
  function capitalize(s:String)
  {
      return s && s[0].toUpperCase() + s.slice(1);
  }
  // set fields Booking Data................ 

  const columnDefs: any = [

    
    {
      headerName: "Booking ID",
      field: "BookingId",

    },
    {
      headerName: "Sports/Classes",
      field: "FacilityName",
      valueFormatter : (params: any) => {
        return capitalize(params.value);
    }
    },
    {
      headerName: "Venue",
      field: "VenueName",
      valueFormatter : (params: any) => {
        return capitalize(params.value);
    }
    },
    {
      headerName: "Customer",
      field: "CustomerName",
      valueFormatter : (params: any) => {
        return capitalize(params.value);
    }
    },
    {
      headerName: "Customer Email",
      field: "CustomerEmail"
    },
    {
      headerName: "Customer Phone",
      field: "CustomerPhone"
    },
    {
      headerName: "Start Time",
      field: "StartTime"
    },
    {
      headerName: "End Time",
      field: "EndTime"
    },
    {
      headerName: "Day",
      field: "Day",
      valueFormatter : (params: any) => {
        return capitalize(params.value);
    }
    },
    {
      headerName: "Booking Date",
      field: "BookingDate"
    },
    {
      headerName: "Amount (AED)",
      field: "Price",
      
    },
    {
      headerName: "Tracking Id",
      field: "TrackingId",
      
    },
    {
      headerName: "Transaction Date",
      field: "TransactionDate",
      valueFormatter : (params: any) => {
        // console.log("SAdsa",params.data.TransactionDate)
        return moment(params.data.TransactionDate).format('YYYY-MM-DD HH:mm:ss');
    }
      
    },
    {
      headerName: "Booking Status",
      field: "BookingStatus",
      
    },
    {
      headerName: 'View',
      field: "action",
      sortable: false,
      filter: false,
      hide: disabled,
      cellRendererFramework: (params: any) => {
        // put the value in bold
    
        
        return <Link to={'booking/view?uuid=' + params?.data?.BookingId}><Visibility /></Link>;
      }
    },

  ]

  //start jsx...................

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GridItem xs={12} sm={12} md={12}>
          <PageHeader
            title="Booking"
            routes={routes}


          />
        </GridItem>
      </GridItem>
      <GridItem xs={12} sm={12} md={12} >
        <Card>
          <CardHeader color="primary" className="card-header-export-btn">
            <h4 className={classes.cardTitleWhite}>Booking List</h4>
            <Button variant="outlined" className="custom-btn export-btn" onClick={(e: any) => exportData.exportDataAsCsv()}>Export to Csv</Button>

          </CardHeader>
          <CardBody>
            <AgGridCustom
              columnDefs={columnDefs}
              rowData={booking}
              getRowNodeId={function (data: any) {
                return data.id;

              }}
              setExportData={setExportData}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}



