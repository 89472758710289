import React, { useEffect } from 'react'
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import { AccordionActions, Box, Button, CardMedia, Container } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import otherImg from 'services/userservices'
import { useDispatch } from "react-redux";
import { spinLoaderShow } from 'redux/action/spinLoader'
import { FileUploadArea } from "components/fileupload/fileuploadArea.component"
import { useState } from "react";



const Accordion = withStyles({
  root: {
    borderRadius: '10px',
    boxShadow: '2px 3px 15px 10px rgba(0,0,0,2)',
    WebkitBoxShadow: ' 2px 3px 4px rgba(0,0,0,2)',
    marginBottom: 10,
    marginTop: 5,

    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',


    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#043cae',
    borderRadius: '5px',
    color: 'white',

    marginBottom: -1,
    marginTop: 5,
    minHeight: 56,
    '&$expanded': {
      minHeight: 20,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',

    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),


  },
}))(MuiAccordionDetails);

const ExpandIcon = {
  color: "#ffffff"
};

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 180,
    width: 180,
  },
  drop: {
    maxHeight: 400,
    width: 400,
  },
  cont1: {
    display: `flex`,

  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210
  },
  textClass: {
    fontSize: 18
  },
});

const Facility = (props: any) => {
  const dispatch = useDispatch()

  const [pics, setPics] = useState<any>()
  const [uploadFiles, setuploadFiles] = useState<any>()
  const [disable, setDisable] = useState<any>(true)

  const { classes, getData, Data, permission } = props;

  useEffect(() => {
    if (Data) {
      setPics(Data?.facilities)
    }

  }, [Data])


  const facilityImg = async (files: any) => {
    if (files) {
      setDisable(false)
      setuploadFiles(files[0])
      setPics(files[0].data)
    }

  }

  const uploadImage = async () => {
    dispatch(spinLoaderShow(true));

    const formData = new FormData()

    formData.append('images', uploadFiles.file)
    const { data } = await otherImg(formData)


    if (data) {
      setPics(data?.result[0])
      let temp = {
        "facilities": data?.result[0]
      }
      getData(temp)
    }

    dispatch(spinLoaderShow(false));

  }
  return (
    <div>
      <form >
        <Accordion  >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={ExpandIcon} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Search</Typography>

          </AccordionSummary>
          <AccordionDetails>
            <GridItem xs={12} sm={12} md={12} className={"mt-15"}>
              <Container className={classes.cont1}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CardMedia
                      className={classes.media}
                      image={pics ? pics : "https://www.lifewire.com/thmb/P856-0hi4lmA2xinYWyaEpRIckw=/1920x1326/filters:no_upscale():max_bytes(150000):strip_icc()/cloud-upload-a30f385a928e44e199a62210d578375a.jpg"}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <Box className={classes.drop}>
                      <FileUploadArea

                        handleSave={facilityImg}
                        message={"Drag and drop a file here or Click. Note: Only jpeg, png will be allowed Optimize image size is 1920 x 500 pixels"}

                      />
                    </Box>
                  </GridItem>
                </GridContainer>
              </Container>
            </GridItem>
          </AccordionDetails>

          <AccordionActions>
            <GridItem
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Box display="flex-end" style={{ marginBottom: '10px' }}>
                {permission === true ? <Button disabled={disable} onClick={uploadImage} variant="contained" color="primary">Submit</Button> : ''}
              </Box>
            </GridItem>
          </AccordionActions>
        </Accordion>
      </form>


    </div>
  )
}

export default Facility
