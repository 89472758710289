export const sections: any = [
  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/users",
    sectionName: "Users",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/venue",
    sectionName: "Venue",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/venue/add",
    sectionName: "Venue",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/venue/edit",
    sectionName: "Venue",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/facilities",
    sectionName: "Facility",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/facilities/add",
    sectionName: "Facility",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/facilities/edit",
    sectionName: "Facility",
  },
  
  {
    specific: "update",
    permission: true,
    subsection: "Admin Users",
    pathName: "/admins/user/edit",
    sectionName: "Administrator",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Service",
    pathName: "/admin/service",
    sectionName: "Services",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Config",
    pathName: "/admin/service-config",
    sectionName: "Services",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Config",
    pathName: "/admin/service-config/edit",
    sectionName: "Services",
  },
 
  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/customer",
    sectionName: "Customer",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/customer/add",
    sectionName: "Customer",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/customer/edit",
    sectionName: "Customer",
  },
  
  {
    specific: "read",
    permission: true,
    subsection: "Push Notification",
    pathName: "/adminpush-notification",
    sectionName: "Notification",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Push Notification",
    pathName: "/admin/push-notification",
    sectionName: "Notification",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Push Notification",
    pathName: "/admin/push-notification",
    sectionName: "Notification",
  },
 

  {
    specific: "read",
    permission: true,
    subsection: "Customer Detail",
    sectionName: "Customer",
    pathName: "/customer",

  },
  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/promotion",
    sectionName: "Promotion",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/promotion/add",
    sectionName: "Promotion",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/promotion/edit",
    sectionName: "Promotion",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Maintenance",
    pathName: "/admin/site-maintenance",
    sectionName: "Setting",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Maintenance",
    pathName: "/admin/site-maintenance",
    sectionName: "Setting",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Maintenance",
    pathName: "/admin/site-maintenance",
    sectionName: "Setting",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Setting",
    pathName: "/admin/setting",
    sectionName: "Application Setting",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Setting",
    pathName: "/admin/setting",
    sectionName: "Application Setting",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Setting",
    pathName: "/admin/setting",
    sectionName: "Application Setting",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Theme Setting",
    pathName: "/admin/theme-setting",
    sectionName: "Application Setting",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Theme Setting",
    pathName: "/admin/theme-setting",
    sectionName: "Application Setting",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Theme Setting",
    pathName: "/admin/theme-setting",
    sectionName: "Application Setting",
  },
  
  
  {
    specific: "read",
    permission: true,
    subsection: "Email",
    pathName: "/admin/email-template",
    sectionName: "Content",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Email",
    pathName: "/admin/cemail-template/add",
    sectionName: "Content",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Email",
    pathName: "/admin/email-template/edit",
    sectionName: "Content",
  },
  {
    specific: "read",
    permission: true,
    sectionName: "Reports",
    pathName: "/admin/reports",
    subsection: "Manage",

  },
  {
    specific: "read",
    permission: true,
    subsection: "Customer Detail",
    sectionName: "Customer",
    pathName: "/customer",

  },
 
  {
    specific: "read",
    permission: true,
    subsection: "FAQ Types",
    pathName: "/admin/faq/type",
    sectionName: "FAQ",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/holiday",
    sectionName: "Holiday",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Vehicles",
    sectionName: "Vehicles",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    sectionName: "Booking",
    pathName: "/admin/booking",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    sectionName: "Booking",
    pathName: "/admin/booking/view",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    sectionName: "Contact Us",
    pathName: "/admin/contactus",
  },

];
