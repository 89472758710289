import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import PageHeader from "components/PageHeader/PageHeader";
import _ from "lodash";
import { useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { TextEditorField } from "components/Forms/Formfield.component";
import {
  AutoCompleteField,
  InputField,
  LoadingButton,
  PhoneNoField,
  SwitchField,
  SwitchFieldDefault,
} from "components/Forms/Formfield.component";
import Button from "components/CustomButtons/Button";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  CardMedia,
  Container,
  Divider,
  FormControlLabel,
  FormHelperText,
  Typography,
} from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { createVenueValidation } from "./Validations/venueValidations";
import { Box } from "@material-ui/core";
import GoogleMap from "components/GoogleMap/GoogleMap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { spinLoaderShow } from "redux/action/spinLoader";
import { useDispatch } from "react-redux";
import DrawMap from "components/GoogleMap/DrawMap";
import { getVenue, createVenue, updateVenue } from "services/userservices";
import { Navigation, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { venueFileUpload } from "services/userservices";
// import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

import {
  BrowserRouter as Router,
  useHistory,
  useLocation,
} from "react-router-dom";
import { FileUploadArea } from "components/fileupload/fileuploadArea.component";
import map from "material-ui/svg-icons/maps/map";

const styles: any = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  accord: {
    color: "white",
    backgroundColor: "#043cae",
    borderRadius: "5px",
    marginTop: "5px",
  },

  button: {
    width: "100%",
  },

  media: {
    height: "100%",
    width: 300,
  },
};

const defaultFormValues: any = {
  name: "",
  lat: "",
  contact_phone: "",
  contact_email: "",
  featured_amenities: [],
  status: true
};

const useStyles = makeStyles(styles);
declare var google: any;

export default function AddVenue(props: any) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [headerTitle, setHeaderTitle] = React.useState<string>("Add");
  const [editData, setEditData] = useState<any>(null);
  const [resetMap, setResetMap] = useState<any>(false);
  const [mapValues, setMapValues] = useState<any>(null);
  const [imageSlider, setImageSlider] = useState<any>([]);
  const [bannerImg, setBannerImg] = useState<any>("");
  const [open, setOpen] = useState(false);
  const [removeImage, setRemoveImage] = useState("");
  const [currentZoom, setCurrentZoom] = useState(12);
  const [gallaryImageLarge, setgallaryImageLarge] = useState<any>("");
  const [gallaryImageMedium, setgallaryImageMedium] = useState<any>("");
  const [gallaryImageSmall, setgallaryImageSmall] = useState<any>("");
  const [currentCenter, setcurrentCenter] = useState({
    lat: parseFloat(editData?.lat ? editData?.lat : "25.2048"),
    lng: parseFloat(editData?.long ? editData?.long : "55.2708"),
  });

  let history = useHistory();
  const { search } = useLocation();
  let uuid = new URLSearchParams(search).get("uuid");

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultFormValues,
    resolver: yupResolver(createVenueValidation),
  });

  const { fields, remove, append } = useFieldArray({
    control,
    name: `featured_amenities`,
  });

  const routes = [
    {
      name: "Admin",
      link: "/admin",
    },
    {
      name: "Venue ",
      link: "/admin/venue",
    },
    {
      name: headerTitle,
      link: "",
    },
  ];

  useEffect(() => {
    if (uuid) {
      setHeaderTitle("Edit");
      dispatch(spinLoaderShow(true));
      getVenue({ uuid: uuid }).then((e) => {
        let data: any = e?.data?.result?.[0];
        
        setValue("name", data.name);
        setValue("contact_phone", data.contact_phone);
        setValue("status", data.status);
        setValue("contact_email", data.contact_email);
        setValue("address", data.address);
        setValue("address_2", data.address_2);
        setValue("slug", data.slug);
        setValue(
          "featured_amenities",
          data?.featured_amenities && data?.featured_amenities?.length > 0
            ? data?.featured_amenities
            : []
        );
        setValue("lat", data?.lat);
        setValue("galleryImageLarge", data?.galleryImageLarge)
        setValue("galleryImageMedium", data?.galleryImageMedium)
        setValue("galleryImageSmall", data?.galleryImageSmall)
        setValue("bannerImage", data?.bannerImage)
        
        setBannerImg(data?.bannerImage ? data?.bannerImage : "");
        setgallaryImageLarge(data?.galleryImageLarge ? data?.galleryImageLarge : "");
        setgallaryImageMedium(data?.galleryImageMedium ? data?.galleryImageMedium : "");
        setgallaryImageSmall(data?.galleryImageSmall ? data?.galleryImageSmall : "");
        setEditData(data);
        dispatch(spinLoaderShow(false));
      });
    } else {
      setHeaderTitle("Add");
      reset(defaultFormValues);
      setEditData([]);
      append({ amenities: "" });
    }
  }, [uuid]);

  // const handleSaveImage = async (files: any) => {

  //   if (files.length > 0) {
  //     dispatch(spinLoaderShow(true));
  //     const formData = new FormData();
  //     for (let i = 0; i < files.length; i++) {
  //       formData.append("images", files[i].file);
  //     }
  //     // formData.append("images", files[0].file)
  //     const { data } = await venueFileUpload(formData);
  //     let temp: any = [];
  //     data?.result?.map((item: any, index: any) => {
  //       temp.push(item);
  //     });
  //     const mergeTemp =
  //       imageSlider.length > 2 ? temp : [...imageSlider, ...temp];
  //     setImageSlider(mergeTemp);
  //     setValue("galleryImage", mergeTemp)
  //    
  //     dispatch(spinLoaderShow(false));
  //   }
  // };

  const handleSaveBanner = async (files: any) => {
    if (files.length > 0) {
      dispatch(spinLoaderShow(true));
      const formData = new FormData();
      formData.append("images", files[0].file);
      const { data } = await venueFileUpload(formData);
      setBannerImg(data.result[0]);
      setValue("bannerImage", data.result[0])
      dispatch(spinLoaderShow(false));
    }
  };
  const handleSaveGallaryLarge = async (files: any) => {
    if (files.length > 0) {
      dispatch(spinLoaderShow(true));
      const formData = new FormData();
      formData.append("images", files[0].file);
      const { data } = await venueFileUpload(formData);
      setgallaryImageLarge(data.result[0]);
      setValue("galleryImageLarge", data.result[0])
      dispatch(spinLoaderShow(false));
    }
  };
  const handleSaveGallaryMedium = async (files: any) => {
    if (files.length > 0) {
      dispatch(spinLoaderShow(true));
      const formData = new FormData();
      formData.append("images", files[0].file);
      const { data } = await venueFileUpload(formData);
      setgallaryImageMedium(data.result[0]);
      setValue("galleryImageMedium", data.result[0])
      dispatch(spinLoaderShow(false));
    }
  };
  const handleSaveGallarySmall = async (files: any) => {
    if (files.length > 0) {
      dispatch(spinLoaderShow(true));
      const formData = new FormData();
      formData.append("images", files[0].file);
      const { data } = await venueFileUpload(formData);
      setgallaryImageSmall(data.result[0]);
      setValue("galleryImageSmall", data.result[0])
      dispatch(spinLoaderShow(false));
    }
  };

  const onSubmit = async (data: any) => {
    // dispatch(spinLoaderShow(true));
    data.lat = mapValues?.autocomplete ? mapValues?.autocomplete.lat : mapValues?.lat;
    data.long = mapValues?.autocomplete ? mapValues?.autocomplete.lng : mapValues?.lng;
    delete data.latLng;
    data.gallaryImageLarge = gallaryImageLarge
    data.gallaryImageMedium = gallaryImageMedium
    data.gallaryImageSmall = gallaryImageSmall
    data.bannerImage = bannerImg;
    data.zoom = String(currentZoom);
    data.center = {
     lat: String(currentCenter.lat),
     lng: String(currentCenter.lng)
    }
    data.lat =  String(data.lat)
    data.long = String(data.long)
   
    if (uuid) {
      data.uuid = uuid;
      updateOnSubmit(data);
    } else {
      dispatch(spinLoaderShow(false));
      createOnSubmit(data);
    }
  
  };

  const createOnSubmit = (data: any) => {
    createVenue(data)
      .then((res) => {
     
        toast.success("Record inserted successfully.");
        reset(defaultFormValues);
        dispatch(spinLoaderShow(false));
        history.push("/admin/venue");
      })
      .catch((err) => {
        dispatch(spinLoaderShow(false));
     
      });
  };

  const updateOnSubmit = (data: any) => {
    updateVenue(data)
      .then((res) => {
       
        toast.success("Record Updated successfully.");
        reset(defaultFormValues);
        dispatch(spinLoaderShow(false));
        history.push("/admin/venue");
       
      })
      .catch((err) => {
        dispatch(spinLoaderShow(false))
      });
  };

  //Delete Image

  const deletImg = (index?: any) => {

    const deletImg = imageSlider.filter((fileObjects: any, i: any) => index !== i);
    
    setImageSlider(deletImg)
    if (deletImg.length > 0) {
      setValue("galleryImage", deletImg)
    }
    else {
      setValue("galleryImage", "")
    }
    handleClose()

  };

  //Dialog Box

  const handleClose = () => {
    setOpen(false);
  };

  //Dialog Box

  useEffect(() => {
    
    if(mapValues?.autocomplete ? mapValues?.autocomplete.lat : mapValues?.lat){
      
    setValue("lat",mapValues?.autocomplete ? mapValues?.autocomplete.lat : mapValues?.lat)
    }
  }, [mapValues])


  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GridContainer>
    
        <InputField
                      errors={!!errors?.lat}
                      fieldName="lat"
                      type="hidden"
                      autoComplete="off"
                      label="* Name"
                      control={control}
                      valueGot={""}
                      setValue={setValue}
                      helperText={errors?.lat && errors?.lat?.message}
                    />
          <GridItem xs={12} sm={12} md={12}>
            <PageHeader
              title="Venue "
              routes={routes}
              newItem={[{ text: "Back", link: "/admin/venue" }]}
              backIcon
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>{headerTitle} Venue</h4>
              </CardHeader>
              <CardBody>

                <GridContainer spacing={5}>
                  <GridItem xs={4} sm={4} md={4}>
                    <InputField
                      errors={!!errors?.name}
                      fieldName="name"
                      type="text"
                      autoComplete="off"
                      label="* Name"
                      control={control}
                      valueGot={""}
                      setValue={setValue}
                      helperText={errors?.name && errors?.name?.message}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={4} md={4}>
                    <InputField
                      errors={!!errors?.address}
                      fieldName="address"
                      type="text"
                      autoComplete="off"
                      label="* Address"
                      control={control}
                      valueGot={""}
                      setValue={setValue}
                      helperText={errors?.address && errors?.address?.message}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={4} md={4}>
                    <InputField
                      errors={!!errors?.address_2}
                      fieldName="address_2"
                      type="text"
                      autoComplete="off"
                      label="* Address 2"
                      control={control}
                      valueGot={""}
                      setValue={setValue}
                      helperText={errors?.address && errors?.address_2?.message}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={4} md={4}>
                    <PhoneNoField
                      errors={!!errors?.contact_phone}
                      fieldName="contact_phone"
                      autoComplete="off"
                      label="* Mobile No"
                      control={control}
                      valueGot={""}
                      setValue={setValue}
                      helperText={
                        errors?.contact_phone && errors?.contact_phone?.message
                      }
                    />
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <InputField
                      errors={!!errors?.contact_email}
                      fieldName="contact_email"
                      type="text"
                      autoComplete="false"
                      label="* Email Address"
                      control={control}
                      valueGot={""}
                      setValue={setValue}
                      helperText={
                        errors?.contact_email && errors?.contact_email?.message
                      }
                    />
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <InputField
                      errors={!!errors?.slug}
                      fieldName="slug"
                      type="text"
                      autoComplete="false"
                      label="* Slug"
                      control={control}
                      valueGot={""}
                      setValue={setValue}
                      helperText={errors?.slug && errors?.slug?.message}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} className={"mt-15"}>
                    <Typography> Description: </Typography>
                    <InputField
                      className={"mt-15"}
                      errors={!!errors?.description}
                      multiline={1}
                      textareaName="description"
                      fieldName="description"
                      type="text"
                      autoComplete="false"
                      label="Description"
                      control={control}
                      isLoading={true}
                      valueGot={editData?.description}
                      setValue={setValue}
                      helperText={
                        errors?.description && errors?.description?.message
                      }
                    />
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4} style={{ marginTop: "15px" }}>
                    <SwitchFieldDefault
                      errors={!!errors?.status}
                      fieldName="status"
                      autoComplete="off"
                      label="Status"
                      control={control}
                      isLoading={true}
                      setValue={setValue}
                      helperText={errors?.status && errors?.status?.message}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12} className={"mt-15"}>
                    <Typography className={classes.heading} variant="subtitle1">
                      {" "}
                      <b>Amenities</b>{" "}
                    </Typography>

                    <Accordion defaultExpanded={true}>
                      <AccordionSummary
                        className={classes.accord}
                        expandIcon={
                          <ExpandMoreIcon className={classes.accord} />
                        }
                      >
                        <Typography>Amenities</Typography>
                      </AccordionSummary>
                      <AccordionDetails className={"arrayaccordian"}>

                        {fields.map((item: any, index) => {
                          return (
                            <GridContainer
                              key={item.id}
                              className={"girdarrayaccordian"}
                            >
                              <GridItem xs={12} sm={12} md={12} >
                                <InputField
                                  errors={
                                    !!errors?.featured_amenities?.[index]
                                      ?.amenities
                                  }
                                  fieldName={`featured_amenities[${index}].amenities`}
                                  type="text"
                                  label={`* Amenities ${index + 1}`}
                                  autoComplete="false"
                                  control={control}
                                  setValue={setValue}
                                  valueGot={""}
                                  helperText={
                                  <div className="imageError">  {errors?.featured_amenities?.[index]
                                      ?.featured_amenities &&
                                    errors?.featured_amenities?.[index]
                                      ?.featured_amenities.message}</div>
                                  }
                                />
                              </GridItem>

                              <GridItem xs={12} sm={12} md={12} className={""}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  className={"custom-btn"}
                                  onClick={() => {
                                    remove(index);
                                  }}
                                >
                                  Remove
                                </Button>
                              </GridItem>
                            </GridContainer>
                          );
                        })}
                        <GridContainer
                          justify="end"
                          alignItems="center"
                          className={"girdarrayaccordian"}
                        >
                          <GridItem xs={12} sm={12} md={12}>
                            <Button
                              variant="contained"
                              color="primary"
                              className={"custom-btn"}
                              onClick={() => {
                                append({ amenities: "" });
                              }}
                            >
                              Add more
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </AccordionDetails>
                    </Accordion>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12} className={"mt-15"}>
                    <Typography className={classes.heading} variant="subtitle1">
                      {" "}
                      <b>Images</b>{" "}
                    </Typography>
                    <Accordion defaultExpanded={true}>
                      <AccordionSummary
                        className={classes.accord}
                        expandIcon={
                          <ExpandMoreIcon className={classes.accord} />
                        }
                      >
                        <Typography>Gallery  Images</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <input {...register("galleryImageLarge")} type="hidden" />
                        <input {...register("galleryImageMedium")} type="hidden" />
                        <input {...register("galleryImageSmall")} type="hidden" />


                        <GridItem xs={12} sm={12} md={12} className={""}>

                          <Container className={classes.cont1}>

                            <GridContainer style={{ Background: 'red' }}>
                              <GridItem xs={12} sm={12} md={4}>
                                <Typography>Gallery Image Large</Typography>
                              </GridItem>
                              <Divider />
                              <GridItem xs={12} sm={12} md={4}>

                                <CardMedia
                                  className={classes.media}
                                  image={
                                    gallaryImageLarge
                                      ? gallaryImageLarge
                                      : "https://www.lifewire.com/thmb/P856-0hi4lmA2xinYWyaEpRIckw=/1920x1326/filters:no_upscale():max_bytes(150000):strip_icc()/cloud-upload-a30f385a928e44e199a62210d578375a.jpg"
                                  }
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={4}>
                                <Box className={classes.drop}>
                                  <FileUploadArea
                                    handleSave={handleSaveGallaryLarge}
                                    message={"Drag and drop a file here or Click. Note: Only jpeg, png will be allowed Optimize image size is 533 x 680 pixels"}
                                  />

                                </Box>

                                <Typography> <span className="imageError">{errors.galleryImageLarge && errors?.galleryImageLarge?.message}</span></Typography>
                              </GridItem>
                            </GridContainer>
                            <GridContainer>
                            <GridItem xs={12} sm={12} md={4}>
                                <Typography>Gallery Image Medium</Typography>
                              </GridItem>
                              <Divider />
                              <GridItem xs={12} sm={12} md={4} className={"mt-15"}>

                                <CardMedia
                                  className={classes.media}
                                  image={
                                    gallaryImageMedium
                                      ? gallaryImageMedium
                                      : "https://www.lifewire.com/thmb/P856-0hi4lmA2xinYWyaEpRIckw=/1920x1326/filters:no_upscale():max_bytes(150000):strip_icc()/cloud-upload-a30f385a928e44e199a62210d578375a.jpg"
                                  }
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={4} className={"mt-15"}>
                                <Box className={classes.drop} >
                                  <FileUploadArea
                                    handleSave={handleSaveGallaryMedium}
                                    message={"Drag and drop a file here or Click. Note: Only jpeg, png will be allowed Optimize image size is 235 x 422 pixels"}
                                  />
                                </Box>
                                <Typography> <span className="imageError">{errors.galleryImageMedium && errors?.galleryImageMedium?.message}</span></Typography>

                              </GridItem>
                            </GridContainer>
                            <GridContainer>
                            <GridItem xs={12} sm={12} md={4}>
                                <Typography>Gallery Image Small</Typography>
                              </GridItem>
                              <Divider />
                              <GridItem xs={12} sm={12} md={4} className={"mt-15"}>

                                <CardMedia
                                  className={classes.media}
                                  image={
                                    gallaryImageSmall
                                      ? gallaryImageSmall
                                      : "https://www.lifewire.com/thmb/P856-0hi4lmA2xinYWyaEpRIckw=/1920x1326/filters:no_upscale():max_bytes(150000):strip_icc()/cloud-upload-a30f385a928e44e199a62210d578375a.jpg"
                                  }
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={4} className={"mt-15"}>
                                <Box className={classes.drop} >
                                  <FileUploadArea
                                    handleSave={handleSaveGallarySmall}
                                    message={"Drag and drop a file here or Click. Note: Only jpeg, png will be allowed Optimize image size is 210 x 283 pixels"}
                                  />
                                </Box>
                                <Typography> <span className="imageError">{errors.galleryImageSmall && errors?.galleryImageSmall?.message}</span></Typography>

                              </GridItem>
                            </GridContainer>

                          </Container>
                        </GridItem>


                      </AccordionDetails>
                    </Accordion>
                  </GridItem>
                  {/* Banner Image  accordion start */}
                  <GridItem xs={12} sm={12} md={12} className={""}>
                    <Accordion defaultExpanded={true}>
                      <AccordionSummary
                        className={classes.accord}
                        expandIcon={
                          <ExpandMoreIcon className={classes.accord} />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Banner Image </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <input {...register("bannerImage")} type="hidden" />
                        <GridItem xs={12} sm={12} md={12} className={""}>
                          <Container className={classes.cont1}>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={4}>
                                <CardMedia
                                  className={classes.media}
                                  image={
                                    bannerImg
                                      ? bannerImg
                                      : "https://www.lifewire.com/thmb/P856-0hi4lmA2xinYWyaEpRIckw=/1920x1326/filters:no_upscale():max_bytes(150000):strip_icc()/cloud-upload-a30f385a928e44e199a62210d578375a.jpg"
                                  }
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={4}>
                                <Box className={classes.drop}>
                                  <FileUploadArea
                                    handleSave={handleSaveBanner}
                                    message={"Drag and drop a file here or Click. Note: Only jpeg, png will be allowed Optimize image size is 1920 x 350 pixels"}
                                  />
                                </Box>
                                <Typography> <span className="imageError">{errors.bannerImage && errors?.bannerImage?.message}</span></Typography>

                              </GridItem>
                            </GridContainer>
                          </Container>
                        </GridItem>
                      </AccordionDetails>
                    </Accordion>
                  </GridItem>
                  {/* Banner Image  accordion end */}
                </GridContainer>
                {/* map==================================================> */}
                <Box mt={2}>
                  <GridContainer mt={2}>
                    <GridItem xs={12} sm={12} md={12}>
                      <Typography variant={"subtitle1"}>
                        <b>point area</b>{" "}
                        <span className="google-map-error-text">
                        </span>
                      </Typography>
                     { <div className="imageError">  {errors.lat && errors?.lat?.message}</div>}
                      {/* <button onClick={handleClick}>Get Zoom</button> */}
                      {editData && (
                        <FormHelperText
                          error={errors.polygon && true}
                          component="div"
                          className="google-map-error"
                        >
                          <GoogleMap
                            draggable={true}
                            value={setMapValues}
                            setValue={{}}
                            autocomplete
                            reset={resetMap}
                            zoom={10}
                            onZoomChanged={(data: any) => {
                      
                              setCurrentZoom(data);
                            }}
                            onCenterChanged={(data: any) => {
                              setcurrentCenter(data);
                            }}
                            center={{
                              lat: parseFloat(
                                editData?.lat ? editData?.lat : "25.2048"
                              ),
                              lng: parseFloat(
                                editData?.long ? editData?.long : "55.2708"
                              ),
                            }}
                          >
                            {/* <DrawMap data={editData} /> */}
                          </GoogleMap>
                        </FormHelperText>
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      {mapValues?.lat}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      {mapValues?.lng}
                    </GridItem>
                  </GridContainer>
                </Box>
                {/* map==================================================> */}
              </CardBody>
              <CardFooter>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  style={{ width: "100%" }}
                >
                  <Button
                    color="primary"
                    className={"custom-btn"}
                    type="submit"
                  >
                    {uuid ? "Update Venue" : "Create Venue "}
                  </Button>
                </Box>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}
