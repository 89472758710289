import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Route, Switch, Redirect, useHistory } from "react-router-dom";
import { userPermissions } from "redux/action/permission";
import { permissionsx } from "json/permissions";
import Admin from "./layouts/Admin";
import LoginLayout from './containers/Login/LoginLayout'
import forgotPasswordLayout from './containers/ForgotPassword/ForgotPasswordLayout'
import SecretLayout from './containers/Secret/SecretLayout'
import { getRoles } from "services/userservices";
import { congitoConfig, signOut } from "services/cognitouser";
import { ToastContainer, toast } from 'react-toastify';
import { Auth } from "aws-amplify";
import { spinLoaderShow } from 'redux/action/spinLoader'

export const App = () => {
  const dispatch = useDispatch();
  let history = useHistory();

 let unlistn = history.listen(({ action, location }) => {
   
     checkRoles();
  });

  const checkRoles = async ()=>{
 
    dispatch(spinLoaderShow(true))

    // dispatch(userPermissions(permissionsx));

    if((await Auth.currentSession()).isValid() ){
      getRoles().then((res: any) => {
    
        
        if (res?.data?.result[0]?.status) {
          dispatch(userPermissions(res.data.result[0]["permissions"] ));
          //dispatch(userPermissions(permissions));
          dispatch(spinLoaderShow(false))

        } else {
          signOut();
          toast.error("Your role is disabled! Contact Admin");
          history.push("/")
        }
      })
    }

  }
  useEffect(()=>{
    // setTimeout(()=>{
    //   Auth.currentSession()
    // },5)
  })

  useEffect(() => {
   // FOR PERMISSION DYNAMIC
   Auth.configure(congitoConfig);


    // dispatch(userPermissions(permissionsx));  //Comment This
     checkRoles();  //UnComment This
    //GET FROM SessionStorage
   
    return () => {

      toast.error("Your role is disabled! Contact Admin");

    };
  }, [])

  return (
    <Switch>
      <Route path="/admin" component={Admin} />
      <Route path="/" exact component={LoginLayout} />
      <Route path="/secret" exact component={SecretLayout} />
      <Route path="/forgot"exact component={forgotPasswordLayout} />


      {/* <Route path="/vendor/secret" exact component={SecretLayout} /> */}

      {/* <Redirect from="/" to="/admin/dashboard" /> */}
    </Switch>
  );
};
