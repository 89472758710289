import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import PageHeader from 'components/PageHeader/PageHeader'
import { useState, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { spinLoaderShow } from 'redux/action/spinLoader'
import { useDispatch } from 'react-redux'
import { HolidayValidations } from './Validations/holidayValidations'
import {createStyles} from '@material-ui/core/styles';
import { getHoliday, createHoliday, updateHoliday, getFacility, getHolidayExcludedfacilities } from 'services/userservices'

import {
  BrowserRouter as Router,
  useHistory,
  useLocation
} from "react-router-dom";
import moment from "moment";
import CalendarSchedulerCustom from "components/CalendarScheduler/CalendarSchedulerCustom";
import _ from 'lodash'


const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    cardCategoryWhite: {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: 300,
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
    }
  }),
);

declare var google: any;


export default function AddHoliday(props: any) {
  const classes = useStyles();
  const [editData, setEditData] = useState<any>(null)
  const [headerTitle, setHeaderTitle] = React.useState<string>('List');
  const [facilities, setFacilities] = useState<any>([])
  const [calendarLoad, setCalendarLoad] = useState<any>(false)
  const [addScheduled, setAddScheduled] = useState<any>({})
  let history = useHistory();
  const { search } = useLocation()
  let uuid = new URLSearchParams(search).get("uuid");

  const [scheduleData, setSchedulteData] = useState<any>({
    data: [],
    confirmationVisible: false,
    editingFormVisible: false,
    deletedAppointmentId: undefined,
    editingAppointment: undefined,
    previousAppointment: undefined,
    addedAppointment: {},
    isNewAppointment: false,
  });

  const dataFromServer = {
    status: true,
  };

  useEffect(() => {
    if (uuid) {
      dispatch(spinLoaderShow(true))

    }
    else {
      setHeaderTitle('List')
      reset(dataFromServer)
      setEditData({})
    }
  }, [uuid])


  let renderCount = 0;
  const dispatch = useDispatch()
  const { control, register, handleSubmit, formState: { errors }, setValue, reset, getValues, trigger } = useForm<any>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: [],
    resolver: yupResolver(HolidayValidations)
  });


  const routes = [
    {
      'name': "Admin",
      'link': '/admin'
    },
    {
      'name': "Holiday ",
      'link': '/admin/holiday'
    },
    {
      'name': headerTitle,
      'link': ''
    },

  ];
  //get holiday data Api..........

  const getHolidayData = () => {
    dispatch(spinLoaderShow(true));
    setCalendarLoad(false)
    getHoliday({
      // status: true
    }).then((res: any) => {
      let data = res?.data?.result

      let temp: any = [];
      data = data.length > 0 ? data.map((item: any, index: any) => {
        let dateSplit: any = moment(item.date).format('YYYY-MM-DD');
        let year: any = dateSplit.split('-')[0];
        let month: any = dateSplit.split('-')[1];
        let day: any = dateSplit.split('-')[2];

        temp.push({
          "id": index,
          "uuid": item.uuid,
          "status": item.status,
          "title": item.description,
          "startDate": new Date(year + ' ' + month + ' ' + day + ' 0:0'),
          "endDate": new Date(year + ' ' + month + ' ' + day + ' 0:1'),
          "allDay": true,
          "facility": []
        })
      }) : []

      setSchedulteData(temp);
      setCalendarLoad(true)
      dispatch(spinLoaderShow(false));
    }).catch((err: any) => {

    })

  }


  useEffect(() => {
    getHolidayData()
  }, []);

  useEffect(() => {

    if (Object.keys(addScheduled).length > 0) {
      let data: any = Object.values(addScheduled);
      if (data?.[0]?.uuid) {
        onSubmit(data, data?.[0]?.uuid);
      } else {
        onSubmit([addScheduled]);
      }
    }

  }, [addScheduled]);


  const operation = (list1: any, list2: any, isUnion: any = false) =>
    list1.filter(
      (set => (a: any) => isUnion === set.has(a.facility_id))(new Set(list2.map((b: any) => b.facility_id)))
    );

  // Following functions are to be used:
  const inBoth = (list1: any, list2: any) => operation(list1, list2, true),
    inFirstOnly = operation,
    inSecondOnly = (list1: any, list2: any) => inFirstOnly(list2, list1);


  const onSubmit = async (data: any, uuid: any = null) => {
    let facilities: any = data[0].facility?.length > 0 ? data[0].facility.map((item: any) => {
      let _facility: any = {};
      _facility.facility_id = item.uuid;
      _facility.status = item.status
      return _facility;
    }) : [];

    let resultData: any = []
    let modifyData: any = facilities;
    if (uuid) {
      dispatch(spinLoaderShow(true))
      await getHolidayExcludedfacilities({
        holiday_id: uuid,
        status: true
      }).then((res) => {
        resultData = res?.data?.result
      }).catch((err) => {
      
      })

      modifyData = resultData.map((t: any) => {
        facilities.map(function (c: any) {
          if ((c.facility_id === t.facility_id)) {
            return t.status = false;
          }
          return c;
        });
        return t
      }).map((x: any) => {
        x.status = !x.status
        return x;
      })

      modifyData = [...modifyData, ...inFirstOnly(facilities, resultData)]

    }

    let _data = data.length > 0 ? data?.map((item: any) => {
      var _modify_item: any = {}
      _modify_item.uuid = item?.uuid
      _modify_item.date = moment(item.startDate).format("YYYY-MM-DD")
      _modify_item.description = item.title
      _modify_item.status = item.status ? true : false
      _modify_item.facilities = modifyData
      return _modify_item;
    }) : []

    let temp = Object.assign({}, _data)


    if (uuid) {
     
      temp[0].uuid = uuid
      delete temp[0].date;
    
      updateOnSubmit(temp[0])
    }
    else {

      createOnSubmit(temp[0])
    }
  }

  const createOnSubmit = (data: any) => {
  
    createHoliday(data).then((e: any) => {
      
      dispatch(spinLoaderShow(true))
      toast.success("Record inserted successfully.");
      getHolidayData()
    })
      .catch((e: any) => {
        getHolidayData()
        dispatch(spinLoaderShow(false));
      });
  };
  //get facilities data ...
  useEffect(() => {
    getFacility({ status: true }).then((res: any) => {
      dispatch(spinLoaderShow(true));
      setFacilities(res?.data?.result)
   
      dispatch(spinLoaderShow(false));

    }).catch((err: any) => {
      dispatch(spinLoaderShow(false))
    })


  }, [])

  //my user


  //update holiday data..

  const updateOnSubmit = (data: any) => {

    updateHoliday(data).then((e: any) => {
      toast.success("Record updated successfully");
      getHolidayData()
      //reset(dataFromServer)
      //history.push("/admin/holiday");

    })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });

  };
  renderCount++;


  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <PageHeader
              title="Holiday "
              routes={routes}
              newItem={[
                // { text: 'Add', link: "/admin/city/add", 'uuid': uuid ? false : true },
                // { text: 'Back', link: "/admin/holiday" }
              ]}
              backIcon
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>{headerTitle} Holiday</h4>
              </CardHeader>
              <CardBody>
                <GridContainer spacing={3}>
                  <GridItem xs={12} sm={12} md={12}>
                    {calendarLoad && <CalendarSchedulerCustom
                      setSchedulteData={setSchedulteData}
                      scheduleData={scheduleData}
                      setAddScheduled={setAddScheduled}
                      facilities={facilities.length && facilities.length > 0 ? facilities : []}
                    />}

                  </GridItem>

                  {/* <GridItem xs={4} sm={4} md={4} className={"mt-15"}>
                    <Switch
                      checked={state.checkedA}
                      onChange={handleChange}
                      name="checkedA"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </GridItem> */}
                </GridContainer>
              </CardBody>
              {/* <CardFooter>
                <Box display="flex" justifyContent="flex-end" style={{ width: '100%' }}>
                  <Button color="primary" className={"custom-btn"} type="submit" >{uuid ? 'Update Holiday' : "Create Holiday"}</Button>
                </Box>

              </CardFooter> */}
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div >
  );
}
