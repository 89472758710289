import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Table from "components/Table/Table";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import AgGridCustom from "components/AgGridCustom/AgGridCustom";
import PageHeader from "components/PageHeader/PageHeader";
import StatusComponent from 'components/Mix/Status';
// import { getVenue, getSchedules } from "services/userservices";

import EditIcon from '@material-ui/icons/Edit';
import { Link } from "react-router-dom";
import moment from "moment";
import { verifyPermission } from 'helper/permission'
import { useSelector } from "react-redux";
import Button from "components/CustomButtons/Button";
import { useDispatch } from 'react-redux'
import { spinLoaderShow } from "redux/action/spinLoader";
import {getVenue, createVenue, updateVenue}  from 'services/userservices'

const styles: any = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export const VenueList = (props: any) => {
  const getPermission = useSelector((state: any) => { return state.userPermission.permissions })
  const classes = useStyles();
  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState<any>(null);
  const [disabled, setDisabled] = useState<any>(false)
  const [exportData, setExportData] = useState<any>(null)
  const dispatch = useDispatch()

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const routes = [
    {
      'name': "Admin",
      'link': '/admin'
    },
    {
      'name': "Venue ",
      'link': ''
    }
  ];

  const columnDefs: any = [
    {
      headerName: "Name",
      field: 'name',
    },
    {
      headerName: "Latitude",
      field: 'lat',
    },
    {
      headerName: "Longitude",
      field: 'long',
    },
    {
      headerName: "Address",
      field: 'address',
    },
    {
      headerName: "Description",
      field: 'description',
    },
    {
      headerName: 'Mobile no',
      field: 'contact_phone',
    },

    {
      headerName: 'Email',
      field: 'contact_email',
    },

    {
      headerName: 'Status',
      field: "status",
      cellRendererFramework: (params: any) => {
        return <StatusComponent data={params} />;
      }
    },
    {
      headerName: 'Created Date',
      field: "createdAt",
      filter: "agDateColumnFilter",
      cellRendererFramework: (params: any) => {
        return moment(params.value).format('llll');
      },
    },
    {
      headerName: 'Modified Date',
      field: "updatedAt",
      sort: "desc",
      filter: "agDateColumnFilter",
      cellRendererFramework: (params: any) => {
        return moment(params.value).format('llll');
      },

    },
    {
      headerName: 'Actions',
      field: "action",
      sortable: false,
      filter: false,
      hide: disabled,
      cellRendererFramework: (params: any) => {
        // put the value in bold
        return <Link to={'venue/edit?uuid=' + params?.data?.uuid}><EditIcon /></Link>;
      }
    },
  ];
  //get venue data using venue Api....
  useEffect(() => {
    dispatch(spinLoaderShow(true));
    getVenue().then((e) => {
   
      setRowData(e.data.result)
      dispatch(spinLoaderShow(false));
    }).catch((err) => {
    
      dispatch(spinLoaderShow(false))
    })
    let ispermission = !(verifyPermission(
      getPermission,
      'Venue',
      'Manage',
      'update'
    ))
    setDisabled(ispermission)
  }, [])



  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <PageHeader
          title={`Venue `}
          routes={routes}
          newItem={[{ text: 'Add New Venue', link: "venue/add" }]}
          permission="Venue.Manage.create"
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary" className="card-header-export-btn">
            <h4 className={classes.cardTitleWhite}>Venue Listing</h4>
            <Button variant="outlined" className="custom-btn export-btn" onClick={(e: any) => exportData.exportDataAsCsv()}>Export to Csv</Button>

          </CardHeader>
          <CardBody>
            <AgGridCustom
              columnDefs={columnDefs}
              rowData={rowData}
              setExportData={setExportData}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}


