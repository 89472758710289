import * as yup from 'yup'



export const createfacilitiesValidations: any = yup.object().shape({
  name: yup.string().max(22, "Max character limit is 22").required("Please enter name").nullable(), 
  long_desc: yup.string().max(150, "Max character limit is 150").required("Please enter long description").nullable(),
  short_desc: yup.string().max(55, "Max character limit is 55").required("Please enter short description").nullable(),
  venues: yup.array().required("Please select venue").min(1, 'Please select venue').nullable(),
  type: yup.object().required("please select type").nullable(),
  slug: yup.string().matches(/^[a-z0-9-.]+$/,"No Space is allowed!").required("please select slug").nullable(),
  listingImage_Desktop: yup.string().required("please upload listing image desktop").nullable(),
  listingImage_Mobile: yup.string().required("please upload listing image mobile").nullable(),
  carouselImage: yup.string().required("please upload home carousel image").nullable(),
  bannerImage: yup.string().required("please upload banner image").nullable(),
  venuesData: yup.array().of(
    yup.object().shape({
      days: yup.array().of(yup.object().shape({
        schedules: yup.array().of(
          yup.object().shape({
            status: yup.boolean().default(true),
            start_time: yup.date().required('Please select start time.').nullable(),
            end_time: yup.date().required('Please select end time.').min(yup.ref('start_time'), 'End time should be later than start time').nullable(),
            persons: yup.string().required("Please select seats").nullable(),
            price: yup.string().required("Please select price").nullable(),
          })
        ),
      }))
    })
  )




})

