import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import PageHeader from 'components/PageHeader/PageHeader'
import { Box } from "@material-ui/core";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { InputField, AutoCompleteField, SwitchFieldDefault, SelectField } from "components/Forms/Formfield.component";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { spinLoaderShow } from 'redux/action/spinLoader'
import { useDispatch } from 'react-redux'
import { createAdminValidation } from './Validations/adminValidations'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useLocation
} from "react-router-dom";
import { getRoles, createAdmin, getAdmin, updateAdmin, regenerateCode } from "services/userservices";
import axios from "axios";

const styles: any = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const defaultFormValues = {
  first_name: '',
  last_name: '',
  email: '',
  role_id: '',
  status: true,

}


const options: any = [{ id: 1, name: "Admin" }, { id: 2, name: "User" }, { id: 2, name: "Developer" }];
const useStyles = makeStyles(styles);
declare var google: any;


export default function AddAdmin(props: any) {
  const classes = useStyles();
  let history = useHistory();
  const [roles, setRoles] = useState<any>([])
  const [isDisabled, setDisabled] = useState<any>(true);
  const [codeDisabled, setcodeDisabled] = useState<any>(true);
  const [editData, setEditData] = useState<any>(null)
  const [adminType, setAdminType] = useState<any>(null)
  const [vendors, setVendors] = useState<any>(null)
  const [headerTitle, setHeaderTitle] = React.useState<string>('Add');
  const { search } = useLocation()
  let uuid = new URLSearchParams(search).get("uuid");
  const dispatch = useDispatch()
  const { control, register, handleSubmit, formState: { errors }, setValue, reset, getValues } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultFormValues,
    resolver: yupResolver(createAdminValidation)
  });

  useEffect(() => {
    if (uuid) {
      dispatch(spinLoaderShow(true))
      getAdmin({ uuid: uuid }).then((e: any) => {
        let data: any = e?.data?.result?.[0]
        setValue("first_name", data.first_name)
        setValue("last_name", data.last_name)
        setValue("email", data.email)
        setValue("status", data.status)
        setEditData(data)
        setHeaderTitle('Edit')
        setDisabled(false)
        dispatch(spinLoaderShow(false))
      }).catch((err) => {
        dispatch(spinLoaderShow(false))
      })
    } else {
      setHeaderTitle('Add')
      reset(defaultFormValues)
      setEditData({})
    }
  }, [])

  useEffect(() => {
    if (editData?.cognito_id == 'Pending') {
      setcodeDisabled(false)
    }
    else {
      setcodeDisabled(true)
    }
  }, [editData])


  const routes = [
    {
      'name': "Admin",
      'link': '/admin/users'
    },
    {
      'name': "User",
      'link': '/admin/users'
    },
    {
      'name': headerTitle,
      'link': ''
    },

  ];

  const viewRole = () => {
    const win = window.open("/admin/role/edit/" + getValues("role_id"), "_blank");
    win?.focus()
    history.push('/admin/role/add')
  }


  const onSubmit = (data: any) => {
    dispatch(spinLoaderShow(true));
    delete data.role_id
    if (uuid) {
      data.uuid = uuid
      updateOnSubmit(data)
    } else {
      createOnSubmit(data)
    }
  }

  // Update admin user data ...
  const updateOnSubmit = (data: any) => {
    // updateAdmin(data)
    updateAdmin(data).then((e: any) => {
      dispatch(spinLoaderShow(true));
      toast.success("Record updated successfully");
      history.push("/admin/users")

    })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });

  };


  const createOnSubmit = (data: any) => {
    // createAdmin(data)
    createAdmin(data).then((e: any) => {
      dispatch(spinLoaderShow(false));
      toast.success("Record inserted successfully.");
      history.push("/admin/users")
    })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });
  };

  const disableRoleButton = (data: any) => {

    if (data) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }


  }
  const regenerateVerificationCode = () => {
    if (editData.cognito_id == "Pending") {
      // dispatch(spinLoaderShow(true));
      regenerateCode({ uuid: uuid }).then((e: any) => {
        dispatch(spinLoaderShow(false));
        toast.success("Code sent on email.");
      }).catch((err: any) => {
      })
    }
    else {
      setcodeDisabled(true)
    }



  }

  const handleTypeChange = (e: any, data: any) => {
    setAdminType(data?.props?.value)
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <PageHeader
              title="Admin "
              routes={routes}
              newItem={[
                // { text: 'Add', link: "/admin/city-manager/add", 'uuid': uuid ? false : true },
                { text: 'Back', link: "/admin/users" }]}
              backIcon
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}> {headerTitle} Admin</h4>
              </CardHeader>
              <CardBody>
                <GridContainer spacing={3}>

                  <GridItem xs={4} sm={4} md={4}>
                    <InputField
                      errors={!!errors?.first_name}
                      fieldName="first_name"
                      type="text"
                      autoComplete="off"
                      label="* First Name"
                      control={control}
                      valueGot={""}
                      setValue={setValue}
                      helperText={
                        errors?.first_name && errors?.first_name?.message
                      }
                    />
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <InputField
                      errors={!!errors?.last_name}
                      fieldName="last_name"
                      type="text"
                      autoComplete="off"
                      label="* Last Name"
                      control={control}
                      valueGot={""}
                      setValue={setValue}
                      helperText={
                        errors?.last_name && errors?.last_name?.message
                      }
                    />
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <InputField
                      errors={!!errors?.email}
                      iProps={{
                        disabled : editData?.uuid ? true : false
                      }}
                      fieldName="email"
                      type="text"
                      autoComplete="false"
                      label="* Email Address"
                      control={control}
                      valueGot={""}
                      setValue={setValue}
                      helperText={
                        errors?.email && errors?.email?.message
                      }
                    />
                  </GridItem>
                  {/* <GridItem xs={4} sm={4} md={4} >
                    <AutoCompleteField
                      errors={!!errors?.role_id}
                      fieldName="role_id"
                      autoComplete="off"
                      label="* Select Role"
                      control={control}
                      setValue={setValue}
                      options={roles}
                      isLoading={options.length > 0 ? true : false}
                      optionKey="uuid"
                      optionValue="name"
                      iProps={{
                        onChange: disableRoleButton
                      }}
                      helperText={
                        errors?.role_id && errors?.role_id?.message
                      }
                      valueGot={editData?.role_id && roles.find(({ uuid }: any) => { return uuid == editData?.role_id })}
                    />
                  </GridItem> */}
                  <GridItem xs={8} sm={8} md={8} >
                    {
                      adminType == 'admin' &&
                      <>

                        {/* <GridItem xs={4} sm={2} md={2} style={{ marginTop: "10px" }}>
                        <Button color="primary" disabled={isDisabled} onClick={() => { viewRole() }} >View Role</Button>
                      </GridItem> */}
                      </>
                    }

                    {
                      uuid && <GridItem xs={4} sm={2} md={2} style={{ marginTop: "10px" }}>
                        <Button color="primary" disabled={codeDisabled} onClick={() => regenerateVerificationCode()} >Regenerate Verification Code</Button>
                      </GridItem>
                    }
                  </GridItem>

                  <GridItem xs={12} sm={2} md={4} style={{ marginTop: "15px" }}>
                    <SwitchFieldDefault
                      errors={!!errors?.status}
                      fieldName="status"
                      autoComplete="off"
                      label="Status"
                      control={control}
                      isLoading={true}
                      setValue={setValue}
                      helperText={
                        errors?.status &&
                        errors?.status?.message
                      }
                    />
                  </GridItem>


                </GridContainer>

              </CardBody>
              <CardFooter>
                <Box display="flex" justifyContent="flex-end" style={{ width: '100%' }}>
                  <Button color="primary" type="submit" >{uuid ? 'Update Admin' : "Create Admin"}</Button>
                </Box>

              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}
