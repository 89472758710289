/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
// core components
import footerStyle from "assets/jss/material-dashboard-react/components/footerStyle";
const styles:any = footerStyle
const useStyles = makeStyles(styles);

export default function Footer(props:any) {
  const classes = useStyles();
  const currentDate:any = new Date();
  const year:number = currentDate.getYear();
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
      
        <p className={classes.right}>
          <span>
            &copy; {1900 + year }{" Copyrights. All rights reserved. Created By "}
            <a
              href="https://www.digitalgravity.ae"
              target="_blank"
              className={classes.a}
            >
              Digital Gravity
            </a>
            
          </span>
        </p>
      </div>
    </footer>
  );
}
