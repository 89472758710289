import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import PageHeader from "components/PageHeader/PageHeader";
import _ from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  InputField,
  PhoneNoField,
  SwitchFieldDefault
} from "components/Forms/Formfield.component";
import { useEffect } from "react";
import { Accordion, AccordionDetails, AccordionSummary, FormHelperText, Typography } from "@material-ui/core";
import GoogleMap from "components/GoogleMap/GoogleMap";
import 'react-toastify/dist/ReactToastify.css';
import { spinLoaderShow } from 'redux/action/spinLoader'
import { useDispatch } from "react-redux";
import { AccordionActions } from '@material-ui/core';
import { getBooking } from 'services/userservices'



import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useLocation
} from "react-router-dom";

const styles: any = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const defaultFormValues: any = {
  name: '',
  polygon: {},
  mobile_no: '',
  email: '',
  status: true,
};

const useStyles = makeStyles(styles);
declare var google: any;




export default function AddBooking(props: any) {

  //create States ................

  const dispatch = useDispatch()
  const classes = useStyles();
  const [headerTitle, setHeaderTitle] = React.useState<string>('Details');
  const [editData, setEditData] = useState<any>(null)
  const [resetMap, setResetMap] = useState<any>(false);
  const [mapValues, setMapValues] = useState<any>(null);
  const [currentZoom, setCurrentZoom] = useState(12);
  const [currentCenter, setcurrentCenter] = useState({
    lat: parseFloat(editData?.lat ? editData?.lat : "25.2048"),
    lng: parseFloat(editData?.long ? editData?.long : "55.2708"),
  });
  const [rowData, setRowData] = useState<any>({})


  let history = useHistory();

  const { search } = useLocation()
  let uuid = new URLSearchParams(search).get("uuid");

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultFormValues,
  });

  //routes................
  const routes = [
    {
      name: "Admin",
      link: "/admin",
    },
    {
      name: "booking List ",
      link: "/admin/booking",
    },
    {
      'name': headerTitle,
      'link': ''
    },

  ];


  useEffect(() => {

    if (uuid) {
      dispatch(spinLoaderShow(false))
      getBooking({ id: uuid })
        .then((res) => {

          let data: any = res?.data?.result?.[0];
          let temp = Object.assign({}, data.booking_details[0])
          data.booking_details[0] = temp
          setEditData(data);
          setRowData(res?.data?.result?.[0])
         



          // facility==============================>

          setValue("name", data?.facilities?.name);
          setValue("title", data?.facilities?.title);
          setValue("type", data?.facilities?.type);

          // facility==============================>

          // venues==============================>

          setValue("address", data?.venues?.address);
          setValue("contact_email", data?.venues?.contact_email);
          setValue("contact_phone", data?.venues?.contact_phone);

          // venues==============================>

          // booking_details==============================>

          setValue("price", temp?.price);
          setValue("bookingAt", temp?.bookingAt);
          setValue("start_time", temp?.start_time);
          setValue("end_time", temp?.end_time);
          // booking_details==============================>

          // customers==============================>

          setValue("first_name", data?.customers?.first_name+" "+ data?.customers?.last_name);
          setValue("city", data?.customers?.city);
          setValue("nationality", data?.customers?.nationality);
          setValue("contact_phone", data?.venues?.contact_phone);
          setValue("email", data?.customers?.email);
          // customers==============================>

          dispatch(spinLoaderShow(false))

        })

    }
    else {
      setHeaderTitle('Add')
      reset(defaultFormValues)
      setEditData([])

    }
  }, [uuid]);

  const onSubmit = async (data: any) => {
    dispatch(spinLoaderShow(true));
    data.lat = mapValues.lat
    data.long = mapValues.lng
    if (uuid) {
      data.uuid = uuid;
      updateOnSubmit(data);
    } else {
      createOnSubmit(data);
    }
  };

  const createOnSubmit = (data: any) => {
    // createBranch(data)
    //   .then((e: any) => {
    //     dispatch(spinLoaderShow(false));
    //     toast.success("Record inserted successfully.");
    //     reset(defaultFormValues);
    //     setResetMap(true);
    //     history.push("/admin/branch-manager");
    //   })
    //   .catch((e: any) => {
    //     dispatch(spinLoaderShow(false));
    //   });
  };

  const updateOnSubmit = (data: any) => {

    // updateBranch(data)
    //   .then((e: any) => {
    //     dispatch(spinLoaderShow(false));
    //     toast.success("Record updated successfully");
    //     setResetMap(true);
    //     history.push("/admin/branch-manager");
    //   })
    //   .catch((e: any) => {
    //     dispatch(spinLoaderShow(false));
    //   });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <PageHeader
              title="Booking "
              routes={routes}
              newItem={[
                { text: 'Back', link: "/admin/booking" }]}
              backIcon
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>
                  Booking {headerTitle}
                </h4>
              </CardHeader>
              <CardBody>

                {/* facility accordian==============================> */}

                <Accordion defaultExpanded={true}>
                  <AccordionSummary
                    // expandIcon={<ExpandMoreIcon  />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Facility</Typography>
                  </AccordionSummary>
                  <AccordionDetails>

                    <GridContainer spacing={3} style={{ width: '100%' }}>
                      <GridItem xs={6} sm={6} md={6}>
                        <InputField disabled={true}

                          errors={!!errors?.name}
                          fieldName="name"
                          type="text"
                          autoComplete="off"
                          label="* Name"
                          control={control}
                          valueGot={""}
                          setValue={setValue}
                          helperText={
                            errors?.name && errors?.name?.message
                          }
                        />
                      </GridItem>

                      <GridItem xs={6} sm={6} md={6}>
                        <InputField disabled={true}

                          errors={!!errors?.type}
                          fieldName="type"
                          type="text"
                          autoComplete="off"
                          label="* type"
                          control={control}
                          valueGot={""}
                          setValue={setValue}
                          helperText={
                            errors?.type && errors?.type?.message
                          }
                        />
                      </GridItem>

                    </GridContainer>

                  </AccordionDetails>

                  <AccordionActions>
                  </AccordionActions>
                </Accordion>

                {/* facility accordian==============================> */}

                {/* Venue accordian==============================> */}
                <Accordion defaultExpanded={true}>
                  <AccordionSummary
                    // expandIcon={<ExpandMoreIcon  />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Venues</Typography>
                  </AccordionSummary>
                  <AccordionDetails>

                    <GridContainer spacing={5} style={{ width: '100%' }}>
                      <GridItem xs={4} sm={4} md={4}>
                        <InputField disabled={true}

                          errors={!!errors?.adress}
                          fieldName="address"
                          type="text"
                          autoComplete="off"
                          label="* Adress"
                          control={control}
                          valueGot={""}
                          setValue={setValue}
                          helperText={
                            errors?.adress && errors?.adress?.message
                          }
                        />
                      </GridItem>

                      <GridItem xs={4} sm={4} md={4}>
                        <InputField disabled={true}

                          errors={!!errors?.contact_email}
                          fieldName="contact_email"
                          type="text"
                          autoComplete="off"
                          label="* Email"
                          control={control}
                          valueGot={""}
                          setValue={setValue}
                          helperText={
                            errors?.contact_email && errors?.contact_email?.message
                          }
                        />
                      </GridItem>

                      <GridItem xs={4} sm={4} md={4}>
                        <InputField disabled={true}

                          errors={!!errors?.contact_phone}
                          fieldName="contact_phone"
                          autoComplete="off"
                          label="* Mobile No"
                          control={control}
                          valueGot={""}
                          setValue={setValue}
                          helperText={
                            errors?.contact_phone && errors?.contact_phone?.message
                          }
                        />
                      </GridItem>


                      {/* map============================> */}

                      <GridItem xs={12} sm={12} md={12} className={"mt-15"}>
                        <Typography variant={"subtitle1"}>
                          <b>point area</b>{" "}
                          <span className="google-map-error-text">
                            {errors.polygon && errors?.polygon?.latLng?.message}
                          </span>
                        </Typography>
                        {/* <button onClick={handleClick}>Get Zoom</button> */}
                        {editData && (
                          <FormHelperText
                            error={errors.polygon && true}
                            component="div"
                            className="google-map-error"
                          >
                            <GoogleMap
                              draggable={false}
                              value={setMapValues}
                              setValue={{
                                latLng: {

                                  lat: editData?.latitude ? +editData?.latitude : 25.276987,
                                  lng: editData?.longitude ? +editData?.longitude : 55.296249
                                }
                              }}
                              autocomplete
                              reset={resetMap}
                              zoom={10}
                              onZoomChanged={(data: any) => {
                                setCurrentZoom(data);
                              }}
                              onCenterChanged={(data: any) => {
                                setcurrentCenter(data);
                              }}
                              center={{
                                lat: parseFloat(
                                  editData?.venues?.lat ? editData?.venues?.lat : "25.2048"
                                ),
                                lng: parseFloat(
                                  editData?.venues?.long ? editData?.venues?.long : "55.2708"
                                ),
                              }}
                            >
                              {/* <DrawMap data={editData} /> */}
                            </GoogleMap>
                          </FormHelperText>
                        )}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        {mapValues?.lat}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        {mapValues?.lng}
                      </GridItem>
                    </GridContainer>

                    {/* map============================> */}



                  </AccordionDetails>
                  <AccordionActions>
                  </AccordionActions>
                </Accordion>

                {/* Venue accordian==============================> */}

                {/* Customer accordian==============================> */}

                <Accordion defaultExpanded={true}>
                  <AccordionSummary
                    // expandIcon={<ExpandMoreIcon  />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Customer</Typography>
                  </AccordionSummary>
                  <AccordionDetails>

                    <GridContainer spacing={3} style={{ width: '100%' }}>
                      <GridItem xs={6} sm={6} md={6}>
                        <InputField disabled={true}

                          errors={!!errors?.first_name}
                          fieldName="first_name"
                          type="text"
                          autoComplete="off"
                          label="Name"
                          control={control}
                          valueGot={""}
                          setValue={setValue}
                          helperText={
                            errors?.first_name && errors?.first_name?.message
                          }
                        />
                      </GridItem>

                      <GridItem xs={6} sm={6} md={6}>
                        <InputField disabled={true}

                          errors={!!errors?.email}
                          fieldName="email"
                          type="text"
                          autoComplete="off"
                          label=" Email"
                          control={control}
                          valueGot={""}
                          setValue={setValue}
                          helperText={
                            errors?.email && errors?.email?.message
                          }
                        />
                      </GridItem>

                      <GridItem xs={6} sm={6} md={6}>
                        <InputField disabled={true}

                          errors={!!errors?.contact_phone}
                          fieldName="contact_phone"
                          type="text"
                          autoComplete="off"
                          label=" Contact Number"
                          control={control}
                          valueGot={""}
                          setValue={setValue}
                          helperText={
                            errors?.contact_phone && errors?.contact_phone?.message
                          }
                        />
                      </GridItem>

                      <GridItem xs={6} sm={6} md={6}>
                        <InputField disabled={true}

                          errors={!!errors?.nationality}
                          fieldName="nationality"
                          type="text"
                          autoComplete="off"
                          label=" Nationality"
                          control={control}
                          valueGot={""}
                          setValue={setValue}
                          helperText={
                            errors?.nationality && errors?.nationality?.message
                          }
                        />
                      </GridItem>



                    </GridContainer>

                  </AccordionDetails>

                  <AccordionActions>
                  </AccordionActions>
                </Accordion>
                {/* Customer accordian==============================> */}

                {/* Booking Details accordian==============================> */}
                <Accordion defaultExpanded={true}>
                  <AccordionSummary
                    // expandIcon={<ExpandMoreIcon  />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Selected Slots</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ display: "flex", flexDirection: "column" }}>

                    {rowData && rowData.booking_details && rowData.booking_details.length > 0 && rowData.booking_details.map((item: any) => {
                      return (
                        <GridContainer

                          direction="row"

                          alignItems="flex-start"
                          xs={12} sm={12} md={12}
                          spacing={5}
                        >
                          <GridItem xs={4} sm={4} md={4}>
                            <b > Start Time:</b>
                            <Typography> {item.start_time} </Typography>
                          </GridItem>

                          <GridItem xs={4} sm={4} md={4}>
                            <b> End Time:</b>
                            <Typography> {item.end_time} </Typography>
                          </GridItem>

                          <GridItem xs={4} sm={4} md={4}>
                            <b> Price:</b>
                            <Typography> {item.price} </Typography>
                          </GridItem>

                        </GridContainer>
                      )
                    })}

                  </AccordionDetails>

                  <AccordionActions>
                  </AccordionActions>
                </Accordion>

                {/* Booking Details accordian==============================> */}


              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </form>

    </div>
  );
}

