import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Table from "components/Table/Table";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import AgGridCustom from "components/AgGridCustom/AgGridCustom";
import PageHeader from "components/PageHeader/PageHeader";
import Box from '@material-ui/core/Box';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import moment from "moment";
import StatusComponent from "components/Mix/Status";
import { getAdmin, getRoles } from "services/userservices";
import EditIcon from '@material-ui/icons/Edit';
import { Link, useLocation } from "react-router-dom";
import queryString from 'querystring'
import Button from "components/CustomButtons/Button";
import { useDispatch } from "react-redux";
import { spinLoaderShow } from "redux/action/spinLoader";

import axios from "axios";
const styles: any = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export const AdminList = (props: any) => {
  const classes = useStyles();
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState<any>(null);
  const [val, setVal] = useState(0)
  const [adminRow, setAdminRow] = useState<any>([])
  const [rolesRow, setRolesRow] = useState<any>([])
  const [exportData, setExportData] = useState<any>(null)
  const [exportData1, setExportData1] = useState<any>(null)
  const [headerTitle, setHeaderTitle] = useState<any>('Admin')
  const { search } = useLocation()
  const queryValues = queryString.parse(search)
  const dispatch = useDispatch()

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const routes = [
    {
      'name': "Admin",
      'link': '/admin/users'
    },
    {
      'name': "Users",
      'link': ''
    }
  ];

  useEffect(() => {
    dispatch(spinLoaderShow(true));
    getAdmin().then((e: any) => {
      dispatch(spinLoaderShow(false));
      setAdminRow(e.data.result)
    })
    // getRoles().then((e: any) => {
    //   dispatch(spinLoaderShow(false));
    //   setRolesRow()
    // })

    let temp: any = new URLSearchParams(search).get("list");
    if (temp == "role") {
      setVal(1)
      setHeaderTitle("Roles")
    }

  }, [])

  const columnDefs: any = [
    {
      headerName: "First Name",
      field: "first_name",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Last Name",
      field: "last_name",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Email",
      field: "email",
      minWidth: 200,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Status",
      field: "status",
      cellRendererFramework: (params: any) => {
        return <StatusComponent data={params} />;
      }
    },
    {
      headerName: "Created Date",
      field: "createdAt",
      minWidth: 150,
      filter: "agDateColumnFilter",
      cellRendererFramework: (params: any) => {
        return moment(params.value).format('llll');
      },
    },
    {
      headerName: "Updated Date",
      field: "updatedAt",
      filter: "agDateColumnFilter",
      sort: "desc",
      minWidth: 150,
      cellRendererFramework: (params: any) => {
        return moment(params.value).format('llll');
      },
    },
    {
      headerName: 'Actions',
      field: "action",
      sortable: false,
      filter: false,
      cellRendererFramework: (params: any) => {
        // put the value in bold

        return <Link to={'users/edit?uuid=' + params?.data?.uuid}><EditIcon /></Link>;

      }
    },
  ];

  const columnDefsRoles: any = [
    {
      headerName: "Role Name",
      field: "name",
      filter: "agTextColumnFilter",
    },

    {
      headerName: "Status",
      field: "status",
      cellRendererFramework: (params: any) => {
        return <StatusComponent data={params} />;
      }
    },
    {
      headerName: "Created Date",
      field: "createdAt",
      minWidth: 150,
      filter: "agDateColumnFilter",
      cellRendererFramework: (params: any) => {
        return moment(params.value).format('llll');
      },
    },
    {
      headerName: "Updated Date",
      field: "updatedAt",
      filter: "agDateColumnFilter",
      sort: "desc",
      minWidth: 150,
      cellRendererFramework: (params: any) => {
        return moment(params.value).format('llll');
      },
    },
    {
      headerName: 'Actions',
      field: "action",
      sortable: false,
      filter: false,
      cellRendererFramework: (params: any) => {
        return <Link to={'role/edit/' + params?.data?.uuid}><EditIcon /></Link>;
      }
    }
  ];


  // function TabPanel(props: any) {
  //   const { children, value, index, ...other } = props;

  //   return (
  //     <div
  //       role="tabpanel"
  //       hidden={value !== index}
  //       id={`simple-tabpanel-${index}`}
  //       aria-labelledby={`simple-tab-${index}`}
  //       {...other}
  //     >
  //       {value === index && <Box p={3}>{children}</Box>}
  //     </div>
  //   );
  // }

  // const adminRow: any = [
  //   // { first_name: "Admin 1", email: "admin1@hotmail.com", mobile_no: '03132370377', status: 'active' },
  //   // { first_name: "Admin 2", email: "admin2@hotmail.com", mobile_no: '03132370377', status: 'active' },
  //   // { first_name: "Admin 3", email: "admin3@hotmail.com", mobile_no: '03132370377', status: 'inactive' },
  //   // { first_name: "Admin 4", email: "admin4@hotmail.com", mobile_no: '03132370377', status: 'active' },
  // ];

  // const rowDataRoles: any = [
  //   // { role_name: "Role 1", status: 'active' },
  //   // { role_name: "Role 2", status: 'active' },
  //   // { role_name: "Role 3", status: 'inactive' },
  //   // { role_name: "Role 4", status: 'active' },
  // ];

  const valhandleChange = (event: any, newValue: any) => {
    setVal(newValue);

    if (val == 1) {
      setHeaderTitle("Admin")
    }
    else {
      setHeaderTitle("Roles")
    }
  };

  const exportChange = () => {
    if (val == 0) {
      exportData.exportDataAsCsv()
    }
    else {
      exportData1.exportDataAsCsv()
    }
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <PageHeader
          title={`Admin`}
          routes={routes}
          newItem={[
            // { text: 'Add New Role', link: "/admin/role/add" },
            { text: 'Add New Admin', link: "/admin/users/add" }
          ]}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary" className="card-header-export-btn">
            <h4 className={classes.cardTitleWhite}>{headerTitle} Listing</h4>
            <Button variant="outlined" className="custom-btn export-btn" onClick={exportChange}>Export to Csv</Button>

          </CardHeader>
          <CardBody>

            {/* <Tabs
              value={val}
              onChange={valhandleChange}
              aria-label="simple tabs example"
            >
              <Tab label="Administrators" />
              <Tab label="Roles & Permissions" />
            </Tabs> */}

            {val == 0 ? (

              <AgGridCustom
                columnDefs={columnDefs}
                rowData={adminRow}
                getRowNodeId={(data: any) => {
                  return data.uuid;
                }}
                setExportData={setExportData}
              />


            ) : (
              <AgGridCustom
                columnDefs={columnDefsRoles}
                rowData={rolesRow}
                getRowNodeId={(data: any) => {
                  return data.uuid;
                }}
                setExportData={setExportData1}
              />
            )
            }
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

